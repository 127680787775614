import { useLoginInfo } from 'hook/loginInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(props){
    window.document.title = "CRIZEL - Coupon";

    const navigate = useNavigate();

    const loginInfo = useLoginInfo();

    const currUrl = window.location.href;

    useEffect(()=>{

        if(loginInfo.memberId == '' && currUrl.indexOf("login") == -1){
            navigate('/coupon/login?returnUrl=' + encodeURIComponent("/coupon"));
        }

    }, [loginInfo]);
    return (
        <header className="header">
            <Link to="/coupon">쿠폰시스템</Link>
        </header>
    );
}