import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import {fn_reAuth} from 'component/info/serverInfo'

import {fn_historyList, fn_postForm, fn_deleteAction} from 'api/history';
import { useLoginInfo } from 'hook/loginInfo';


export default function App(){    
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState({

    });

    const accessToken = sessionStorage.getItem("accessToken");
    const loginInfo = useLoginInfo();

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState(null);
    const [info, setInfo] = useState({
        no : ''
        , histCategory1 : ''
        , histDate : ''
        , histContent : ''
        , histPeriod : ''
        , histLink : ''
    });


    const fn_getHistoryList = async (params)=>{
        setLoading(true);
        const data = await fn_historyList(params);
        setList(data.historyList);
        setLoading(false);
    }

    const fn_insert = ()=>{
        if(loginInfo.memberId == 'rhzhzh3'){
            document.querySelector('#historyDialog').setAttribute("open", "open");
            setInfo({
                no : ''
                , histCategory1 : ''
                , histDate : ''
                , histContent : ''
                , histPeriod : ''
                , histLink : ''
            })
        }else{
            alert('권한이 없습니다.');
        }
    }

    const fn_update = (info)=>{
        if(loginInfo.memberId == 'rhzhzh3'){
            document.querySelector('#historyDialog').setAttribute("open", "open");
            setInfo({
                no : info.no
                , histCategory1 : info.histCategory1
                , histDate : info.histDate.substring(0, 10)
                , histContent : info.histContent
                , histPeriod : info.histPeriod
                , histLink : info.histLink
            })
        }else{
            alert('권한이 없습니다.');
        }
        
    }

    const fn_close = ()=>{
        document.querySelector('#historyDialog').removeAttribute("open");
    }


    const fn_deleteAction = (no)=>{
        if(loginInfo.memberId == 'rhzhzh3'){
            let url = process.env.REACT_APP_API_URL + "/crizelApi/historyDeleteAction"
            let formData = new FormData();
            formData.append("no", no);

            if(window.confirm('삭제하시겠습니까?')){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        fn_getHistoryList(params);
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.' + err);
                });
            }
        }else{
            alert('권한이 없습니다.');
        }
        
    }

    useEffect(()=>{
        fn_getHistoryList(params);
    }, []);


    if(loading) return <Loading/>
    return (
        <>  
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <div className="btn-area text-center">
                    <button className="btn btn-primary" onClick={(e)=>{
                        e.preventDefault();
                        fn_insert();
                    }}>등록</button>
                </div>
            </form>
            <table className="table list">
                <colgroup>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'auto'}}/>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'16%'}}/>
                    <col style={{width:'22%'}}/>
                    {
                        sessionStorage.getItem('accessToken')!=null && sessionStorage.getItem('accessToken')!='' ? (
                            <col style={{width:'10%'}}/>
                        ) : ''
                    }
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            업체명
                        </th>
                        <th>
                            프로젝트 내용
                        </th>
                        <th>
                            완료날짜
                        </th>
                        <th>
                            기간
                        </th>
                        <th>
                            링크
                        </th>
                        {
                            sessionStorage.getItem('accessToken')!=null && sessionStorage.getItem('accessToken')!='' ? (
                                <th>
                                    수정/삭제
                                </th>
                            ) : ''
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length>0 ? (
                            list.map((ob, i)=>{
                                return (
                                    <tr key={i}>
                                        <td>
                                            <span className="mob-title">업체명</span>
                                            {ob.histCategory1}
                                        </td>
                                        <td className="text-left">
                                            <span className="mob-title">프로젝트 내용</span>
                                            {ob.histContent}
                                        </td>
                                        <td>
                                            <span className="mob-title">완료날짜</span>
                                            {ob.histDate.substring(0, 10)}
                                        </td>
                                        <td>
                                            <span className="mob-title">기간</span>
                                            {ob.histPeriod}
                                        </td>
                                        <td className="text-left">
                                            <span className="mob-title">링크</span>
                                            <a href={ob.histLink} target="_blank">{ob.histLink}</a>
                                        </td>
                                        {
                                            sessionStorage.getItem('accessToken')!=null && sessionStorage.getItem('accessToken')!='' ? (
                                                <td>
                                                    <button className="btn btn-success" onClick={(e)=>{
                                                        e.preventDefault();
                                                        fn_update(ob);
                                                    }}>수정</button>
                                                    <button className="btn btn-danger" onClick={(e)=>{
                                                        e.preventDefault();
                                                        fn_deleteAction(ob.no, params);
                                                    }}>삭제</button>
                                                </td>
                                            ) : ''
                                        }
                                        
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={5}>데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>

            <dialog id="historyDialog">
                <div className="text-right">
                    <button className="btn btn-danger" onClick={(e)=>{
                        e.preventDefault();
                        fn_close();
                    }}>닫기</button>
                </div>
                <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                    <input type="hidden" name="no" defaultValue={info.no}/>                            
                    <table className="table">
                        <colgroup>
                            <col style={{width:'20%'}}/>
                            <col style={{width:'30%'}}/>
                            <col style={{width:'20%'}}/>
                            <col style={{width:'30%'}}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    업체
                                </th>
                                <td colSpan={3}>
                                    <input type="text" name="histCategory1" defaultValue={info.histCategory1}/>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    완료날짜
                                </th>
                                <td>
                                    <input type="date" name="histDate" defaultValue={info.histDate}/>
                                </td>
                                <th>
                                    기간
                                </th>
                                <td>
                                    <input type="text" name="histPeriod" defaultValue={info.histPeriod}/>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    결과 링크
                                </th>
                                <td colSpan={3}>
                                    <input type="text" name="histLink" defaultValue={info.histLink}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <textarea name="histContent" defaultValue={info.histContent}></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>                  
                    <div className="btn-area">
                        <button onClick={(e)=>{
                            e.preventDefault();
                            fn_postForm(document.postForm, params, fn_close, fn_getHistoryList);
                        }} className="btn btn-primary">확인</button>
                        <button onClick={(e)=>{
                            e.preventDefault();
                            fn_close();
                        }} className="btn btn-danger">닫기</button>
                    </div>                                      
                </form>
            </dialog>
        </>   
    );
}

