import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import Main from 'page/crizel/Main';

import Ani from 'page/crizel/ani/Main'

import GirlsList from 'page/crizel/girls/List'
import GirlsRepository from 'page/crizel/girls/Repository'

import RdvList from 'page/crizel/rdv/List'
import RdvRelay from 'page/crizel/rdv/Relay'

import DiaryMain from 'page/crizel/diary/Main'
import DiaryView from 'page/crizel/diary/View'
import DiaryList from 'page/crizel/diary/List'
import DiaryMoney from 'page/crizel/diary/Money'



export const crizel = [
    {path:'/crizel', element : <Main/>}
    , {path:'/crizel/ani', element : <Ani/>}
    , {path:'/crizel/girls/list', element : <GirlsList/>}
    , {path:'/crizel/girls/repository', element : <GirlsRepository/>}
    , {path:'/crizel/rdv/list', element : <RdvList/>}
    , {path:'/crizel/rdv/relay', element : <RdvRelay/>}
    
    , {path:'/crizel/diary', element : <DiaryMain/>}
    , {path:'/crizel/diary/view', element : <DiaryView/>}
    , {path:'/crizel/diary/list', element : <DiaryList/>}
    , {path:'/crizel/diary/money', element : <DiaryMoney/>}
];