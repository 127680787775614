import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import ReactQuill from "react-quill";

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const navigate = useNavigate();

    const [searchparams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        noticeSeq : searchparams.get('noticeSeq') || ''
        , deviceSeq : searchparams.get('deviceSeq') || ''
    });

    
    const [noticeContent, setNoticeContent] = useState("")

    const [deviceList, setDeviceList] = useState([]);
    const [info, setInfo] = useState({
        noticeSeq : ''
        , deviceSeq : params.deviceSeq
        , startTime : ''
        , endTime : ''
        , noticeTitle : ''
        , noticeContent : ''
        , activeFlag : 'Y'
        , deleteFlag : ''
        , registerOper : ''
        , registerDate : ''
        , updateOper : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteDate : ''
    });

    const fn_postForm = ()=>{
        let url = '';
        let msg = '';
        let formData = new FormData(document.postForm);


        if(document.postForm.noticeSeq.value == ''){
            url = apiServer + "/crizelApi/mediaBoard/noticeInsertAction";
            msg = '등록하시겠습니까?'
        }else{
            url = apiServer + "/crizelApi/mediaBoard/noticeUpdateAction";
            msg = '수정하시겠습니까?'
        }


        if(document.postForm.noticeTitle.value == ''){
            alert('제목을 입력하여주시기 바랍니다.');
        } else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/mediaboard/notice/list');
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
    }

    const fn_getDeviceList = async(params)=>{
        const url = apiServer + "/crizelApi/mediaBoard/deviceList"
        const res = await fetch(url);
        const data = await res.json();
        setDeviceList(data.deviceList);
    }


    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/noticeInfo"
        + "?noticeSeq=" + params.noticeSeq

        const res = await fetch(url);
        const data = await res.json();
        const noticeInfo = data.noticeInfo;

        if(noticeInfo!=null){
            setInfo({
                ...info
                , noticeSeq : noticeInfo.noticeSeq
                , deviceSeq : noticeInfo.deviceSeq
                , startTime : noticeInfo.startTime
                , endTime : noticeInfo.endTime
                , noticeTitle : noticeInfo.noticeTitle
                , noticeContent : noticeInfo.noticeContent
                , activeFlag : noticeInfo.activeFlag
                , deleteFlag : noticeInfo.deleteFlag
                , registerOper : noticeInfo.registerOper
                , registerDate : noticeInfo.registerDate
                , updateOper : noticeInfo.updateOper
                , updateDate : noticeInfo.updateDate
                , deleteOper : noticeInfo.deleteOper
                , deleteDate : noticeInfo.deleteDate
            });
            setNoticeContent(noticeInfo.noticeContent);
        }
    }

    useEffect(()=>{
        fn_getDeviceList(params);
        fn_getData(params);
    }, []);


    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="noticeSeq" defaultValue={info.noticeSeq}/>
                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>기기</th>
                            <td>
                                <select name="deviceSeq" value={info.deviceSeq} onChange={(e)=>{const tmp = {...info, deviceSeq : e.target.value}; setInfo(tmp) }}>
                                    <option value="">기기선택</option>
                                    {
                                        deviceList!=null && deviceList.length>0 ? (
                                            deviceList.map((ob, i)=>{
                                                return (
                                                    <option value={ob.deviceSeq} key={i}>{ob.deviceName}</option>
                                                );
                                            })
                                        ) : ''
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>제목</th>
                            <td>
                                <input type="text" name="noticeTitle" defaultValue={info.noticeTitle}/>
                            </td>
                        </tr>
                        <tr>
                            <th>출력시간</th>
                            <td>
                                <input type="time" name="startTime" defaultValue={info.startTime} placeholder='시작시간'/>
                                <input type="time" name="endTime" defaultValue={info.endTime} placeholder='종료시간'/>
                            </td>
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td>
                                <ReactQuill id="noticeContent" value={noticeContent} onChange={setNoticeContent} />
                                <input type="hidden" name="noticeContent" defaultValue={noticeContent}/>
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="Y" checked={info.activeFlag == 'Y'} onChange={(e)=>{setInfo({...info, activeFlag : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="N" checked={info.activeFlag == 'N'} onChange={(e)=>{setInfo({...info, activeFlag : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-area">
                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className='btn btn-primary'>확인</button>
                    <button onClick={(e)=>{e.preventDefault(); if(window.confirm('취소하시겠습니까?')){navigate('/mediaboard/notice/list')}}} className='btn btn-danger'>취소</button>
                </div>
            </form>
        </>        
    );

}