import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import Loading from 'component/Loading';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState(null);

    const [params, setParams] = useState({
        pageNo : searchParams.get('pageNo') || '1'
    });

    const fn_getDataList = async (params)=>{
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/meeting/meetingList"

        const res = await fetch(url);
        const data = await res.json();
        
        setList(data.meetingList);
        setLoading(false);
    }


    useEffect(()=>{
        fn_getDataList(params);
    }, []);

    return (
        <>
        {
            loading == true  ? (
                <Loading/>
            ) : (
                <>  
                    <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                        <Link to="/meeting/insert" className="btn btn-primary">등록</Link>
                    </form>
                    <table className="table">
                        <colgroup>
                            <col style={{width:'25%'}}/>
                            <col style={{width:'25%'}}/>
                            <col style={{width:'25%'}}/>
                            <col style={{width:'25%'}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>날짜</th>
                                <th>작성자</th>
                                <th>작성일</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            list!=null && list.length>0 ? (
                                list.map((ob, i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {ob.meetingNo}
                                            </td>
                                            <td>
                                                <Link to={"/meeting/insert?meetingNo=" + ob.meetingNo}>{ob.meetingDate}</Link>
                                            </td>
                                            <td>
                                                {ob.registerOperName}
                                            </td>
                                            <td>
                                                {ob.registerDate}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>데이터가 없습니다.</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>        
                </>
            )
        }
             
        </>        
    );
}

