import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const [list, setList] = useState([]);


    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParmas] = useState({
        deviceSeq : searchParams.get('deviceSeq') || ''
        , deviceId : searchParams.get('deviceId') || ''
    });

    const ws = new WebSocket(process.env.REACT_APP_WS_URL);


    const fn_setSchedule = ()=>{
        ws.send(JSON.stringify({
            event : 'medeaboardSetSchedule'	
            , data : params.deviceSeq
        }));

    }


    useEffect(()=>{

        ws.onopen = () => {
            //console.log('웹소켓 연결됨');
        };
        ws.onmessage = (event) => {
            let obj = JSON.parse(event.data);

            console.log(obj);
        };
        ws.onclose = () => {
            //console.log('웹소켓 연결 종료됨');
        };
        return () => {
            ws.close();
        };
    }, []);


    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
            </form>
            <div className="text-right">
                <Link to="/mediaboard/device/insert" className="btn btn-primary">등록</Link>
            </div>


            기기번호 : {params.deviceSeq}, {params.deviceId}
        
            <table className="table list">
                
            </table>
        </>        
    );

}