import { apiServer } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    window.document.title = "CRIZEL - Register";

    const [searchParams, setSearchParams] = useSearchParams();

    const [memberIdChk, setMemberIdChk] = useState(false);


    const fn_memberIdChk = async ()=>{
        const form = document.registerForm;
        let url = process.env.REACT_APP_API_URL + "/crizelApi/member/memberIdChk"
        + "?memberId=" + form.memberId.value;

        if(form.memberId.value == ""){
            alert('아이디를 입력하여주시기 바랍니다.');
        }else{
            const res = await fetch(url);
            const data = await res.json();

            if(data.result == 0){
                alert('사용가능한 아이디입니다.');
                setMemberIdChk(true);
            }else{
                alert('이미 사용중인 아이디입니다.');
                setMemberIdChk(false);
            }
        }


    }

    const fn_registerAction = async ()=>{
        const url = process.env.REACT_APP_API_URL + "/crizelApi/member/registerAction";

        const form = document.registerForm;
        let formData = new FormData(form);

        if(form.memberId.value == ""){
            alert('아이디를 입력하여주시기 바랍니다.');
            form.memberId.focus();
        }else if(form.memberPw.value == ""){
            alert('비밀번호를 입력하여주시기 바랍니다.');
            form.memberPw.focus();
        }else if(form.memberPwChk.value == ""){
            alert('비밀번호 확인값을 입력하여주시기 바랍니다.');
            form.memberPwChk.focus();
        }else if(form.memberName.value == ""){
            alert('이름을 입력하여주시기 바랍니다.');
            form.memberName.focus();
        }else if(memberIdChk == false){
            alert('아이디 중복체크를 진행하여주시기 바랍니다.');
        }else if(fn_chgMemberPw() == false){
            alert('비밀번호를 확인하여주시기 바랍니다.');
        }else{

            if(window.confirm('회원가입을 진행하시겠습니까?')){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        navigate("/registerComp");                        
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                });
            }
        }

    }


    const fn_chgMemberId = ()=>{
        const memberId = document.registerForm.memberId;
        memberId.value = memberId.value.replace(/[^0-9a-zA-Z]/g, '');
    }

    const fn_chgMemberPw = ()=>{
        let pass = true;
        let regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
        const memberPw = document.registerForm.memberPw;
        const memberPwChk = document.registerForm.memberPwChk;

        if((regex.test(memberPw.value) || regex.test(memberPwChk.value)) && memberPw.value == memberPwChk.value){
            pass = true
        }else{
            pass = false;
        }
        return pass;
    }

    useEffect(()=>{
    }, []);

    return (
        <>
            <form name="registerForm" onSubmit={(e)=>{e.preventDefault();}}>

                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>아이디 <span className="red">*</span></th>
                            <td>
                                <input type="text" name="memberId" id="memberId" onChange={(e)=>{fn_chgMemberId()}}/>
                                <buttion onClick={(e)=>{e.preventDefault(); fn_memberIdChk();}} className="btn btn-primary" id="idDupChk">아이디 중복체크</buttion>
                            </td>
                        </tr>
                        <tr>
                            <th>비밀번호 <span className="red">*</span></th>
                            <td>
                                <input type="password" name="memberPw" />
                            </td>
                        </tr>
                        <tr>
                            <th>비밀번호 확인 <span className="red">*</span></th>
                            <td>
                                <input type="password" name="memberPwChk" />
                            </td>
                        </tr>
                        <tr>
                            <th>이름 <span className="red">*</span></th>
                            <td>
                                <input type="text" name="memberName"/>
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>
                                <input type="email" name="memberEmail"/>
                            </td>
                        </tr>
                        <tr>
                            <th>휴대폰번호</th>
                            <td>
                                <input type="number" name="memberPhone"/>
                            </td>
                        </tr>
                        <tr>
                            <th>전화번호</th>
                            <td>
                                <input type="number" name="memberTel"/>
                            </td>
                        </tr>
                        <tr>
                            <th>프로필 사진</th>
                            <td>
                                <input type="file" name="upload"/>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="btn-area">
                    <button className='btn btn-primary' onClick={(e)=>{e.preventDefault(); fn_registerAction();}}>확인</button>                    
                </div>
            </form>
        </>        
    );
}

