import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import Loading from 'component/Loading';
import { fn_authCheck } from 'component/info/serverInfo';

export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);
    
    const loginInfo = useSelector(getLoginInfo);

    const [timeList, setTimeList] = useState(null);
    const [resvList, setResvList] = useState(null);

    const [params, setParams] = useState({
        corp : searchParams.get('corp') || ''
        , timeNo : searchParams.get('timeNo') || ''
    });     

    const date = new Date();

    const [info, setInfo] = useState({
        resvNo : ''
        , corp : searchParams.get('corp') || ''
        , timeNo : searchParams.get('timeNo') || ''
        , resvDate : date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
        , userId : sessionStorage.getItem('memberId')
        , resvName : sessionStorage.getItem('memberName')
        , resvPhone : ''
        , status : '0'
        , registerDate : ''
        , corpName : ''
        , startTime : ''
        , endTime : ''
    });

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    const fn_getResvList = async(params)=>{
        setLoading(true);
        
        await fn_authCheck();

        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/resvList'
        ;

        const res = await fetch(url, {
            method : 'get'
            , headers : {
                Authorization : 'Bearer ' + sessionStorage.getItem("accessToken")
            }
        });
        const data = await res.json();

        setResvList(data.resvList);

        setLoading(false);
    }

    useEffect(()=>{
        fn_getResvList(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                            <input type="hidden" name="corp" defaultValue={info.corp}/>
                            <input type="hidden" name="timeNo" defaultValue={info.timeNo}/>
                            <table className="table">
                                <colgroup>
                                    <col style={{width:'25%'}}/>
                                    <col style={{width:'25%'}}/>
                                    <col style={{width:'25%'}}/>
                                    <col style={{width:'25%'}}/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>골프장</th>
                                        <th>예약날짜</th>
                                        <th>예약시간</th>
                                        <th>취소</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        resvList!=null && resvList.length>0 ? (
                                            resvList.map((ob, i)=>{
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <span className="mob-title">골프장</span>
                                                            {ob.corpName}
                                                        </td>
                                                        <td>
                                                            <span className="mob-title">예약날짜</span>
                                                            {ob.resvDate}
                                                        </td>
                                                        <td>
                                                            <span className="mob-title">예약시간</span>
                                                            {ob.startTime} ~ {ob.endTime}
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-danger" onClick={(e)=>{
                                                                e.preventDefault();
                                                            }}>예약취소</button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>데이터가 없습니다.</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </form>
                    </>  
                )
            }
        </>      
    );
}

