import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const navigate = useNavigate();

    const [searchparams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        contentSeq : searchparams.get('contentSeq') || ''
    });

    const [info, setInfo] = useState({
        contentSeq : ''
        , category1 : ''
        , category2 : ''
        , contentName : ''
        , orgFileName : ''
        , sysFileName : ''
        , fileSize : ''
        , fileExt : ''
        , fileType : ''
        , md5Hash : ''
        , encodeStr : ''
        , sortOrd : '0'
        , activeFlag : 'Y'
        , deleteFlag : ''
        , registerOper : ''
        , registerDate : ''
        , updateOper : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteDate : ''
    });

    const fn_postForm = ()=>{
        let url = '';
        let msg = '';
        let formData = new FormData(document.postForm);


        if(document.postForm.contentSeq.value == ''){
            url = apiServer + "/crizelApi/mediaBoard/contentInsertAction";
            msg = '등록하시겠습니까?'
        }else{
            url = apiServer + "/crizelApi/mediaBoard/contentUpdateAction";
            msg = '수정하시겠습니까?'
        }


        if(document.postForm.contentName.value == ''){
            alert('기기명을 입력하여주시기 바랍니다.');
        } else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/mediaboard/content/list');
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
    }


    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/contentInfo"
        + "?contentSeq=" + params.contentSeq

        const res = await fetch(url);
        const data = await res.json();
        const contentInfo = data.contentInfo;

        if(contentInfo!=null){
            setInfo({
                ...info
                , contentSeq : contentInfo.contentSeq
                , category1 : contentInfo.category1
                , category2 : contentInfo.category2
                , contentName : contentInfo.contentName
                , orgFileName : contentInfo.orgFileName
                , sysFileName : contentInfo.sysFileName
                , fileSize : contentInfo.fileSize
                , fileExt : contentInfo.fileExt
                , fileType : contentInfo.fileType
                , md5Hash : contentInfo.md5Hash
                , encodeStr : contentInfo.encodeStr
                , sortOrd : contentInfo.sortOrd
                , activeFlag : contentInfo.activeFlag
                , deleteFlag : contentInfo.deleteFlag
                , registerOper : contentInfo.registerOper
                , registerDate : contentInfo.registerDate
                , updateOper : contentInfo.updateOper
                , updateDate : contentInfo.updateDate
                , deleteOper : contentInfo.deleteOper
                , deleteDate : contentInfo.deleteDate
            });
        }
        
    }

    const fn_fileCheck = async(e)=>{
       
    }

    useEffect(()=>{
        fn_getData(params);
    }, []);


    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="contentSeq" defaultValue={info.contentSeq}/>
                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>카테고리1</th>
                            <td>
                                <input type="text" name="category1" defaultValue={info.category1}/>
                            </td>
                        </tr>
                        <tr>
                            <th>카테고리2</th>
                            <td>
                                <input type="text" name="category2" defaultValue={info.category2}/>
                            </td>
                        </tr>
                        <tr>
                            <th>컨텐츠명</th>
                            <td>
                                <input type="text" name="contentName" defaultValue={info.contentName}/>
                            </td>
                        </tr>
                        <tr>
                            <th>순서</th>
                            <td>
                                <input type="text" name="sortOrd" defaultValue={info.sortOrd}/>
                            </td>
                        </tr>
                        <tr>
                            <th>파일</th>
                            <td>
                                <input type="file" name="upload" onChange={(e)=>{
                                    fn_fileCheck(e);
                                }}/>                                
                                {
                                    info.fileType != '' ? (
                                        info.fileType == 'I' ? (
                                            <img src={apiServer + "/upload/mediaboard/" + info.sysFileName} className='content-item'/>
                                        ) : (
                                            <video src={apiServer + "/upload/mediaboard/" + info.sysFileName} className='content-item' controls/>
                                        )
                                    ) : ''
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="Y" checked={info.activeFlag == 'Y'} onChange={(e)=>{setInfo({...info, activeFlag : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="N" checked={info.activeFlag == 'N'} onChange={(e)=>{setInfo({...info, activeFlag : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-area">
                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className='btn btn-primary'>확인</button>
                    <button onClick={(e)=>{e.preventDefault(); if(window.confirm('취소하시겠습니까?')){navigate('/mediaboard/content/list')}}} className='btn btn-danger'>취소</button>
                </div>
            </form>
        </>        
    );

}