import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const navigate = useNavigate();

    const [searchparams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        deviceSeq : searchparams.get('deviceSeq') || ''
    });

    const [info, setInfo] = useState({
        deviceSeq : ''
        , deviceName : ''
        , deviceId : ''
        , deviceModel : ''
        , deviceIp : ''
        , deviceVersion : ''
        , screenType : '0'
        , activeFlag : 'Y'
        , deleteFlag : ''
        , registerOper : ''
        , registerDate : ''
        , updateOper : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteDate : ''
    });

    const fn_postForm = ()=>{
        let url = '';
        let msg = '';
        let formData = new FormData(document.postForm);


        if(document.postForm.deviceSeq.value == ''){
            url = apiServer + "/crizelApi/mediaBoard/deviceInsertAction";
            msg = '등록하시겠습니까?'
        }else{
            url = apiServer + "/crizelApi/mediaBoard/deviceUpdateAction";
            msg = '수정하시겠습니까?'
        }


        if(document.postForm.deviceName.value == ''){
            alert('기기명을 입력하여주시기 바랍니다.');
        } else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/mediaboard/device/list');
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
    }


    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/deviceInfo"
        + "?deviceSeq=" + params.deviceSeq

        const res = await fetch(url);
        const data = await res.json();
        const deviceInfo = data.deviceInfo;

        if(deviceInfo!=null){
            setInfo({
                ...info
                , deviceSeq : deviceInfo.deviceSeq
                , deviceName : deviceInfo.deviceName
                , deviceId : deviceInfo.deviceId
                , deviceModel : deviceInfo.deviceModel
                , deviceIp : deviceInfo.deviceIp
                , deviceVersion : deviceInfo.deviceVersion
                , screenType : deviceInfo.screenType
                , activeFlag : deviceInfo.activeFlag
                , deleteFlag : deviceInfo.deleteFlag
                , registerOper : deviceInfo.registerOper
                , registerDate : deviceInfo.registerDate
                , updateOper : deviceInfo.updateOper
                , updateDate : deviceInfo.updateDate
                , deleteOper : deviceInfo.deleteOper
                , deleteDate : deviceInfo.deleteDate
            });

            console.log(info.activeFlag + " ~ " + deviceInfo.activeFlag);
        }
        
    }

    useEffect(()=>{
        fn_getData(params);
    }, []);


    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="deviceSeq" defaultValue={info.deviceSeq}/>
                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>기기명</th>
                            <td>
                                <input type="text" name="deviceName" defaultValue={info.deviceName}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기기 고유번호</th>
                            <td>
                                <input type="text" name="deviceId" defaultValue={info.deviceId}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기기 모델명</th>
                            <td>
                                <input type="text" name="deviceModel" defaultValue={info.deviceModel}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기기 사용 IP</th>
                            <td>
                                <input type="text" name="deviceIp" defaultValue={info.deviceIp}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기기 사용 버전</th>
                            <td>
                                <input type="text" name="deviceVersion" defaultValue={info.deviceVersion}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기기 스크린 타입</th>
                            <td>
                                <label>
                                    <input type="radio" name="screenType" value="0" checked={info.screenType == '' || info.screenType == '0'}
                                        onChange={(e)=>{setInfo({...info, screenType : '0'})}}/> 영상절반 + 공지사항 절반
                                </label>
                                <label>
                                    <input type="radio" name="screenType" value="1" checked={info.screenType == '1'}
                                        onChange={(e)=>{setInfo({...info, screenType : '1'})}}/> 전체 영상
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="Y" checked={info.activeFlag == 'Y'} onChange={(e)=>{setInfo({...info, activeFlag : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="N" checked={info.activeFlag == 'N'} onChange={(e)=>{setInfo({...info, activeFlag : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-area">
                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className='btn btn-primary'>확인</button>
                    <button onClick={(e)=>{e.preventDefault(); if(window.confirm('취소하시겠습니까?')){navigate('/mediaboard/device/list')}}} className='btn btn-danger'>취소</button>
                </div>
            </form>
        </>        
    );

}