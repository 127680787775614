import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading';

export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [timeList, setTimeList] = useState(null);

    const [params, setParams] = useState({
        corp : searchParams.get('corp') || ''
        , timeNo : searchParams.get('timeNo') || ''
    });     

    const date = new Date();

    const [info, setInfo] = useState({
        resvNo : ''
        , corp : searchParams.get('corp') || ''
        , timeNo : searchParams.get('timeNo') || ''
        , resvDate : date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
        , userId : sessionStorage.getItem('memberId')
        , resvName : sessionStorage.getItem('memberName')
        , resvPhone : ''
        , status : '0'
        , registerDate : ''
        , corpName : ''
        , startTime : ''
        , endTime : ''
    });

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    useEffect(()=>{
    }, []);

    return (
        <>
            예약이 완료되었습니다.
            <Link to="/pg/reserve/reserveList">예약 조회</Link>
        </>   
    );
}

