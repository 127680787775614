import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const Slick = () => {

  
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
      
    return (
        <div>
          <Slider {...settings}>
            <div>
              <h3>선착순 1명</h3>
            </div>
            <div>
              <h3>선착순 2명</h3>
            </div>
            <div>
              <h3>선착순 3명</h3>
            </div>
          </Slider>
        </div>
    );
    
  };

  export default Slick;
