import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import Av19 from 'page/crizel/rdv/List'
import { jwtDecode } from 'jwt-decode';


export default function App(){
    const navigate = useNavigate();

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    if(memberId != 'rhzhzh3'){
        navigate('/');
    }

    const fn_sendPush = ()=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/app/pushSendAction"
        const form = document.postForm;
        const formData = new FormData(form);

        fetch(url, {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
        }).then((res)=>alert('완료')).catch((err)=>{
            alert(err);
        });
    }


    const fn_chatForm = async()=>{
        const form = document.chatForm;
        const formData = new FormData(form);
        const url = "https://api.openai.com/v1/chat/completions";
        const config = {
            method : 'post'
            , body : JSON.stringify({
                "model": "gpt-3.5-turbo",
                "messages": form.messages.value,
                "temperature": 1,
                "max_tokens": 256,
                "top_p": 1,
                "frequency_penalty": 0,
                "presence_penalty": 0,
                "response_format": {
                    "type": "text"
                }
            })
            , headers : {
                'Content-Type' : 'application/json'
                , Authorization : "Bearer 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"
            }
        }

        fetch(url, config).then((res)=>res.json()).then((data)=>{
            console.log(data);
        }).catch((err)=>{
            console.log(err);
        });
    }



    return (
        <>
           메인
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="text" name="title" defaultValue="테스트"/>
                <input type="text" name="body" defaultValue="테스트 푸시"/>
            </form>
           <button onClick={(e)=>{
            e.preventDefault();
            fn_sendPush();
           }} className="btn btn-primary">푸시 발송</button>

           <form name="chatForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="text" name="messages"/>
                <button onClick={(e)=>{
                    e.preventDefault();
                    fn_chatForm();
                }}>채팅</button>
           </form>
        </>        
    );
}

