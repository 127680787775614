import { api_commonFetch } from 'api/commonFetch';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function CouponFooter(props){

    const navigate = useNavigate();

    const fn_allMenu = ()=>{
        if(document.querySelector(".all-menu").classList.contains("display-n")){
            document.querySelector(".all-menu").classList.remove('display-n');
        }else{
            document.querySelector(".all-menu").classList.add('display-n');
        }

        window.scrollTo({
            top : 0
            , behavior : 'smooth'
        });
    }

    const fn_logout = async ()=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/member/logout'
        const config = {method : 'post'}
        const data = await api_commonFetch(url, config);
        sessionStorage.setItem('accessToken', '')
        sessionStorage.setItem('refreshToken', '')

        navigate('/coupon');
    }

    useEffect(()=>{
        document.querySelectorAll(".all-menu ul li a").forEach((ob)=>{
            ob.addEventListener('click', ()=>{
                fn_allMenu();
            });
        });
        document.querySelectorAll(".all-menu ul li button").forEach((ob)=>{
            ob.addEventListener('click', ()=>{
                fn_allMenu();
            });
        });
    }, []);

    return (
        <>
            <footer className="footer">
                <ul className="menu depth1">
                    <li>
                        <Link to={"/coupon/"}>
                            <img src="https://crizel.duckdns.org/images/noimage.jpg"/>
                            <span>홈</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/coupon/user/couponInventory"}>
                            <img src="https://crizel.duckdns.org/images/noimage.jpg"/>
                            <span>쿠폰 함</span>
                        </Link>
                    </li>
                    <li>
                        <button onClick={(e)=>{e.preventDefault(); fn_allMenu();}}>
                            <img src="https://crizel.duckdns.org/images/noimage.jpg"/>
                            <span>마이메뉴</span>
                        </button>
                    </li>
                </ul>
            </footer>
            <div className="all-menu display-n">
                <button onClick={(e)=>{e.preventDefault(); fn_allMenu();}} className="btn-close">X</button>
                <ul>
                    <li>
                        <Link to="/coupon/user/couponList">쿠폰 가져오기</Link>    
                    </li> 
                    <li>
                        <Link to="/coupon/user/couponInventory">쿠폰함</Link>    
                    </li> 
                    <li>
                        <Link to="/coupon/user/couponUseList">쿠폰 사용내역</Link>    
                    </li> 
                    <li>
                        <button onClick={(e)=>{e.preventDefault(); fn_logout();}}>로그아웃</button>
                    </li> 
                </ul>
            </div>
        </>
    );
}