import { apiServer, fn_reAuth} from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import { Map, MapMarker, MapTypeControl, ZoomControl, useKakaoLoader, MapInfoWindow} from "react-kakao-maps-sdk";


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        projectNo : searchParams.get("projectNo") || ''
        , typeNo : searchParams.get("typeNo") || ''
        , groupNo : searchParams.get("groupNo") || ''
        , userName : searchParams.get("userName") || ''
        , viewType : searchParams.get("viewType") || 'list'
    });

    const [list, setList] = useState([]);


    const fn_getDataList = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/qrScanLogList"
        + "?projectNo=" + params.projectNo
        
        const res = await fetch(url, {
                    method : 'get'
                    , headers : {
                        Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                    }
                });
        const data = await res.json();
        const list = data.qrScanLogList
        setList(list);
        fn_reAuth(fn_getDataList, data);
    }


    const fn_deleteAction = (userNo)=>{
        
    }

    useEffect(()=>{

        fn_getDataList(params);
        
    }, []);

    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <fieldset>
                    <label>
                        <input type="radio" name="viewType" value="list" defaultChecked={params.viewType == 'list'} onChange={(e)=>{setParams({...params, viewType : 'list'})}}/> 목록
                    </label>
                    <label>
                        <input type="radio" name="viewType" value="map" defaultChecked={params.viewType == 'map'} onChange={(e)=>{setParams({...params, viewType : 'map'})}}/> 지도
                    </label>
                </fieldset>
            </form>
            {
                params.viewType == 'list' ? (
                    <List list={list} projectNo={params.projectNo}/>
                ) : (
                    <MapList list={list} projectNo={params.projectNo}/>
                )
            }
            
        </>        
    );
}


const fn_maskPhone = (val)=>{

    const memberName = sessionStorage.getItem("memberName");

    val = val.replace("+82", "0");

    val = val.substring(0, 3) + "-"
    + "****-"
    + val.substring(7,11)

    

    return val;
}


function List(props){
    const list = props.list;

    const [itemList, setItemList] = useState([]);
    const [qrScanLogItemList, setQrScanLogItemList] = useState([]);

    const fn_getProjectItemList = async (projectNo)=>{
        let url = apiServer + "/crizelApi/app/qr/itemList"
        + "?projectNo=" + projectNo

        const res = await fetch(url);
        const data = await res.json();

        setItemList(data.itemList);
    }

    const fn_getQrScanLogItemList = async (projectNo)=>{
        let url = apiServer + "/crizelApi/app/qr/qrScanLogItemList"
        + "?projectNo=" + projectNo

        const res = await fetch(url);
        const data = await res.json();

        setQrScanLogItemList(data.qrScanLogItemList);
    }


    useEffect(()=>{
        fn_getProjectItemList(props.projectNo);
        fn_getQrScanLogItemList(props.projectNo);
    }, []);

    return (
        <>
            <table className="table list">
                <thead>
                    <tr>
                        <th>프로젝트</th>
                        <th>타입</th>
                        <th>그룹</th>
                        <th>UUID</th>
                        <th>식별코드</th>
                        <th>전화번호</th>
                        <th>접속IP</th>
                        <th>접속장소</th>
                        {
                            itemList!=null && itemList.length>0 ? (
                                itemList.map((ob, i)=>{
                                    return (
                                        <th>{ob.itemName}</th>
                                    );
                                })
                            ) : ''
                        }
                        <th>입실일시</th>
                        <th>퇴실일시</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list!=null && list.length>0 ? (
                        list.map((ob, i)=>{
                            return (
                                <tr key={i}>
                                    <td>{ob.projectName}</td>
                                    <td>{ob.typeName}</td>
                                    <td>{ob.groupName}</td>
                                    <td>{ob.userUuid}</td>
                                    <td>{ob.userCd}</td>
                                    <td>{fn_maskPhone(ob.userPhone)}</td>
                                    <td>{ob.userIp}</td>
                                    <td>{ob.userAddr}</td>
                                    {
                                        itemList!=null && itemList.length>0 ? (
                                            itemList.map((ob2, j)=>{
                                                return (
                                                   <td key={j}>
                                                    {
                                                         qrScanLogItemList!=null && qrScanLogItemList.length>0 ? (
                                                            qrScanLogItemList.map((ob3, k)=>{
                                                                return (
                                                                    ob.logNo == ob3.logNo && ob2.itemNo == ob3.itemNo ? (
                                                                        <>{ob3.itemValue}</>
                                                                    ) : ''
                                                                );
                                                            })
                                                        ) : ''
                                                    }
                                                   </td>
                                                );
                                            })
                                        ) : ''

                                        
                                    }
                                    <td>{ob.registerDate}</td>                                    
                                    <td>{ob.updateDate}</td>
                                </tr>                                
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={12}>데이터가 없습니다.</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </>
    );
}


function MapList(props){
    const list = props.list
    return (
        <>
            <Map
                id="map"
                center={{
                lat: 35.1925661,
                lng: 128.0838116,
                }}
                style={{
                width: "100%",
                height: "550px",
                }}
                level={3}
            >        
                <MapTypeControl position={"TOPRIGHT"} />
                <ZoomControl position={"RIGHT"} />

                <MapInfoWindow // 인포윈도우를 생성하고 지도에 표시합니다
                    position={{
                    // 인포윈도우가 표시될 위치입니다
                    lat: 35.1925661,
                    lng: 128.0838116,
                    }}
                    removable={true} // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다
                >
                    {/* 인포윈도우에 표출될 내용으로 HTML 문자열이나 React Component가 가능합니다 */}
                    <div style={{ padding: "5px", color: "#000" }}>서경방송</div>
                </MapInfoWindow>
                {
                    list.map((ob, i)=>{
                        return (
                            <MapInfoWindow // 인포윈도우를 생성하고 지도에 표시합니다
                                position={{
                                // 인포윈도우가 표시될 위치입니다
                                lat: ob.latitude,
                                lng: ob.longitude,
                                }}
                                removable={true} // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다
                            >
                                {/* 인포윈도우에 표출될 내용으로 HTML 문자열이나 React Component가 가능합니다 */}
                                <div style={{ padding: "5px", color: "#000" }}>{ob.projectName} {ob.userName} {ob.userNumber} </div>
                            </MapInfoWindow>                            
                        );
                    })
                }
            </Map>
        </>
    );
}