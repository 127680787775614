import { apiServer, fn_reAuth} from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        projectNo : searchParams.get("projectNo") || ''
    });

    const [list, setList] = useState([]);


    const fn_getDataList = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/itemList"
        + "?projectNo=" + params.projectNo

        const res = await fetch(url, {
                    method : 'get'
                    , headers : {
                        Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                    }
                });
        const data = await res.json();
        const list = data.itemList
        setList(list);
        fn_reAuth(fn_getDataList, data);
    }


    const fn_deleteAction = (userNo)=>{
        
    }

    useEffect(()=>{

        fn_getDataList(params);
        
    }, []);

    return (
        <>
            <Link to={"/qr/projectItem/insert?projectNo=" + params.projectNo} className="btn btn-primary">등록</Link>
            <table className="table list">
                <colgroup>
                    <col style={{width:'14%'}}/>
                    <col style={{width:'14%'}}/>
                    <col style={{width:'14%'}}/>
                    <col style={{width:'14%'}}/>
                    <col style={{width:'14%'}}/>
                    <col style={{width:'14%'}}/>
                    <col style={{width:'14%'}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>프로젝트</th>
                        <th>타입</th>
                        <th>항목 타입</th>
                        <th>항목명</th>
                        <th>사용여부</th>
                        <th>순서</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list!=null && list.length>0 ? (
                        list.map((ob, i)=>{
                            return (
                                <tr key={i}>
                                    <td>{ob.projectName}</td>
                                    <td>{ob.typeName}</td>
                                    <td>{ob.itemType == 'T' ? '입력' : '선택'}</td>
                                    <td className="text-left">
                                        <Link to={"/qr/projectItem/insert?projectNo="+ob.projectNo+"&itemNo=" + ob.itemNo}>{ob.itemName}</Link>                                        
                                    </td>
                                    <td>{ob.useFlag == 'Y' ? '사용중' : '미사용'}</td>
                                    <td>{ob.sortOrd}</td>
                                    <td>
                                        <button onClick={(e)=>{e.preventDefault(); fn_deleteAction(ob.typeNo);}} className="btn btn-danger">삭제</button>
                                    </td>
                                </tr>                                
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>데이터가 없습니다.</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </>        
    );
}

