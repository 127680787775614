import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        searchKeyword : searchParams.get('searchKeyword') || ''
        , category2 : searchParams.get('category2') || ''
    });

    const [list, setList] = useState(null);

    const fn_getMovieList = async (params)=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/movieList"
        + "?searchKeyword=" + params.searchKeyword
        + "&category2=" + params.category2;


        const res = await fetch(url);
        const data = await res.json();
        setList(data.movieList);
    }


    useEffect(()=>{
        fn_getMovieList(params);
    }, []);

    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="text" name="searchKeyword" defaultValue={params.searchKeyword}/> 
            </form>
            <ul className="movieList">
            {
                list!=null && list.length>0 ? (
                    list.map((ob, i)=>{
                        return (
                            <li key={i}>
                                <Link to={"/movieView?no=" + ob.no}>
                                    {
                                        ob.thumbnail!=null && ob.thumbnail!='' ? (
                                            <img src={"https://crizel.duckdns.org/crizel/upload/movie/" + ob.thumbnail}/>
                                        ) : ''
                                    }
                                    {ob.title}
                                </Link>
                            </li>                            
                        );
                    })
                ) : (
                    <>
                        데이터가 없습니다.
                    </>
                )
            }
            </ul>
        </>        
    );
}

