import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_list, fn_categoryList} from 'api/restaurant';

export default function App(){
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState({
        pageNo : searchParams.get("pageNo") || 1
    });

    const [list, setList] = useState(null);
    const [category1List, setCategory1List] = useState(null);
    const [category2List, setCategory2List] = useState(null);

    const fn_getRtList = async(params)=>{
        setLoading(true);
        const data = await fn_list(params)
        setList(data.rtList);
        setLoading(false);
    }

    const fn_getCategoryList = async (params)=>{
        const data = await fn_categoryList(params);
        setCategory1List(data.category1List);
        setCategory2List(data.category2List);
    }

    useEffect(()=>{
        fn_getCategoryList(params);
        fn_getRtList(params);
    }, [params]);

    return (
        <>
           <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="pageNo" defaultValue={params.pageNo}/>

                <div className="btn-area text-right">
                    <Link to="/rt/insert" className="btn btn-primary">등록</Link>
                </div>
           </form>

            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <table className="table list">
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>카테고리1</th>
                                <th>카테고리2</th>
                                <th>식당이름</th>
                                <th>식당주소</th>
                                <th>작성자</th>
                                <th>작성일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list!=null && list.length>0 ? (
                                    list.map((ob, i)=>{
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <span className="mob-title">번호</span>
                                                    {ob.no}
                                                </td>
                                                <td>
                                                    <span className="mob-title">카테고리1</span>
                                                    {ob.category1}
                                                </td>
                                                <td>
                                                    <span className="mob-title">카테고리2</span>
                                                    {ob.category2}
                                                </td>
                                                <td>
                                                    <span className="mob-title">식당이름</span>
                                                    <Link to={"/rt/view?no=" + ob.no}>
                                                        {ob.rstrTitle}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <span className="mob-title">식당주소</span>
                                                    {
                                                        ob.linkUrl!=null && ob.linkUrl!='' ? (
                                                            <a href={ob.linkUrl} target="_blank">{ob.rstrLocation}</a>
                                                        ) : (
                                                            <a href={"https://map.naver.com/p/search/" + ob.rstrTitle} target="_blank">{ob.rstrLocation}</a>
                                                        )

                                                    }
                                                </td>
                                                <td>
                                                    <span className="mob-title">작성자</span>
                                                    {ob.userName}
                                                </td>
                                                <td>
                                                    <span className="mob-title">작성일</span>
                                                    {ob.registerDate}
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={7}>데이터가 없습니다.</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                )
            }

        </>        
    );
}

