import { fn_reAuth } from 'component/info/serverInfo';
import Loading from 'component/Loading'
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [params, setParmas] = useState({
        custId : searchParams.get("custId") || '0001'
        , couponNo : ''
    });

    const [couponList, setCouponList] = useState([]);

    const ws = new WebSocket(process.env.REACT_APP_WS_URL);


    const fn_getCouponList = async (params)=>{
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/coupon/couponUseWaitList"
        + "?custId=" + params.custId

        const res = await fetch(url);
        const data = await res.json();

        setCouponList(data.couponUseWaitList);
        setLoading(false);
    }

    const fn_searchUseCd = async (params)=>{

        document.querySelector("#searchUseCdWrap").setAttribute("open", "open");

        document.postForm.useCd.focus();

    }

    const fn_approvalAction = (params)=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/coupon/approvalAction"
        const form = document.mainPostForm;
        let formData = new FormData(form);

        if(form.useCd.value == ""){
            alert('인증번호를 입력하여주시기 바랍니다.');
            form.useCd.focus();
        }else{
            if(window.confirm('쿠폰 사용을 승인하시겠습니까?')){            
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{

                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        document.querySelector("#searchUseCdWrap").removeAttribute("open"); 
                        fn_getCouponList(params);

                        ws.send(JSON.stringify({
                            event : 'scsCouponApproval'	
                            , data : "Y"
                        }));


                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }

                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                });
            }
        }

    }


    useEffect(()=>{
        fn_getCouponList(params);

        ws.onopen = () => {
            //console.log('웹소켓 연결됨');
        };
        ws.onmessage = (event) => {
            let obj = JSON.parse(event.data);
            if(obj.event == 'scsCouponUseAction' && obj.data == params.custId){
                fn_getCouponList(params);
            }
        };
        ws.onclose = () => {
            //console.log('웹소켓 연결 종료됨');
        };
        return () => {
            ws.close();
        };
    }, []);


    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <select name="custId" onChange={(e)=>{
                    const tmpParams = {
                        ...params
                        , custId : e.target.value
                    };
                    setParmas(tmpParams);
                    navigate("/coupon/admin/couponUseWaitList?custId=" + e.target.value)
                    fn_getCouponList(tmpParams)
                }} defaultValue={params.custId}>
                    <option value="0001">서경방송</option>
                    <option value="0002">코리아팀S</option>
                    <option value="0003">서경투어</option>
                    <option value="0004">서경엔카</option>
                </select>
            </form>
            {
                loading==true ? (
                    <Loading/>
                ) : (
                    couponList!=null&&couponList.length>0?(
                        <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={7}>
                                            <form name="mainPostForm">
                                                <input type="text" name="useCd" placeholder='인증번호 입력' style={{display:'inline-block', width:'150px'}}/>
                                                <input type="hidden" name="couponNo"/>
                                                <button className="btn btn-success" onClick={(e)=>{e.preventDefault(); fn_approvalAction(params);}}>승인</button>
                                                {
                                                    /*
                                                }
                                                <button className="btn btn-primary" onClick={(e)=>{
                                                    e.preventDefault();
                                                    fn_searchUseCd(params);
                                                }}>
                                                    인증번호 입력
                                                </button>
                                                {
                                                    */
                                                }
                                            </form>
                                            
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>번호</th>
                                        <th>이름</th>
                                        <th>연락처</th>
                                        <th>금액</th>
                                        <th>사용날짜</th>
                                        <th>인증번호</th>
                                        <th>상태</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        couponList.map((ob, i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {ob.couponNo}
                                                    </td>
                                                    <td>
                                                        {ob.userName}
                                                    </td>
                                                    <td>
                                                        {ob.userPhone}
                                                    </td>
                                                    <td>
                                                        {ob.price}
                                                    </td>
                                                    <td>
                                                        {ob.registerDate}
                                                    </td>
                                                    <td>
                                                        {
                                                            ob.useCd!=null && ob.useCd!='' ? (
                                                                <button className="btn btn-success" onClick={(e)=>{
                                                                    e.preventDefault(); 
                                                                    document.mainPostForm.useCd.value = ob.useCd
                                                                    document.mainPostForm.couponNo.value = ob.couponNo
                                                                    fn_approvalAction(params);       
                                                                    document.mainPostForm.useCd.value = ""; 
                                                                    document.mainPostForm.couponNo.value = "";
                                                                    }}>                                                                
                                                                    {ob.useCd}
                                                                </button>
                                                            ) : ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            ob.useFlag == 'W' ? (
                                                                <>
                                                                    승인 대기
                                                                </>                                                                
                                                            ) : (
                                                                <>
                                                                    승인 완료 ({ob.approvalDate})
                                                                </>         
                                                            )
                                                        }                              
                                                    </td>
                                                </tr>                                                    
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <>
                        데이터가 없습니다.
                        </>
                    )
                )
            }
            <SearchUseCd params={params} fn_getCouponList={fn_getCouponList}/>
        </>        
    );
}


function SearchUseCd(props){

    const [info, setInfo] = useState([]);

    const [dataView, setDataView] = useState(false);

    const params = props.params

    const fn_searchUseCd = async (params)=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/coupon/searchUseCd"
        + "?custId=" + params.custId
        + "&useCd=" + document.postForm.useCd.value

        const res = await fetch(url);
        const data = await res.json();

        if(data.couponUseInfo != null){
            setInfo(data.couponUseInfo);
            setDataView(true);
        }else{
            alert('해당하는 데이터가 없습니다.');
        }
    }

    const fn_approvalAction = (params)=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/coupon/approvalAction"
        const form = document.postForm;
        let formData = new FormData(form);

        if(form.useCd.value == ""){
            alert('인증번호를 입력하여주시기 바랍니다.');
            form.useCd.focus();
        }else{
            if(window.confirm('쿠폰 사용을 승인하시겠습니까?')){            
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{

                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        document.querySelector("#searchUseCdWrap").removeAttribute("open"); 
                        props.fn_getCouponList(params);
                        setInfo([]);

                        ws.send(JSON.stringify({
                            event : 'scsCouponApproval'	
                            , data : "Y"
                        }));


                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }

                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                });
            }
        }

    }
    const ws = new WebSocket(process.env.REACT_APP_WS_URL);
    const [message, setMessage] = useState('');
    useEffect(()=>{
        ws.onopen = () => {
            //console.log('웹소켓 연결됨');
        };
        ws.onmessage = (event) => {
            setMessage(event.data); // 서버로부터 온 메시지 업데이트
        };
        ws.onclose = () => {
            //console.log('웹소켓 연결 종료됨');
        };
        return () => {
            ws.close();
        };
    }, []);

    return (
        <dialog id="searchUseCdWrap" style={{width:'50%'}}>
            <button className="btn btn-danger" onClick={(e)=>{e.preventDefault(); document.querySelector("#searchUseCdWrap").removeAttribute("open"); setDataView(false)}}>닫기</button>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="text" name="useCd"/>
                <button className="btn btn-primary" onClick={(e)=>{e.preventDefault(); fn_searchUseCd(params);}}>검색</button>

                {
                    dataView == true && info!=null && info.couponNo!='' ? (
                        <>
                            <input type="hidden" name="couponNo" defaultValue={info.couponNo}/>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>
                                            {info.userName}
                                        </td>
                                        <td>
                                            {info.userPhone}
                                        </td>
                                        <td>
                                            {info.price}
                                        </td>
                                        <td>
                                            <button className="btn btn-success" onClick={(e)=>{e.preventDefault(); fn_approvalAction(params);}}>승인</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    ) : ''
                }


            </form>
        </dialog>
    );
}