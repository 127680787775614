import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo';

export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [info, setInfo] = useState({
        schNo : ''
        , schTitle : ''
        , delFlag : ''
        , registerOper : ''
        , registerOperName : ''
        , registerDate : ''
        , updateOper : ''
        , updateOperName : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteDate : ''
    });

    const [contentList, setContentList] = useState([]);

    const schNo = searchParams.get("schNo") || ''

    const [params, setParams] = useState({
        schNo : searchParams.get("schNo") || ''
    });

    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/scheduleInfo?schNo=" + params.schNo;

        const res = await fetch(url);
        const data = await res.json();

        if(data.scheduleInfo!=null){
        setInfo({
            schNo : data.scheduleInfo.schNo
            , schTitle : data.scheduleInfo.schTitle
            , delFlag : data.scheduleInfo.delFlag
            , registerOper : data.scheduleInfo.registerOper
            , registerOperName : data.scheduleInfo.registerOperName
            , registerDate : data.scheduleInfo.registerDate
            , updateOper : data.scheduleInfo.updateOper
            , updateOperName : data.scheduleInfo.updateOperName
            , updateDate : data.scheduleInfo.updateDate
            , deleteOper : data.scheduleInfo.deleteOper
            , deleteDate : data.scheduleInfo.deleteDate
        });
        }
        
        setContentList(data.contentList);
    }

  useEffect(()=>{
    
    fn_getData(params);

  }, []);

    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="schNo" defaultValue={info.schNo}/>
                <table className="table">
                <colgroup>
                    <col style={{width : '30%'}}/>
                    <col style={{width : '70%'}}/>
                </colgroup>
                <thead></thead>
                <tbody>
                    <tr>
                        <th>
                            제목
                        </th>
                        <td>
                            {info.schTitle}
                        </td>
                    </tr>     
                    <tr>
                        <th>
                            작성자
                        </th>
                        <td>
                            {info.registerOperName}
                        </td>
                    </tr>        
                    <tr>
                        <th>
                            작성일
                        </th>
                        <td>
                            {info.registerDate.substring(0, 19)}
                        </td>
                    </tr>      
                </tbody>
                </table>
                {
                schNo != null && schNo != '' ? (
                        <table className="table list">
                        <colgroup>
                        
                        </colgroup>
                        <thead>
                            <tr>
                            <th>번호</th>
                            <th>제목</th>
                            <th>순서</th>
                            <th>파일</th>
                            <th>재생시간</th>
                            </tr>
                        </thead>
                        <tbody id="contentList">
                            {
                            contentList!=null && contentList.length>0 ? (
                                contentList.map((ob, i)=>(
                                <tr>
                                    <td>
                                    {ob.contentNo}
                                    </td>
                                    <td>
                                    {ob.contentTitle}
                                    </td>
                                    <td>
                                    {ob.contentOrder}
                                    </td>
                                    <td className="text-left">
                                        <a href={apiServer + "/upload/" + ob.sysFileName} target="_blank">{ob.orgFileName}</a>
                                    </td>
                                    <td>
                                        {ob.filePlayTime}
                                    </td>
                                </tr>
                                ))
                            ) : ''
                            }
                        </tbody>
                        </table>
                ) : (
                    <div className="no-data">
                        스케쥴 작성 후 컨텐츠 등록이 가능합니다.
                    </div>
                )
                }
                <div className="btn-area">
                    <a href={"/mediaboard/scheduleInsert?schNo=" + info.schNo} className="btn btn-success" >수정</a>
                    <a href="/mediaboard/scheduleList" className="btn btn-list">목록</a>
                </div>

            </form>
        </>        
    );
}

