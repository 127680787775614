import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {useState, useEffect} from 'react'
import Loading from 'component/Loading'
import { api_commonFetch } from "api/commonFetch";


export default function GsList(){

    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [info, setInfo] = useState({
        no : ''
        , memberNo : ''
        , day : params.get('date') || ''
        , price : 0
        , items : ''
        , registerDate : ''
    });
    const [memberList, setMemberList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [vo, setVO] = useState({
        searchStartDate : params.get('date')
        , searchEndDate : params.get('date')
    });

    const fn_getGsInfo = async(vo)=>{
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/crizelApi/gsList'
        + "?searchStartDate=" + vo.searchStartDate
        + "&searchEndDate=" + vo.searchEndDate

        const config = {
            method : 'get'
            , headers : {
                Authorization : "Bearer " + sessionStorage.getItem('accessToken')
            }
        }

        const data = await api_commonFetch(url, config);
        const list = data.gsList;
        const info = list!=null&&list.length>0?list[0]:null;
        if(info!=null){
            const tmp = {
                no : info.NO
                , memberNo : info.MEMBER_NO
                , day : info.DAY
                , price : info.PRICE
                , items : info.ITEM
                , registerDate : info.REGISTER_DATE
            }
            setInfo(tmp);
        }        

        setLoading(false);
    }

    const fn_getMemberList = async()=>{
        const url = process.env.REACT_APP_API_URL + "/crizelApi/gsMemberList"
        const config = {
            method : 'get'            
        }
        const data = await api_commonFetch(url, config);
        setMemberList(data.gsMemberList);
    }

    const fn_postForm = async()=>{
        setLoading(true);
        let url = '';
        const form = document.gsPostForm;
        const formData = new FormData(form);


        if(form.no.value == ''){
            url = process.env.REACT_APP_API_URL + '/crizelApi/gsInsertAction'
        }else{
            url = process.env.REACT_APP_API_URL + '/crizelApi/gsUpdateAction'
        }

        const config = {
            method : 'post'
            , body : formData
            , headers : {
                Authorization : "Bearer " + sessionStorage.getItem('accessToken')
            }
        }

        const data = await api_commonFetch(url, config)
        if(data.result>0){
            fn_getGsInfo(vo);
        }else{
            alert('처리중 오류가 발생하였습니다.');
        }
        setLoading(false);
    }

    useEffect(()=>{
        fn_getMemberList();
        fn_getGsInfo(vo);
    }, []);


    if(loading) return <Loading/>
    return (
        <>
            <form name="gsPostForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="no" defaultValue={info.no}/>
                <table className="table">
                    <colgroup>
                        <col style={{width: '25%'}}/>
                        <col style={{width: '75%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>날짜</th>
                            <td>
                                <input type="date" name="day" defaultValue={info.day}/>
                            </td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>
                                <select name="memberNo" defaultValue={info.memberNo}>
                                {
                                    memberList!=null && memberList.length>0?(
                                        memberList.map((ob, i)=>{
                                            return (
                                                <option key={i} value={ob.MEMBER_NO}>{ob.MEMBER_NAME}</option>
                                            );
                                        })
                                    ):''
                                }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>금액</th>
                            <td>
                                <input type="number" name="price" defaultValue={info.price}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className="btn-area">
                                    <button className="btn btn-primary" onClick={(e)=>{
                                        e.preventDefault();
                                        fn_postForm();
                                    }}>확인</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}