import { api_commonFetch } from "api/commonFetch";

export const fn_getGirlsList = async (params, setLoading, setList)=>{

    setLoading(true);
    let url = process.env.REACT_APP_API_URL + "/crizelApi/girlsList"
    const config = {
        method : 'get'
        , cache : 'no-cache'
        , headers : {
            Authorization :  'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }
    
    const data = await api_commonFetch(url, config);

    setList(data.girlsList);

    setLoading(false);
}

export const fn_postForm = async(form, params, setLoading, setList, fn_getGirlsList, fn_close)=>{

    let url = "";       
    const formData = new FormData(form);
    let msg = "";
    let method = '';

    if(form.gno.value == ""){
        url = process.env.REACT_APP_API_URL + "/crizelApi/girlsInsertAction" ;
        msg = "등록하시겠습니까?"
        method = 'post'
    }else{
        url = process.env.REACT_APP_API_URL + "/crizelApi/girlsUpdateAction" ;
        msg = "수정하시겠습니까?"
        method = 'post'
    }

    if(form.name.value == ""){
        alert('이름을 입력하여주시기 바랍니다.');
        form.name.focus();
    }else if(form.addr.value == ""){
        alert('주소를 입력하여주시기 바랍니다.');
        form.addr.focus();
    }else{
        if(window.confirm(msg)){

            const config = {
                method : method
                , cache : 'no-cache'
                , body : formData
                , headers : {
                    Authorization : 'Bearer' + sessionStorage.getItem('accessToken')
                }
            }
            
            const data = await api_commonFetch(url, config);
            if(data.result>0){
                alert('정상적으로 처리되었습니다.');
                fn_getGirlsList(params, setLoading, setList);
                fn_close();
            }else{
                alert('처리중 오류가 발생하였습니다.');
            }    
        }
    }
}


export const fn_deleteAction = async(info, params, setLoading, setList)=>{
    const url = process.env.REACT_APP_API_URL + "/crizelApi/girlsDeleteAction"
    let formData = new FormData();
    formData.append("gno", info.gno);
    const config = {
        method : 'post'
        , cache : 'no-cache'
        , body : formData
        , headers : {
            Authorization : 'Bearer' + sessionStorage.getItem('accessToken')
        }
    }

    if(window.confirm('삭제하시겠습니까?')){
        const data = await api_commonFetch(url, config);
        if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            fn_getGirlsList(params, setLoading, setList);
        }else{
            alert('처리중 오류가 발생하였습니다.');
        } 
    }
        
}


export const fn_toggleBest = async (info, bestYn, params, setLoading, setList, fn_getGirlsList)=>{
    const url = process.env.REACT_APP_API_URL + "/crizelApi/girlsBestToggleAction"
    let formData = new FormData();
    formData.append('gno', info.gno);
    formData.append('best', bestYn);

    const config = {
        method : 'post'
        , cache : 'no-cache'
        , body : formData
        , headers : {
            Authorization : 'Bearer ' + sessionStorage.getItem('refreshToken')
        }
    }

    const data = await api_commonFetch(url, config);

    if(data.result>0){
        alert('정상적으로 처리되었습니다.');
        fn_getGirlsList(params, setLoading, setList);
    }else{
        alert('처리중 오류가 발생하였습니다');
    }

}


export const fn_getGirlsImgList = async (params, setLoading, setTotalList, fn_goPage)=>{        
    setLoading(true);

    let url = process.env.REACT_APP_API_URL + "/crizelApi/girlsImgList"
    + "?name=" + params.name

    const config = {
        method : 'get'
        , cache : 'no-cache'
        , headers : {
            Authorization :  'Bearer ' + sessionStorage.getItem('refreshToken')
        }
    }   

    const data = await api_commonFetch(url, config);

    setTotalList(data.girlsImgList);
    fn_goPage(params, data.girlsImgList);

    setLoading(false);
}