import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const [list, setList] = useState([]);

    const [searchparams, setSearchParams] = useSearchParams();

    const [params, setParmas] = useState({
        deviceSeq : searchparams.get('deviceSeq') || ''
    });

    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/scheduleList"
        + "?deviceSeq=" + params.deviceSeq
        
        const res = await fetch(url);
        const data = await res.json();

        setList(data.scheduleList);

    }
    useEffect(()=>{
        fn_getData(params);
    }, []);


    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>

            </form>
            <div className="btn-area">
                <Link to={"/mediaboard/schedule/insert?deviceSeq=" + params.deviceSeq} className="btn btn-primary">등록</Link>
            </div>

            <a href={apiServer + "/crizelApi/mediaBoard/scheduleList" + "?deviceSeq=" + params.deviceSeq} target="_blank">스케쥴관리 목록 데이터 URL</a>

            <table className="table list">
                <colgroup>
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>스케쥴명</th>
                        <th>스케쥴 타입</th>
                        <th>스케쥴 시작 날짜</th>
                        <th>스케쥴 종료 날짜</th>
                        <th>종일사용여부</th>
                        <th>스케쥴 시작시간</th>
                        <th>스케쥴 종료시간</th>
                        <th>스케쥴 재생시간</th>
                        <th>사용여부</th>
                        <th>컨텐츠 보기</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length > 0 ? (
                            list.map((ob, i)=>(
                                <tr key={i}>
                                    <td>
                                        <span className="mob-title">번호</span>
                                        {ob.schSeq}
                                    </td>
                                    <td>
                                        <span className="mob-title">스케쥴명</span>
                                        <Link to={"/mediaboard/schedule/insert?deviceSeq=" + params.deviceSeq + "&schSeq=" + ob.schSeq}>{ob.schTitle}</Link>
                                    </td>
                                    <td>
                                        <span className="mob-title">스케쥴 타입	</span>
                                        {ob.schType}
                                    </td>
                                    <td>
                                        <span className="mob-title">스케쥴 시작 날짜</span>
                                        {ob.schStartDate}
                                    </td>
                                    <td>
                                        <span className="mob-title">스케쥴 종료 날짜</span>
                                        {ob.schEndDate}
                                    </td>
                                    <td>
                                        <span className="mob-title">종일사용여부</span>
                                        {ob.schAllday=='Y'?'사용':'미사용'}
                                    </td>
                                    <td>
                                        <span className="mob-title">스케쥴 시작시간</span>
                                        {ob.schStartTime}
                                    </td>
                                    <td>
                                        <span className="mob-title">스케쥴 종료시간	</span>
                                        {ob.schEndTime}
                                    </td>
                                    <td>
                                        <span className="mob-title">스케쥴 재생시간</span>
                                        {ob.schPlayTime}
                                    </td>
                                    <td>
                                        <span className="mob-title">사용여부</span>
                                        {ob.activeFlag=='Y'?'사용':'미사용'}
                                    </td>
                                    <td>
                                        <span className="mob-title">컨텐츠 보기</span>
                                        <Link to={"/mediaboard/content/insert?contentSeq=" + ob.contentSeq}>컨텐츠 보기</Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={11} className="text-center">데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>        
    );

}