import { createSlice } from '@reduxjs/toolkit';

export const loginInfoSlice = createSlice({
  name: 'loginInfo',
  initialState: {
    accessToken : sessionStorage.getItem("accessToken")
    , refreshToken : sessionStorage.getItem("refreshToken")
    , memberId : sessionStorage.getItem("memberId")
    , memberName : sessionStorage.getItem("memberName")
    , memberRole : sessionStorage.getItem("memberRole")
  },
  reducers: {
    setAccessToken: (state, action) => {
        state.accessToken = action.payload
    }
    , setRefreshToken : (state, action) => {
        state.refreshToken = action.payload;
    }
    , setMemberId : (state, action) => {
        state.memberId = action.payload;
    }
    , setMemberName : (state, action) => {
        state.memberName = action.payload;
    }
    , setMemberRole : (state, action) => {
        state.memberRole = action.payload;
    }

  },
});

export const { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole } = loginInfoSlice.actions;

export const getLoginInfo = state => state.loginInfo;

export default loginInfoSlice.reducer;
