import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import Main from 'page/Main';
import NotFound from 'page/NotFound';
import Header from 'component/include/Header';
import Footer from 'component/include/Footer';

import CouponMain from 'page/coupon/Main'
import CouponLogin from 'page/coupon/Login'

import CouponUserCouponList from 'page/coupon/user/CouponList'
import CouponUserInventory from 'page/coupon/user/CouponInventory'
import CouponUserUseList from 'page/coupon/user/CouponUseList'

import CouponAdminCouponUseList from 'page/coupon/admin/CouponUseList'
import CouponAdminCouponUseWaitList from 'page/coupon/admin/CouponUseWaitList'

export const coupon = [

    {path : "/coupon", element : <CouponMain/>}
    , {path : "/coupon/login", element : <CouponLogin/>}
    , {path : "/coupon/user/couponList", element : <CouponUserCouponList/>}
    , {path : "/coupon/user/couponInventory", element : <CouponUserInventory/>}
    , {path : "/coupon/user/couponUseList", element : <CouponUserUseList/>}
    , {path : "/coupon/admin/couponUseWaitList", element : <CouponAdminCouponUseWaitList/>}
    , {path : "/coupon/admin/couponUseList", element : <CouponAdminCouponUseList/>}
];