import { apiServer, fn_reAuth} from 'component/info/serverInfo';

import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        projectNo : searchParams.get("projectNo") || ''
    });

    const [list, setList] = useState([]);


    const fn_getDataList = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/projectList"

        const res = await fetch(url, {
                        method : 'get'
                        , headers : {
                            Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                        }
                    });
        const data = await res.json();
        const list = data.projectList
        setList(list);
        fn_reAuth(fn_getDataList, data);

        

    }

    const fn_deleteAction = (projectNo)=>{

    }


    useEffect(()=>{

        fn_getDataList(params);
        
    }, []);

    return (
        <>
            <Link to={"/qr/project/insert"} className="btn btn-primary">등록</Link>
            <table className="table list">
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>프로젝트명</th>
                        <th>사용여부</th>
                        <th>항목 관리</th>
                        <th>타입 관리</th>
                        <th>그룹 관리</th>
                        <th>유저 관리</th>
                        <th>로그 조회</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list!=null && list.length>0 ? (
                        list.map((ob, i)=>{
                            return (
                                <tr key={i}>
                                    <td>{ob.projectNo}</td>
                                    <td className="text-left">
                                        <Link to={"/qr/project/insert?projectNo=" + ob.projectNo}>{ob.projectName}</Link>
                                    </td>
                                    <td>{ob.useFlag == 'Y' ? '사용'  : '미사용'}</td>
                                    <td>
                                        <Link to={"/qr/projectItem/list?projectNo=" + ob.projectNo}>항목관리</Link>
                                    </td>
                                    <td>
                                        <Link to={"/qr/type/list?projectNo=" + ob.projectNo}>타입관리</Link>
                                    </td>
                                    <td>
                                        <Link to={"/qr/group/list?projectNo=" + ob.projectNo}>그룹관리</Link>
                                    </td>
                                    <td>
                                        <Link to={"/qr/user/list?projectNo=" + ob.projectNo}>유저관리</Link>
                                    </td>
                                    <td>
                                        <Link to={"/qr/scanLog?projectNo=" + ob.projectNo}>로그조회</Link>
                                    </td>
                                    <td>
                                        <button onClick={(e)=>{e.preventDefault(); fn_deleteAction(ob.projectNo);}} className="btn btn-danger">삭제</button>
                                    </td>
                                </tr>                                
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={8}>데이터가 없습니다.</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </>        
    );
}

