import { api_getLoginInfo } from 'api/loginInfo';
import { jwtDecode } from 'jwt-decode';
import { useState, useEffect } from 'react';


export const useLoginInfo = () => {
  /*
  const [loginInfo, setLoginInfo] = useState({
    memberId : ''
    , memberName : ''
    , roleList : ''
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await api_getLoginInfo();
      if (data) {
        const tmp = {
            ...loginInfo
            , memberId : data.memberId
            , memberName : data.memberName
            , roleList : data.roleList
        }
        setLoginInfo(tmp);
      }

      const accessToken = sessionStorage.getItem('accessToken');

      if(accessToken!=null && accessToken!=''){
        const data = jwtDecode(accessToken);

        if (data) {
          const tmp = {
              ...loginInfo
              , memberId : data.memberId
              , memberName : data.memberName
              , roleList : data.roleList
          }
          setLoginInfo(tmp);
        }
      }
    };
    fetchData();
  }, []);

  return loginInfo;
  */

  //  JWT 토큰에서 정보를 가져올수 있었다..
  const accessToken = sessionStorage.getItem('accessToken');

  let tmp = {
    memberId : ''
    , memberName : ''
    , roleList : ''
    , profileImg : ''
  }
  if(accessToken!=null && accessToken!=''){
    const data = jwtDecode(accessToken);

    //console.log(data);

    if (data!=null) {
      tmp = {
          memberId : data.memberId
          , memberName : data.memberName
          , roleList : data.roleList
          , profileImg : data.profileImg
      }
    }
  }

  return tmp
};
