import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import Main from 'page/Main';
import NotFound from 'page/NotFound';
import Header from 'component/include/Header';
import Footer from 'component/include/Footer';

import  QrProjectList from 'page/qr/project/List'
import  QrProjectInsert from 'page/qr/project/Insert'

import  QrProjectItemList from 'page/qr/projectItem/List'
import  QrProjectItemInsert from 'page/qr/projectItem/Insert'

import  QrTypeList from 'page/qr/type/List'
import  QrTypeInsert from 'page/qr/type/Insert'

import  QrGroupList from 'page/qr/group/List'
import  QrGroupInsert from 'page/qr/group/Insert'

import  QrUserList from 'page/qr/user/List'
import  QrUserInsert from 'page/qr/user/Insert'

import  QrScanLog from 'page/qr/ScanLog'

import QrMain from 'page/qr/Main'

export const qr = [
    {path : "/qr", element : <QrMain/>}
    , {path : "/qr/project/list", element : <QrProjectList/>}
    , {path : "/qr/project/insert", element : <QrProjectInsert/>}
    , {path : "/qr/projectItem/list", element : <QrProjectItemList/>}
    , {path : "/qr/projectItem/insert", element : <QrProjectItemInsert/>}
    , {path : "/qr/type/list", element : <QrTypeList/>}
    , {path : "/qr/type/insert", element : <QrTypeInsert/>}
    , {path : "/qr/group/list", element : <QrGroupList/>}
    , {path : "/qr/group/insert", element : <QrGroupInsert/>}
    , {path : "/qr/user/list", element : <QrUserList/>}
    , {path : "/qr/user/insert", element : <QrUserInsert/>}
    , {path : "/qr/scanLog", element : <QrScanLog/>}    
];