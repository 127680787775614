import { api_commonFetch } from "./commonFetch";

export const fn_historyList = async (params)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/historyList";
    let config = {
        method : 'get'
    };

    const data = await api_commonFetch(url, config);
    return data;
}

export const fn_postForm = async (form, params, fn_close, fn_getHistoryList)=>{
    if(sessionStorage.getItem("accessToken") != ''){
        const formData = new FormData(form);
        let url = "";
        let msg = "";
        let method = "";

        if(form.no.value == ""){
            url = process.env.REACT_APP_API_URL + "/crizelApi/historyInsertAction";
            msg = "등록하시겠습니까?";
            method = "post"
        }else{
            url = process.env.REACT_APP_API_URL + "/crizelApi/historyUpdateAction";
            msg = "수정하시겠습니까?";
            method = "post";
        }

        if(form.histCategory1.value == ""){
            alert('업체명을 입력하여주시기 바랍니다.');
            form.histCategory1.focus();
        }else if(form.histDate.value == ""){
            alert('업무완료 날짜를 입력하여주시기 바랍니다.');
            form.histDate.focus();
        }else if(form.histContent.value == ""){
            alert('업무내용을 입력하여주시기 바랍니다.');
            form.histContent.focus();
        }else{
            if(window.confirm(msg)){

                let config = {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                    , headers : {
                        Authorization :  'Bearer ' + sessionStorage.getItem("accessToken")
                    }
                };
                const data = await api_commonFetch(url, config);

                if(data.result>0){
                    alert('정상적으로 처리되었습니다.');
                    fn_close();
                    fn_getHistoryList(params);
                }else{
                    alert('처리중 오류가 발생하였습니다.');
                }
            }
        }
    }else{
        alert('권한이 없습니다.');
    }
    
}

export const fn_deleteAction = async (params)=>{
    if(sessionStorage.getItem("accessToken") != ''){
        let url = process.env.REACT_APP_API_URL + "/crizelApi/historyDeleteAction"
        let formData = new FormData();
        formData.append("no", params.no);

        if(window.confirm('삭제하시겠습니까?')){
            let config = {
                method : 'post'
                , cache : 'no-cache'
                , body : formData
                , headers : {
                    Authorization :  'Bearer ' + sessionStorage.getItem("accessToken")
                }
            };
            const data = await api_commonFetch(url, config);

            if(data.result>0){
                alert('정상적으로 처리되었습니다.');
                fn_historyList(params);
            }else{
                alert('처리중 오류가 발생하였습니다.');
            }
        }
    }else{
        alert('권한이 없습니다.');
    }
    
}