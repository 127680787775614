import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

export default function App(props){
    const navigate = useNavigate();

    window.document.title = "CRIZEL - Mediaboard";

    const currUrl = window.location.href

    return (
        <>
            <header className="header">
                <ul className="menu depth1">
                    <li>
                        <Link to="/mediaboard/" >HOME</Link>
                    </li>
                    <li>
                        <Link to="/mediaboard/device/list" className={currUrl.indexOf('device/') > -1 ? ' active' : ''}>기기 관리</Link>
                    </li>
                    <li>
                        <Link to="/mediaboard/content/list" className={currUrl.indexOf('content/') > -1 ? ' active' : ''}>컨텐츠 관리</Link>
                    </li>
                    <li>
                        <Link to="/mediaboard/notice/list" className={currUrl.indexOf('notice/') > -1 ? ' active' : ''}>공지사항 관리</Link>
                    </li>
                    <li>
                        <Link to="/mediaboard/preview" className={currUrl.indexOf('preview/') > -1 ? ' active' : ''}>미리보기</Link>
                    </li>
                    <li>
                        <Link to="/mediaboard/static" className={currUrl.indexOf('static/') > -1 ? ' active' : ''}>통계</Link>
                    </li>
                </ul>
            </header>
        </>        
    );
}