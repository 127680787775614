import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import { fn_getGirlsList, fn_postForm, fn_toggleBest } from 'api/crizel/girls';
import { fn_deleteAction } from 'api/crizel/girls';
import { useLoginInfo } from 'hook/loginInfo';

export default function App(props){
    const navigate = useNavigate();

    const loginInfo = useLoginInfo();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState(null);
    const [info, setInfo] = useState({
        gno : ''
        , name : ''
        , addr : ''
        , type : ''
        , tag1 : ''
        , registerId : ''
        , best : ''
        , registerDate : ''
        , viewStatus : ''
        , mainImg : ''
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const [memberId, setMemberId] = useState(sessionStorage.getItem('memberId') || '');

   

    const [params, setParams] = useState({
    });

    

    
    const fn_insert = async ()=>{
        setInfo({
            gno : ''
            , name : ''
            , addr : ''
            , type : ''
            , tag1 : ''
            , registerId : ''
            , best : ''
            , registerDate : ''
            , viewStatus : ''
            , mainImg : ''
        });
        document.querySelector("#girls-dlg").setAttribute("open", 'open');
    }

    const fn_update = async (info)=>{
        console.log(info);
        setInfo({
            gno : info.gno
            , name : info.name
            , addr : info.addr
            , type : info.type
            , tag1 : info.tag1
            , registerId : info.registerId
            , best : info.best
            , registerDate : info.registerDate
            , viewStatus : info.viewStatus
            , mainImg : info.mainImg
        });
        document.querySelector("#girls-dlg").setAttribute("open", 'open');
    }


    const fn_close = ()=>{
        document.querySelector("#girls-dlg").removeAttribute("open");
    }

    useEffect(()=>{
        fn_getGirlsList(params, setLoading, setList);
    }, [loginInfo.memberId]);

    if(loginInfo.memberId != 'rhzhzh3') return (<div><Link to={"/login?returnUrl=" + encodeURIComponent("/crizel/girls/list")}>로그인 후 이용하여주시기 바랍니다.</Link></div>)
    else if(loading) return <Loading/>
    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>

                <div className="text-right">
                    <button onClick={(e)=>{
                        e.preventDefault()
                        fn_insert();
                    }} className="btn btn-primary">등록</button>
                </div>
            </form>
            <table className="table list">
                <colgroup>
                    <col style={{width:'10%'}}/>
                    <col style={{width:'15%'}}/>
                    <col style={{width:'auto'}}/>
                    <col style={{width:'15%'}}/>
                    <col style={{width:'15%'}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>타입</th>
                        <th>이름</th>
                        <th>주소</th>
                        <th>즐겨찾기</th>
                        <th>수정/삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length>0?(
                            list.map((ob, i)=>{
                                return (
                                    <tr key={i}>
                                        <td>
                                            {ob.type}
                                        </td>
                                        <td className="text-left">
                                            <Link to={"/crizel/girls/repository?name=" + ob.name}>{ob.name}</Link>
                                        </td>
                                        <td className="text-left">
                                            <a href={ob.addr} target="_blank">{ob.addr}</a>
                                        </td>
                                        <td>
                                            {
                                                ob.best == 'Y' ? (
                                                    <button className="btn btn-danger" onClick={(e)=>{
                                                        e.preventDefault();
                                                        fn_toggleBest(ob, 'N', params, setLoading, setList, fn_getGirlsList);
                                                    }}>즐겨찾기 해제</button>
                                                ) : (
                                                    <button className="btn btn-primary"onClick={(e)=>{
                                                        e.preventDefault();
                                                        fn_toggleBest(ob, 'Y', params, setLoading, setList, fn_getGirlsList);
                                                    }}>즐겨찾기 등록</button>
                                                )
                                            }
                                        </td>
                                        <td>
                                            <button className="btn btn-success" onClick={(e)=>{
                                                e.preventDefault();
                                                fn_update(ob);
                                            }}>수정</button>
                                            <button className="btn btn-danger" onClick={(e)=>{
                                                e.preventDefault();
                                                fn_deleteAction(ob, params, setLoading, setList);
                                            }}>삭제</button>
                                        </td>
                                    </tr>
                                );
                            })
                        ):  (
                            <tr>
                                <td colSpan={5}>데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            
            <dialog id="girls-dlg">
                <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                    <input type="hidden" name="gno" defaultValue={info.gno}/>
                    <table className="table">
                        <colgroup>
                            <col style={{width:'25%'}}/>
                            <col style={{width:'75%'}}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>구분</th>
                                <td>
                                    <select name="type" value={info.type} onChange={(e)=>{
                                        const tmp = {...info, type : e.target.value}
                                        setInfo(tmp);
                                    }}>
                                        <option value="insta">인스타그램</option>
                                        <option value="twitter">트위터</option>
                                        <option value="blog">기타</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <input type="text" name="name" value={info.name} onChange={(e)=>{
                                        const tmp = {...info, name : e.target.value}
                                        setInfo(tmp);
                                    }}/>
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td>
                                    <input type="text" name="addr" value={info.addr} onChange={(e)=>{
                                        const tmp = {...info, addr : e.target.value}
                                        setInfo(tmp);
                                    }}/>
                                </td>
                            </tr>
                            <tr>
                                <th>즐겨찾기 여부</th>
                                <td>
                                    <select name="best" value={info.best} onChange={(e)=>{
                                        const tmp = {...info, best : e.target.value}
                                        setInfo(tmp);
                                    }}>
                                        <option value="Y">즐겨찾기</option>
                                        <option value="N">일반</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="btn-area text-center">
                        <button onClick={(e)=>{e.preventDefault(); fn_postForm(document.postForm, params, setLoading, setList, fn_getGirlsList, fn_close);}} className="btn btn-primary">확인</button>
                        <button onClick={(e)=>{e.preventDefault(); fn_close();}} className="btn btn-danger">닫기</button>
                    </div>
                </form>
            </dialog>
        </>
    );
}