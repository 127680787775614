import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import RtList from 'page/restaurant/List'
import RtInsert from 'page/restaurant/Insert'
import RtView from 'page/restaurant/View'


export const restaurant = [
    {path:'/rt/', element : <RtList/>}
    , {path:'/rt/list', element : <RtList/>}
    , {path:'/rt/insert', element : <RtInsert/>}
    , {path:'/rt/view', element : <RtView/>}
];