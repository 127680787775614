import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading';

export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [corpList, setCorpList] = useState(null);
    const [corpLocationList, setCorpLocationList] = useState(null);

    const [params, setParams] = useState({
        corpLocation : searchParams.get('corpLocation') || ''
        , hole : searchParams.get('corpLocation') || ''
        , useFlag : searchParams.get('useFlag') || ''
        , searchKeyword : searchParams.get('searchKeyword') || ''
    });     

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    const fn_getCorpList = async (params)=>{
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/corpList'
        + "?corpLocation=" + params.corpLocation
        + "&hole=" + params.hole
        + "&useFlag=" + params.useFlag
        + "&searchKeyword=" + params.searchKeyword

        const res = await fetch(url);
        const data = await res.json();

        setCorpList(data.corpList);
        setLoading(false);
    }

    const fn_getCorpLocationList = async ()=>{
        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/corpLocationList'

        const res = await fetch(url);
        const data = await res.json();

        setCorpLocationList(data.corpLocationList);
        setLoading(false);
    }

    useEffect(()=>{
        fn_getCorpLocationList();
        fn_getCorpList(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                    <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                        <select name="corpLocation" defaultValue={params.corpLocation} onChange={(e)=>{
                            const tmp = {...params, corpLocation : e.target.value}
                            setParams(tmp);
                            fn_getCorpList(tmp);
                        }}>
                            <option value="">지역 선택</option>
                            {
                                corpLocationList!=null && corpLocationList.length>0 ? (
                                    corpLocationList.map((ob, i)=>{
                                        return (
                                            <option key={i} value={ob.corpLocation}>{ob.corpLocation}</option>
                                        );
                                    })
                                ) : ''
                            }
                        </select>
                        <input type="text" name="searchKeyword" defaultValue={params.searchKeyword} onKeyUp={(e)=>{
                            if(e.key == 'Enter'){
                                const tmp = {...params, searchKeyword : e.target.value}
                                setParams(tmp);
                                fn_getCorpList(tmp);
                            }
                        }}/>
                    </form>
                    <h3>총 {corpList==null?0:corpList.length} 건</h3>
                    <table className="table list">
                        <colgroup>
                            <col style={{width:'10%'}}/>
                            <col style={{width:'10%'}}/>
                            <col style={{width:'30%'}}/>
                            <col style={{width:'30%'}}/>
                            <col style={{width:'10%'}}/>
                            <col style={{width:'10%'}}/>
                        </colgroup>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>지역</th>
                                    <th>주소</th>
                                    <th>골프장명</th>
                                    <th>홀 수</th>
                                    <th>예약하기</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    corpList!=null && corpList.length>0 ? (
                                        corpList.map((ob, i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <span className="mob-title">번호</span>
                                                        {ob.corp}
                                                    </td>
                                                    <td>
                                                        <span className="mob-title">지역</span>
                                                        {ob.corpLocation}
                                                    </td>
                                                    <td className="text-left">
                                                        <span className="mob-title">주소</span>
                                                        <a href={"https://map.naver.com/p/search/" + ob.corpAddr} target="_blank">{ob.corpAddr}</a>
                                                    </td>
                                                    <td className="text-left">
                                                        <span className="mob-title">골프장명</span>
                                                        {ob.corpName}
                                                    </td>
                                                    <td>
                                                        <span className="mob-title">홀 수</span>
                                                        {ob.hole}
                                                    </td>
                                                    <td>
                                                        <Link className="btn btn-success" to={"/pg/reserve/timeList?corp=" + ob.corp}>예약하기</Link>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>데이터가 없습니다.</td>
                                        </tr>
                                    ) 
                                }
                            </tbody>
                    </table>
                    </>  
                )
            }
        </>      
    );
}

