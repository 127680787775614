import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {useState, useEffect, useRef} from 'react'
import { api_commonFetch } from "api/commonFetch";

export default function Home(){

    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [vo, setVO] = useState({
        deviceSeq : params.get('deviceSeq') || '1'
    });


    const [index, setIndex] = useState(0);
    const [playTime, setPlayTime] = useState(0);

    const [list, setList] = useState([]);

    const fn_getSchList = async(vo)=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/mediaBoard/scheduleList'
        + '?deviceSeq=' + vo.deviceSeq
        const config = {
            method : "get"
        }
        const res = await fetch(url, config);
        const data = await res.json();
        const list = data.scheduleList;
        setList(list);
        
        if(list!=null && list.length>0){
            setIndex(0);
            setPlayTime(list[0].schPlayTime);
        }
        
    }

    const fn_contentPlay = ()=>{
        const nextIndex = (index+1) % list.length;
        setIndex(nextIndex);
        setPlayTime(list[nextIndex].schPlayTime);
    }
    
    const renderFile = ()=>{
        if(list.length === 0) return;

        const currentFile = list[index];
        if(currentFile.fileType == 'I'){
            return (
                <img src={"https://crizel.duckdns.org/crizel/upload/mediaboard/" + currentFile.sysFileName} />
            )
        }else{
            return (
                <video src={"https://crizel.duckdns.org/crizel/upload/mediaboard/" + currentFile.sysFileName} controls autoPlay loop></video>
            )
        }
    }

    useEffect(() => {
      fn_getSchList(vo);
    }, [])

    useEffect(()=>{
        if(list.length === 0) return;

        const timer = setTimeout(fn_contentPlay, playTime * 1000);
        return () => clearTimeout(timer);

    }, [index, playTime]);

    return (
        <>
            <button onClick={(e)=>{
                e.preventDefault();
                fn_contentPlay();
            }}>다음</button>
            <div className="mobile-wrap">
                <div className="item">
                    {renderFile()}
                </div>
                <div className="mobile-footer">
                    FOOTER
                </div>
            </div>
        </>
    );    

}