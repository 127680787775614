
import { api_commonFetch } from 'api/commonFetch';
import React, {useState, useEffect, useRef} from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

export default function App(){


    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [vo, setVO] = useState({
        memberId : params.get('memberId') || ''
        , memberPw : params.get('memberPw') || ''
        , returnUrl : params.get('returnUrl') || '/coupon'
    });

    const fn_loginForm = async()=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/member/loginAction'
        const form = document.loginForm;
        const formData = new FormData(form);
        const config = {method : 'post', body : formData}

        const data = await api_commonFetch(url, config);

        if(data.result>0){

            sessionStorage.setItem('accessToken', data.accessToken);
            sessionStorage.setItem('refreshToken', data.refreshToken);

            navigate(vo.returnUrl);

        }else{
            alert('계정을 확인하여주시기 바랍니다.');
        }


    }

    return (
        <>
            <form name="loginForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="returnUrl" defaultValue={vo.returnUrl}/>
                <div className="login-wrap">
                    <div className="login-item">
                        <label htmlFor="memberId">아이디</label>
                        <div>
                            <input type="text" name="memberId" defaultValue={vo.memberId}/>
                        </div>
                    </div>
                    <div className="login-item">
                        <label htmlFor="memberPw">비밀번호</label>
                        <div>
                            <input type="password" name="memberPw" defaultValue={vo.memberPw}/>
                        </div>
                    </div>
                </div>

                <button className="btn-login" onClick={(e)=>{e.preventDefault(); fn_loginForm();}}>로그인</button>
            </form>
        </>
    );
}