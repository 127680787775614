import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';

export default function App(){


    return (
        <>
            <h1>[통계 입력]</h1>
            <ul>
                <li>
                    <p><strong>URL</strong></p>
                    <span>https://crizelr.duckdns.org/crizelApi/mediaboard/statsInsertAction</span>
                </li>
                <li>
                    <p><strong>METHOD</strong></p>
                    <span>POST</span>
                </li>
                <li>
                    <p><strong>PARAMETERS</strong></p>
                    <span>deviceStat</span>
                </li>
            </ul>
            
            
        </>        
    );

}