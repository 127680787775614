import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';

export default function App(props){
    const navigate = useNavigate();

    const loginInfo = useSelector(getLoginInfo);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const videoRef = useRef(null);

    const [list, setList] = useState(null);
    const [category1List, setCategory1List] = useState(null);
    const [category2List, setCategory2List] = useState(null);
    const [video, setVideo] = useState('');
    const [info, setInfo] = useState({
        videoNo : ''
        , title : ''
        , addr : ''
        , startTime : ''
        , endTime : ''
        , regdate : ''
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const [memberId, setMemberId] = useState(sessionStorage.getItem('memberId') || '');

    const [params, setParams] = useState({
        pageNo : searchParams.get('pageNo') || 1
        , countList : searchParams.get('countList') || 30
        , order : searchParams.get('order') || 'recent'
        , searchKeyword : searchParams.get('searchKeyword') || ''
        , index : searchParams.get('index') || 0
    });

    const fn_getRelayVideoList = async (params)=>{        
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/relayVideoList"

        await fn_authCheck()

        const res = await fetch(url, {
            headers : {
                Authorization :  'Bearer ' + loginInfo.accessToken
            }
        });
        const data = await res.json();

        setList(data.relayVideoList);
        setLoading(false);

        if(data.relayVideoList!=null && data.relayVideoList.length>0){
            setVideo("https://crizel.duckdns.org" + data.relayVideoList[Number(params.index)].ADDR);
        }

        
    }

    const fn_update = (info)=>{
        setInfo({
            videoNo : info.VIDEO_NO
            , title : info.TITLE
            , addr : info.ADDR
            , startTime : info.START_TIME
            , endTime : info.END_TIME
            , regdate : info.REGDATE
        })
        document.querySelector("#update-wrap").setAttribute("open", "open");
    }

    const fn_dlgClose = ()=>{
        document.querySelector("#update-wrap").removeAttribute("open");
    }

    const fn_deleteAction = (info)=>{

    }

    const fn_postForm = ()=>{

    }

    const fn_prev = ()=>{
        let index = params.index-1<=0?0:params.index-1;
        const info = list[index];
        setVideo("https://crizel.duckdns.org" + info.ADDR);
        const tmp = {...params, index : index}
        setParams(tmp);
        fn_play(info);
    }

    const fn_next = ()=>{
        let index = params.index+1>list.length?list.length:params.index+1;
        const info = list[index];
        setVideo("https://crizel.duckdns.org" + info.ADDR);
        const tmp = {...params, index : index}
        setParams(tmp);
        fn_play(info);
    }

    const fn_play = (info)=>{
        videoRef.current.play();
        videoRef.current.currentTime = info.START_TIME;

        console.log(info.START_TIME);
    }


    useEffect(()=>{
        if(memberId == 'rhzhzh3'){
            fn_getRelayVideoList(params);

            const video = document.querySelector("rdvVideo");

            if(video!=null){
                video.addEventListener("timeupdate", function () {
                    if(video.currentTime == video.duration){
                        fn_next();
                    }
                });
            }
            
        }else{
            navigate('/')
        }

        
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <div>
                            <video id="rdvVideo" src={video} ref={videoRef} autoPlay controls preload="auto">
                                <source src={video} type="video/mp4"/>
                                <source src={video} type="video/ogg"/>
                                <source src={video} type="video/webm"/>
                                <source src={video} type="video/avi"/>
                                <source src={video} type="video/mp3"/>
                                <source src={video} type="video/mkv"/>
                                <source src={video} type="video/mov"/>
                                <source src={video} type="video/wmv"/>
                                <source src={video} type="video/vob"/>    
                            </video>                                        
                            <ul className="tab-menu">
                                <li>
                                    <button onClick={(e)=>{ 
                                        e.preventDefault();
                                        fn_prev();
                                    }} className="btn btn-success">이전</button>
                                </li>
                                <li>
                                    <button onClick={(e)=>{
                                        e.preventDefault();
                                    }} className="btn btn-primary">전체화면</button>
                                </li>
                                <li>
                                    <button onClick={(e)=>{
                                        e.preventDefault();
                                        fn_next();
                                    }} className="btn btn-success">다음</button>
                                </li>
                            </ul>
                        </div>
                        <table className="table">
                            <colgroup>
                                <col style={{width:'25%'}}/>
                                <col style={{width:'50%'}}/>
                                <col style={{width:'20%'}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>날짜</th>
                                    <th>제목</th>
                                    <th>수정/삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list!=null && list.length>0?(
                                        list.map((ob, i)=>{
                                            return (
                                                <tr key={i} className={Number(params.index) == i ? 'active' : ''}>
                                                    <td>
                                                        {ob.REGDATE}
                                                    </td>
                                                    <td className="text-left">
                                                        <button onClick={(e)=>{
                                                            e.preventDefault();
                                                            setVideo("https://crizel.duckdns.org/" + ob.ADDR);
                                                            const tmp = {...params, index : i}
                                                            setParams(tmp);
                                                            fn_play(ob);
                                                        }}>{ob.ADDR}</button>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-success" onClick={(e)=>{
                                                            e.preventDefault();
                                                            fn_update(ob);
                                                        }}>수정</button>
                                                        <button className="btn btn-danger" onClick={(e)=>{
                                                            e.preventDefault();
                                                            fn_deleteAction(ob);
                                                        }}>삭제</button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ):  (
                                        <tr>
                                            <td colSpan={5}>데이터가 없습니다.</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        
                        <dialog id="update-wrap" className="wp-50">
                            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                                <input type="hidden" name="rdvNo" defaultValue={info.rdvNo}/>
                                <table className="table">
                                    <colgroup>
                                        <col style={{width:'25%'}}/>
                                        <col style={{width:'75%'}}/>
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>카테고리1</th>
                                            <th>
                                                <select defaultValue={info.category1} onChange={(e)=>{
                                                    e.preventDefault();
                                                    const tmp = {...info, category1 : e.target.value}
                                                    setInfo(tmp);
                                                }}>
                                                    <option value="">카테고리1</option>
                                                    {
                                                        category1List!=null && category1List.length>0?(
                                                            category1List.map((ob, i)=>{
                                                                return (
                                                                    <option key={i} value={ob.category1}>{ob.category1}</option>                                                                    
                                                                );
                                                            })
                                                        ):''
                                                    }
                                                </select>
                                                <input type="text" name="category1" defaultValue={info.category1}/>
                                            </th>                                            
                                        </tr>
                                        <tr>
                                            <th>카테고리2</th>
                                            <th>
                                                <select defaultValue={info.category2} onChange={(e)=>{
                                                    e.preventDefault();
                                                    const tmp = {...info, category2 : e.target.value}
                                                    setInfo(tmp);
                                                }}>
                                                    <option value="">카테고리1</option>
                                                    {
                                                        category2List!=null && category2List.length>0?(
                                                            category2List.map((ob, i)=>{
                                                                return (
                                                                    <option key={i} value={ob.category2}>{ob.category2}</option>                                                                    
                                                                );
                                                            })
                                                        ):''
                                                    }
                                                </select>
                                                <input type="text" name="category2" defaultValue={info.category2}/>
                                            </th>                                            
                                        </tr>
                                        <tr>
                                            <th>파일명</th>
                                            <th>
                                                <input type="text" name="rdvPath" defaultValue={info.rdvPath} onChange={(e)=>{
                                                    let name = e.target.value.substring(e.target.value.lastIndexOf('/')+1, e.target.value.length)
                                                    console.log(name);
                                                    const tmp = {...info, rdvPath : e.target.value, rdvName : name}
                                                    setInfo(tmp);
                                                }}/>
                                                <input type="text" name="rdvName" defaultValue={info.rdvName} onChange={(e)=>{
                                                    let path = info.rdvPath.substring(0, info.rdvPath.lastIndexOf("/")+1) + e.target.value
                                                    const tmp = {...info, rdvPath : path, rdvName : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                            </th>                                            
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="btn-area text-center">
                                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className="btn btn-primary">확인</button>
                                    <button onClick={(e)=>{e.preventDefault(); fn_dlgClose();}} className="btn btn-danger">닫기</button>
                                </div>
                            </form>
                        </dialog>
                    </>
                )
            }
        </>
    );
}