import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from '../../features/loginInfo/loginInfoSlice';
import { useLoginInfo } from 'hook/loginInfo';

export default function App(props){
    const navigate = useNavigate();
    window.document.title = "CRIZEL - CRIZEL";

    const loginInfo = useLoginInfo();

    const fn_logout = ()=>{
        sessionStorage.setItem('accessToken', '');
        sessionStorage.setItem('refreshToken', '');
        sessionStorage.setItem('memberName', '');
        window.location.href='/';
    }  

    const fn_login = ()=>{
        navigate("/login?returnUrl=" + window.location.href);
    }

    useEffect(()=>{
    }, []);
    if(loginInfo.memberId != 'rhzhzh3'){ navigate('/');}
    return (
        <>
            <header className="naver header">
                <ul className="menu depth1">
                    <li>
                        <Link to="/crizel/">홈</Link>
                    </li>
                    <li>
                        <Link to="/crizel/ani">애니</Link>
                    </li>
                    <li>
                        <Link to="/crizel/girls/list">사진</Link>
                    </li>
                    <li>
                        <Link to="/crizel/rdv/list">RDV</Link>
                    </li>
                    <li>
                        <Link to="/crizel/diary">일기</Link>
                    </li>
                    <li>
                        {
                            loginInfo.memberId != null && loginInfo.memberId != '' ? (
                                <button onClick={(e)=>{e.preventDefault(); fn_logout();}}>{loginInfo.memberName} 로그아웃</button>
                            ) : (
                                <button onClick={(e)=>{e.preventDefault(); fn_login();}} >로그인</button>
                            )
                        }
                    </li>
                </ul>
            </header>
        </>        
    );
}