import { createSlice, PayloadAction  } from '@reduxjs/toolkit';
  

const initialState = {
  memberId : ''
  , memberName : ''
  , roleList : 9
  , profileImg : ''
};

  export const loginReducer = createSlice({
    name: 'counter',
    initialState,
    reducers: {
      login: (state, action) => {
        state.memberId = action.payload.loginInfo.memberId
        state.memberName = action.payload.loginInfo.memberName
        state.roleList = action.payload.loginInfo.roleList
        state.profileImg = action.payload.loginInfo.profileImg
      },
      logout: (state) => {
        state.memberId = ''
        state.memberName = ''
        state.roleList = 9
        state.profileImg = ''
      }
    },
  });
  
  export const { login, logout} = loginReducer.actions;
  
  export default loginReducer.reducer;
  