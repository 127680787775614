import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading';
import { fn_authCheck } from 'component/info/serverInfo';

export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [timeList, setTimeList] = useState(null);

    const [params, setParams] = useState({
        corp : searchParams.get('corp') || ''
    });     

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    const fn_timeList = async(params)=>{
        setLoading(true);
        await fn_authCheck();
        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/timeList'
        + "?corp=" + params.corp
        ;

        const res = await fetch(url);
        const data = await res.json();

        setTimeList(data.timeList);
        setLoading(false);
    }

    const fn_postForm = ()=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/parkGolf/timeInsertAction"
        const form = document.postForm;
        const formData = new FormData(form);

        if(window.confirm('변경하시겠습니까?')){
            fetch(url, {
                method : 'post'
                , cache : 'no-cache'
                , body : formData
            }).then((res)=>res.json()).then((data)=>{
                if(data.result>0){
                    alert('정상적으로 처리되었습니다.');
                    form.startTime.value = '09:00';
                    form.endTime.value = '10:00';
                    form.basicAmount.value = '';
                    form.discountAmount.value = '';
                    form.maxCount.value = '20';
                    form.useFlag.value = 'Y';
                    fn_timeList(params);
                }else{
                    alert(data.resultMsg!=''?data.resultMsg:'처리중 오류가 발생하였습니다.');
                }
            }).catch((err)=>{
                alert('처리중 오류가 발생하였습니다.');
            });
        }
        
    }

    useEffect(()=>{
        fn_timeList(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <form name="postForm" onSubmit={(e)=>{e.preventDefault()}}>
                            <input type="hidden" name="corp" defaultValue={params.corp}/>   
                            <table className="table list">
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>시작시간</th>
                                        <th>종료시간</th>
                                        <th>기본요금</th>
                                        <th>할인요금</th>
                                        <th>최대신청인원</th>
                                        <th>사용여부</th>
                                    </tr>
                                </thead>
                                <tbody id="timeList">
                                    <tr>
                                        <td>
                                            신규등록
                                        </td>
                                        <td>
                                            <input type="time" name="startTime" defaultValue="09:00" placeholder='시작 시간'/>
                                            <input type="hidden" name="timeNo"/>
                                        </td>
                                        <td>
                                            <input type="time" name="endTime" defaultValue="10:00" placeholder='종료 시간'/>
                                        </td>
                                        <td>
                                            <input type="number" name="basicAmount" placeholder='기본요금'/>
                                        </td>
                                        <td>
                                            <input type="number" name="discountAmount" placeholder='할인된 요금' />
                                        </td>
                                        <td>
                                            <input type="number" name="maxCount" defaultValue={20} placeholder='최대신청인원'/>
                                        </td>
                                        <td>
                                            <select name="useFlag">
                                                <option value="Y">사용</option>
                                                <option value="N">미사용</option>
                                            </select>
                                        </td>
                                    </tr>        
                                    {
                                        timeList!=null && timeList.length>0 ? (
                                            timeList.map((ob, i)=>{
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {ob.timeNo}
                                                        </td>
                                                        <td>
                                                            <input type="time" name="startTimeArr" defaultValue={ob.startTime} placeholder='시작 시간'/>
                                                            <input type="hidden" name="timeNoArr" defaultValue={ob.timeNo}/>
                                                        </td>
                                                        <td>
                                                            <input type="time" name="endTimeArr" defaultValue={ob.endTime} placeholder='종료 시간'/>
                                                        </td>
                                                        <td>
                                                            <input type="number" name="basicAmountArr" defaultValue={ob.basicAmount} placeholder='기본요금'/>
                                                        </td>
                                                        <td>
                                                            <input type="number" name="discountAmountArr" defaultValue={ob.discountAmount} placeholder='할인된 요금' />
                                                        </td>
                                                        <td>
                                                            <input type="number" name="maxCountArr" defaultValue={ob.maxCount} placeholder='최대신청인원'/>
                                                        </td>
                                                        <td>
                                                            <select name="useFlagArr" defaultValue={ob.useFlag}>
                                                                <option value="Y">사용</option>
                                                                <option value="N">미사용</option>
                                                            </select>
                                                        </td>
                                                    </tr>                                     
                                                );
                                            })
                                        ) : ''
                                    }
                                </tbody>
                            </table>

                            <div className="btn-area text-center">
                                <button onClick={(e)=>{
                                    e.preventDefault();
                                    fn_postForm();
                                }} className="btn btn-primary">확인</button>
                                <button onClick={(e)=>{
                                    e.preventDefault();
                                    if(window.confirm("목록으로 이동하시겠습니까?")){
                                        navigate('/pg/corp/list');
                                    }
                                }} className="btn btn-list">
                                    목록
                                </button>
                            </div>
                        </form>   
                    </>  
                )
            }
        </>      
    );
}

