import { apiServer } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <>
            회원가입이 완료되었습니다
            <a href="/login">로그인 하러가기</a>
        </>        
    );
}

