import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import { apiServer } from 'component/info/serverInfo';

import Loading from 'component/Loading'
import { fn_getStudyInfo } from 'api/study';

export default function App(){
    const navigate = useNavigate();

    window.document.title = "CRIZEL - Study";

    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        no : searchParams.get("no") || ''
    });

    const [info, setInfo] = useState({
        no : ''
        , category1 : ''
        , category2 : ''
        , title : ''
        , content : ''
        , userId : ''
        , userName : ''
        , regdate : ''
    });
    const [category1List, setCategory1List] = useState([]);
    const [category2List, setCategory2List] = useState([]);

    const fn_getData = async (params)=>{
        setLoading(true);
        const data = await fn_getStudyInfo(params);
        if(data.info!=null){
            setInfo({
                no : data.info.no
                , category1 : data.info.category1
                , category2 : data.info.category2
                , title : data.info.title
                , content : data.info.content
                , userId : data.info.userId
                , userName : data.info.userName
                , regdate : data.info.regdate
            });
        }
        setCategory1List(data.category1List);
        setCategory2List(data.category2List);
        setLoading(false);
    }

    useEffect(()=>{
        fn_getData(params);
    }, []);

    if(loading) return <Loading/>
    return (
        <>
            <table className="table">
                <colgroup>
                    <col style={{width:'30%'}}/>
                    <col style={{width:'70%'}}/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>카테고리1</th>
                        <td className="text-left">{info.category1}</td>
                    </tr>
                    <tr>
                        <th>카테고리2</th>
                        <td className="text-left">{info.category2}</td>
                    </tr>
                    <tr>
                        <th>제목</th>
                        <td className="text-left">{info.title}</td>
                    </tr>
                    <tr>
                        <th>작성자</th>
                        <td className="text-left">{info.userName}</td>
                    </tr>
                    <tr>
                        <th>작성일</th>
                        <td className="text-left">{info.regdate}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-left" dangerouslySetInnerHTML={{__html : info.content.split("\n").join("<br>")}}></td>
                    </tr>
                </tbody>
            </table>

            <div class="btn-area">
                <Link to={"/studyList"} className="btn btn-list">목록</Link>
            </div>
        </>        
    );
}

