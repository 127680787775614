import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';
import FoodInfo from 'component/info/FoodInfo'

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import GsList from './GsList';

export default function App(props){
    const navigate = useNavigate();

    const loginInfo = useSelector(getLoginInfo);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    
    const [searchParams, setSearchParams] = useSearchParams();

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const [memberId, setMemberId] = useState(sessionStorage.getItem('memberId') || '');

   

    const [params, setParams] = useState({
        date : searchParams.get('date') || ''
    });



    const [list, setList] = useState(null);
    const [info, setInfo] = useState({
        moneyNo : ''
        , diaryId : ''
        , moneyTitle : ''
        , moneyValue : ''
        , moneyDate : params.date
        , userId : ''
        , moneyType : ''
    });

    const [diaryInfo, setDiaryInfo] = useState({
        diaryId : ''
        , diaryDate : ''
        , content : ''
        , deleteStatus : ''
        , userId : ''
    });

    const [foodInfo, setFoodInfo] = useState({
        menuNo : ''
        , menuDate : ''
        , menuItem : ''
        , menuLike : ''
        , registerDate : ''
        , url : ''
        , img : ''
    });
    const fn_getDiaryInfo = async (params)=>{

        await fn_authCheck()

        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/diaryInfo"
        + "?diaryDate=" + params.date

        const res = await fetch(url, {
            headers : {
                Authorization :  'Bearer ' + loginInfo.accessToken
            }
        });
        const data = await res.json();

        if(data.diaryInfo!=null){
            setDiaryInfo({
                diaryId : data.diaryInfo.DIARY_ID
                , diaryDate : data.diaryInfo.DIARY_DATE
                , content : data.diaryInfo.CONTENT
                , deleteStatus : data.diaryInfo.DELETE_STATUS
                , userId : data.diaryInfo.USER_ID
            });
        }

        setLoading(false);
    }

    
    const fn_getMoneyList = async (params)=>{

        await fn_authCheck()


        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/moneyList"
        + "?moneyDate=" + params.date

        const res = await fetch(url, {
            headers : {
                Authorization :  'Bearer ' + loginInfo.accessToken
            }
        });
        const data = await res.json();

        setList(data.moneyList);
        setLoading(false);
    }

    const fn_getFoodInfo = async (params)=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/foodInfo"
        + "?menuDate=" + params.date
        ;

        const res = await fetch(url);
        const data = await res.json();

        if(data.foodInfo!=null){
            setFoodInfo({
                menuNo : data.foodInfo.MENU_NO
                , menuDate : data.foodInfo.MENU_DATE
                , menuItem : data.foodInfo.MENU_ITEM
                , menuLike : data.foodInfo.MENU_LIKE
                , registerDate : data.foodInfo.REGISTER_DATE
                , url : data.foodInfo.URL
                , img : data.foodInfo.IMG
            })
        }

    }

    
    const fn_insert = async (id)=>{
        if(id == "#money-dlg"){
            setInfo({
                moneyNo : ""
                , diaryId : ""
                , moneyTitle : ""
                , moneyValue : ""
                , moneyDate : ""
                , userId : ""
                , moneyType : ""
            });
        }
        
        document.querySelector(id).setAttribute("open", 'open');
    }

    const fn_update = async (id, info)=>{

        if(id == "#money-dlg"){
            setInfo({
                moneyNo : info.MONEY_NO
                , diaryId : info.DIARY_ID
                , moneyTitle : info.MONEY_TITLE
                , moneyValue : info.MONEY_VALUE
                , moneyDate : info.MONEY_DATE
                , userId : info.USER_ID
                , moneyType : info.MONEY_TYPE
            });
        }
        
        document.querySelector(id).setAttribute("open", 'open');
    }


    const fn_moneyPostForm = async()=>{
        await fn_authCheck()

        let url = "";
        const form = document.moneyPostForm;
        const formData = new FormData(form);

        if(form.moneyNo.value == ""){
            url = process.env.REACT_APP_API_URL + "/crizelApi/moneyInsertAction"            
        }else{
            url = process.env.REACT_APP_API_URL + "/crizelApi/moneyUpdateAction"            
        }

        fetch(url, {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
            , headers : {
                Authorization : 'Bearer ' + loginInfo.accessToken
            }
        }).then((res)=>res.json()).then((data)=>{
            if(data.result>0){
                fn_getMoneyList(params);
            }else{
                alert('처리중 오류가 발생하였습니다.');
            }
        }).catch((err)=>{
            alert('처리중 오류가 발생하였습니다.' + err);
        });

    }

    const fn_postForm = async()=>{

        await fn_authCheck()

        let url = "";       
        const form = document.postForm;
        const formData = new FormData(form);
        let msg = "";
        let method = '';

        if(form.gno.value == ""){
            url = process.env.REACT_APP_API_URL + "/crizelApi/girlsInsertAction" ;
            msg = "등록하시겠습니까?"
            method = 'post'
        }else{
            url = process.env.REACT_APP_API_URL + "/crizelApi/girlsUpdateAction" ;
            msg = "수정하시겠습니까?"
            method = 'post'
        }

        if(form.name.value == ""){
            alert('이름을 입력하여주시기 바랍니다.');
            form.name.focus();
        }else if(form.addr.value == ""){
            alert('주소를 입력하여주시기 바랍니다.');
            form.addr.focus();
        }else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : method
                    , cache : 'no-cache'
                    , body : formData
                    , headers : {
                        Authorization : 'Bearer' + loginInfo.accessToken
                    }
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        fn_close();
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }                     
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.' + err);
                });
            }
        }
    }

    const fn_deleteAction = async(info)=>{
        await fn_authCheck()
        const url = process.env.REACT_APP_API_URL + "/crizelApi/girlsDeleteAction"
        let formData = new FormData();
        formData.append("gno", info.gno);

        if(window.confirm('삭제하시겠습니까?')){
            fetch(url, {
                method : 'post'
                , cache : 'no-cache'
                , body : formData
                , headers : {
                    Authorization : 'Bearer' + loginInfo.accessToken
                }
            }).then((res)=>res.json()).then((data)=>{
                if(data.result>0){
                    alert('정상적으로 처리되었습니다.');
                }else{
                    alert('처리중 오류가 발생하였습니다.');
                }                     
            }).catch((err)=>{
                alert('처리중 오류가 발생하였습니다.' + err);
            });
        }
            
    }
    const fn_close = (id)=>{
        document.querySelector(id).removeAttribute("open");
    }





    const fn_diaryPostForm = async ()=>{

        await fn_authCheck()


        const form = document.diaryPostForm;
        const formData = new FormData(form);
        let url = "";
        let msg = "";

        if(form.diaryId.value == ""){
            url = process.env.REACT_APP_API_URL + "/crizelApi/diaryInsertAction"            
        }else{
            url = process.env.REACT_APP_API_URL + "/crizelApi/diaryUpdateAction"
        }

        if(form.content.value == ""){
            alert('내용을 입력하여주시기 바랍니다.');
            form.content.focus();
        }else{
            fetch(url, {
                method : 'post'
                , cache : 'no-cache'
                , body : formData
                , headers : {
                    Authorization : 'Bearer ' + loginInfo.accessToken
                }
            }).then((res)=>res.json()).then((data)=>{
                if(data.result>0){
                    fn_getDiaryInfo(params)
                }else{
                    alert('처리중 오류가 발생하였습니다.');
                }
            }).catch((err)=>{
                alert('처리중 오류가 발생하였습니다.');
            });
        }
    }  

    useEffect(()=>{
        fn_getMoneyList(params);            
        fn_getDiaryInfo(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <table className="table list">
                            <thead>
                                <tr>
                                    <th>타입</th>
                                    <th>항목</th>
                                    <th>금액</th>
                                    <th>수정/삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colSpan={4}>
                                        <button onClick={(e)=>{
                                            e.preventDefault();
                                            fn_insert("#money-dlg");
                                        }} className="btn btn-primary">신규등록</button>
                                    </th>
                                </tr>
                                {
                                    list!=null && list.length>0?(
                                        list.map((ob, i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <span className="mob-title">타입</span>
                                                        {ob.MONEY_TYPE}
                                                    </td>
                                                    <td className="text-left">
                                                        <span className="mob-title">항목</span>
                                                        {ob.MONEY_TITLE}
                                                    </td>
                                                    <td className="text-left">
                                                        <span className="mob-title">금액</span>
                                                        {ob.MONEY_VALUE}
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-success" onClick={(e)=>{
                                                            e.preventDefault();
                                                            fn_update("#money-dlg", ob);
                                                        }}>수정</button>
                                                        <button className="btn btn-danger" onClick={(e)=>{
                                                            e.preventDefault();
                                                            fn_deleteAction(ob);
                                                        }}>삭제</button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ):  (
                                        <tr>
                                            <td colSpan={5}>데이터가 없습니다.</td>
                                        </tr>
                                    )
                                }
                                <tr>
                                    <td colSpan={3} className="text-left">
                                        <span dangerouslySetInnerHTML={{ __html: diaryInfo.content.split("\n").join("<br>") }}></span>
                                    </td>
                                    <td>
                                        <button className="btn btn-primary" onClick={(e)=>{
                                            e.preventDefault();
                                            fn_update('#diary-dlg');
                                        }}>수정</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <FoodInfo date={params.date}/>

                        <GsList date={params.date}/>

                        <div className="btn-area">
                            <Link className="btn btn-list" to="/crizel/diary">뒤로</Link>
                        </div>
                        
                        <dialog id="money-dlg">
                            <form name="moneyPostForm" onSubmit={(e)=>{e.preventDefault();}}>
                                <input type="hidden" name="moneyNo" defaultValue={info.moneyNo}/>
                                <input type="hidden" name="moneyDate" defaultValue={params.date}/>
                                <ul className="tab-menu">
                                    <li>
                                        <button onClick={(e)=>{
                                            e.preventDefault();
                                            document.moneyPostForm.type.value = "교통비";
                                            document.moneyPostForm.moneyTitle.value = "시내버스";
                                            document.moneyPostForm.moneyValue.value = "2900"
                                            fn_moneyPostForm();
                                        }} className="btn btn-primary">시내버스</button>
                                    </li>
                                    <li>
                                        <button onClick={(e)=>{
                                            e.preventDefault();
                                            document.moneyPostForm.type.value = "식비";
                                            document.moneyPostForm.moneyTitle.value = "과자";
                                            document.moneyPostForm.moneyValue.value = "4800"
                                            fn_moneyPostForm();
                                        }} className="btn btn-primary">과자</button>
                                    </li>
                                </ul>
                                <table className="table">
                                    <colgroup>
                                        <col style={{width:'25%'}}/>
                                        <col style={{width:'75%'}}/>
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>타입</th>
                                            <td>
                                                <select name="type" value={info.type} onChange={(e)=>{
                                                    const tmp = {...info, moneyType : e.target.value}
                                                    setInfo(tmp);
                                                }}>
                                                    <option value="교통비">교통비</option>
                                                    <option value="식비">식비</option>
                                                    <option value="쇼핑">쇼핑</option>
                                                    <option value="기타">기타</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>항목</th>
                                            <td>
                                                <input type="text" name="moneyTitle" defaultValue={info.moneyTitle}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>금액</th>
                                            <td>
                                                <input type="number" name="moneyValue" defaultValue={info.moneyValue}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="btn-area text-center">
                                    <button onClick={(e)=>{e.preventDefault(); fn_moneyPostForm();}} className="btn btn-primary">확인</button>
                                    <button onClick={(e)=>{e.preventDefault(); fn_close("#money-dlg");}} className="btn btn-danger">닫기</button>
                                </div>
                            </form>
                        </dialog>


                        <dialog id="diary-dlg">
                            <form name="diaryPostForm" onSubmit={(e)=>{e.preventDefault()}}>
                                <input type="hidden" name="diaryId" defaultValue={diaryInfo.diaryId}/>
                                <input type="hidden" name="diaryDate" defaultValue={params.date}/>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <textarea name="content" defaultValue={diaryInfo.content}></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="btn-area">
                                    <button className="btn btn-primary" onClick={(e)=>{
                                        e.preventDefault();
                                        fn_diaryPostForm();
                                    }}>확인</button>
                                    <button className="btn btn-danger" onClick={(e)=>{
                                        e.preventDefault();
                                        fn_close('#diary-dlg');
                                    }}>닫기</button>
                                </div>
                            </form>
                        </dialog>
                    </>
                )
            }
        </>
    );
}