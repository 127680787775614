// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import loginInfoReducer from '../features/loginInfo/loginInfoSlice';

const store = configureStore({
  reducer: {
    loginInfo : loginInfoReducer
  },
});

export default store;