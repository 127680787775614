
export const api_commonFetch = async (url, config)=>{
    const refreshToken = sessionStorage.getItem('refreshToken') || '';

    let loginFlag = 'N'

    //  1. config에 인증정보가 있는지 조회
    if (config.headers) {
        // Headers 객체일 때 처리
        if (config.headers instanceof Headers) {
            config.headers.forEach((value, key) => {
                if(key == 'Authorization'){
                    loginFlag = 'Y'
                }
            });
        } else {
            Object.entries(config.headers).forEach(([key, value]) => {
                if(key == 'Authorization'){
                    loginFlag = 'Y'
                }
            });
        }
    }

    //  2. API 요청
    let res = await fetch(url, config);
    let data = await res.json();
    

    //  3. config에 인증정보가 있고 API 요청에서 권한없음이 떴을 경우
    if(refreshToken != '' && loginFlag == 'Y' && data.result == 'N'){
        res = await fetch(process.env.REACT_APP_API_URL + '/crizelApi/member/reAuth', {
                method : 'post'
                , cache  : 'no-cache'
                , headers : {
                    Authorization : "Bearer " + refreshToken
                }
            });
        data = await res.json();
    
        if(data.result == 'R'){     //  엑세스토큰 재발급
            sessionStorage.setItem('accessToken', data.accessToken);
        }else if(data.result == 'F'){       //  리프레시토큰 만료 시 로그인페이지로 이동
            window.location.replace('/login?returnUrl=' + encodeURIComponent(window.location.href));
        }

        //  엑세스토큰 재발급 시 config의 인증값 변경 후 다시 시도
        if (config.headers) {
            (config.headers)['Authorization'] = 'Bearer ' + data.accessToken;
        }

        res = await fetch(url, config);
        data = await res.json(); 
    }else{
        res = await fetch(url, config);
        data = await res.json();
    }


    return data;
}