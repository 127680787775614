import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

export default function App(props){
    var iX, iY;
    var bDraw = false;

    const [img, setImg] = useState("");

    const canvasRef = useRef(null);
    const [context, setContext] = useState(null);
    const [bb, setBb] = useState(null);    

    const fn_drawStart = (e)=>{
        iX = e.clientX - bb.left;
        iY = e.clientY - bb.top;
        bDraw = true;

        console.log(bDraw);
    }

    const fn_drawEnd = (e)=>{
        if( bDraw ){
            var iNewX = e.clientX - bb.left;
            var iNewY = e.clientY - bb.top;
        
            context.beginPath();
            context.moveTo( iX, iY );
            context.lineTo( iNewX, iNewY );
            context.closePath();
            context.stroke();
        
            iX = iNewX;
            iY = iNewY;
        }
    }

    const fn_drawClose = (e)=>{
        bDraw = false;
    }

    const fn_drawSave = (e)=>{
        var canvas = canvasRef.current;
        var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // MIME 타입 지정
        setImg(canvas.toDataURL("image/png"));

        const index = document.querySelectorAll(".img-wrap li").length + 1

        let html = `
            <li onclick="this.remove()">
                <img src="${canvas.toDataURL("image/png")}" style="display:inline-block; width:500px; height:500px; background:white"/>
                <input type="hidden" name="imgArr" value="${canvas.toDataURL("image/png")} "/>                                
            </li>
        `;

        document.querySelector(".img-wrap").insertAdjacentHTML('beforeend', html);
    }

    useEffect(()=>{
        const canvas = canvasRef.current;
        if(canvas!=null){
            const context = canvas.getContext("2d")
            setContext(context);
            const bb = canvas.getBoundingClientRect();
            setBb(bb);
            context.strokeStyle = "black"
            context.lineJoin = "round";
            context.lineWidth = 2;
        }

    }, []);

    return (
        <>
            <canvas ref={canvasRef} id="img-main" style={{display:"inline-block", width:"500px",  height:"500px", border:'1px solid red', backgroundColor:'white'}}
                onMouseDown={(e)=>{fn_drawStart(e)}} onTouchStart={(e)=>{fn_drawStart(e)}}
                onMouseMove={(e)=>{fn_drawEnd(e)}} onTouchMove={(e)=>{fn_drawEnd(e);}}
                onMouseUp={(e)=>{fn_drawClose(e)}} onTouchEnd={(e)=>{fn_drawClose(e)}}
            />
            <button onClick={(e)=>{e.preventDefault(); 
                // 픽셀 정리
                const canvas = canvasRef.current
                context.clearRect(0, 0, canvas.width, canvas.height);
                // 컨텍스트 리셋
                context.beginPath();}} className="btn btn-danger">초기화</button>
            <button id="save" onClick={(e)=>{e.preventDefault(); fn_drawSave(e);}} className="btn btn-primary">저장</button> 
        </>
    );
}