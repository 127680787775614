import { api_getLoginInfo } from 'api/loginInfo';
import { useLoginInfo } from 'hook/loginInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

export default function App(props){
    const navigate = useNavigate();
    
    window.document.title = "CRIZEL";

    const loginInfo = useLoginInfo();
    /*
    const [loginInfo, setLoginInfo] = useState({
        memberId : ''
        , memberName : ''
        , roleList : ''
    });    
    const fn_getLoginInfo = async()=>{
        const data = await api_getLoginInfo();

        if(data!=null){
            const tmp = {
                ...loginInfo
                , memberId : data.memberId
                , memberName : data.memberName
                , roleList : data.roleList
            }

            setLoginInfo(tmp);
        }
        
    }
    useEffect(()=>{
        fn_getLoginInfo();
    }, []);
    */

    const fn_logout = ()=>{
        sessionStorage.setItem('accessToken', '');
        sessionStorage.setItem('refreshToken', '');
        sessionStorage.setItem('memberName', '');
        window.location.href='/';
    }  

    const fn_login = ()=>{
        navigate("/login?returnUrl=" + window.location.href);
    }

    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        setDeferredPrompt(event);
    };

    const handleInstallClick = () => {
        if (deferredPrompt) {
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
            console.log("사용자가 설치 프롬프트에 동의했습니다.");
            } else {
            console.log("사용자가 설치 프롬프트를 무시했습니다.");
            }

            setDeferredPrompt(null);
        });
        }
    };

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        return () => {
        window.removeEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
        );
        };
    }, []);

    return (
        <>
            <header className="naver header">
                <ul className="menu depth1">
                    <li>
                        <Link to="/">홈</Link>
                    </li>
                    <li>
                        <Link to="/studyList">공부</Link>
                    </li>
                    <li>
                        <Link to="/history">커리어</Link>
                    </li>
                    <li>
                        <Link to="/rt">식당</Link>
                    </li>
                    {
                        loginInfo.memberId != null && loginInfo.memberId != '' ? (
                            <>
                                <li>
                                    <Link to="/drawing/list">그림</Link>
                                </li>
                                <li>
                                    <Link to="/movie">영화</Link>
                                </li>
                                {
                                    loginInfo.memberId == 'rhzhzh3' ? (
                                        <li>
                                            <Link to="/crizel/">CRIZEL</Link>
                                        </li>
                                    ) : ''
                                }
                            </>
                        ) : ''
                    }
                        {
                            loginInfo.memberId != null && loginInfo.memberId != '' ? (
                                <li>
                                    <button onClick={(e)=>{e.preventDefault(); fn_logout();}}>로그아웃</button>
                                </li>
                            ) : (
                                <>
                                    <li>
                                        <button onClick={(e)=>{e.preventDefault(); fn_login();}} >로그인</button>
                                    </li>
                                    <li>
                                        <Link to={"/register"}>회원가입</Link>
                                    </li>
                                </>
                            )
                        }
                    <li>
                        <button onClick={(e)=>{
                            e.preventDefault();
                            handleInstallClick();
                        }}>앱 설치</button>
                    </li>
                </ul>
            </header>
        </>        
    );
}