import { apiServer, wsServer} from 'component/info/serverInfo';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import { Map, MapMarker, MapTypeControl, ZoomControl, useKakaoLoader, MapInfoWindow} from "react-kakao-maps-sdk";
import React, { useState, useEffect } from 'react';


export default function App(){

    const [message, setMessage] = useState('');


    useEffect(()=>{
    const ws = new WebSocket(wsServer);
    ws.onopen = () => {
      console.log('웹소켓 연결됨');
    };
    ws.onmessage = (event) => {
      setMessage(event.data); // 서버로부터 온 메시지 업데이트
    };
    ws.onclose = () => {
      console.log('웹소켓 연결 종료됨');
    };
    return () => {
      ws.close();
    };
        
    }, []);

    return (
        <>
          QR 메인
        </>        
    );
}

