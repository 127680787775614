import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link, useFetcher } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';


import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import { fn_getRdvCategoryList, fn_getRdvList, fn_deleteAction, fn_postForm, fn_getBookmarkList, fn_bookmarkDeleteAction, fn_bookmarkPostForm
        , fn_av19Form, fn_av19DeleteAction, fn_getAv19List } from 'api/crizel/rdv';
import { useLoginInfo } from 'hook/loginInfo';

export default function App(props){
    const navigate = useNavigate();

    const loginInfo = useLoginInfo();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const videoRef = useRef(null);


    const [list, setList] = useState(null);
    const [category1List, setCategory1List] = useState(null);
    const [category2List, setCategory2List] = useState(null);
    const [av19List, setAv19List] = useState(null);
    const [video, setVideo] = useState('');
    const [info, setInfo] = useState({
        rdvNo : ''
        , rdvName : ''
        , rdvPath : ''
        , registerDate : ''
        , category1 : ''
        , category2 : ''
        , memo : ''
        , av19 : ''
    });

    const [bookmarkList, setBookmarkList] = useState(null);
    const [bookmarkInfo, setBookmarkInfo]  = useState({
        bNo : ''
        , keyword : ''
        , sortOrd : '0'
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const [memberId, setMemberId] = useState(sessionStorage.getItem('memberId') || '');

    const [params, setParams] = useState({
        pageNo : searchParams.get('pageNo') || 1
        , countList : searchParams.get('countList') || 30
        , order : searchParams.get('order') || 'recent'
        , searchKeyword : searchParams.get('searchKeyword') || ''
        , index : searchParams.get('index') || 0
        , category1 : searchParams.get("category1") || ''
        , category2 : searchParams.get("category2") || ''
    });

    

    const fn_update = (info)=>{
        setInfo({
            rdvNo : info.rdvNo
            , rdvName : info.rdvName
            , rdvPath : info.rdvPath
            , registerDate : info.registerdate
            , category1 : info.category1
            , category2 : info.category2
            , memo : info.memo
        })
        document.querySelector("#update-wrap").setAttribute("open", "open");
    }

    const fn_dlgClose = ()=>{
        document.querySelector("#update-wrap").removeAttribute("open");
    }

    

    const fn_prev = ()=>{
        let index = params.index-1<=0?0:params.index-1;
        const info = list[index];
        setVideo("https://crizel.duckdns.org" + info.RDV_PATH);
        const tmp = {...params, index : index}
        setParams(tmp);
        fn_play();
    }

    const fn_next = ()=>{
        let index = params.index+1>list.length?list.length:params.index+1;
        const info = list[index];
        setVideo("https://crizel.duckdns.org" + info.RDV_PATH);
        const tmp = {...params, index : index}
        setParams(tmp);
        fn_play();
    }

    const fn_play = ()=>{
        videoRef.current.play();
    }


    const fn_bookmarkInsert = ()=>{
        setBookmarkInfo({
            bNo : ''
            , keyword : ''
            , sortOrd : 0
        });
        document.querySelector("#bookmark-update-dlg").setAttribute("open", "open");
    }
    const fn_bookmarkUpdate = (info)=>{
        setBookmarkInfo({
            bNo : info.B_NO
            , keyword : info.KEYWORD
            , sortOrd : info.SORT_ORD
        });
        document.querySelector("#bookmark-update-dlg").setAttribute("open", "open");
    }

    const fn_bookmarkInsertAction = async (info)=>{

        await fn_authCheck()

    }

    const fn_bookmarkUpdateAction = async (info)=>{

        await fn_authCheck()

    }

    const fn_bmDlgClose = ()=>{
        document.querySelector("#bookmark-update-dlg").removeAttribute("open");
    }

    const fn_fullScreen = ()=>{
        if (videoRef.current) {
            if (videoRef.current.requestFullscreen) {
              videoRef.current.requestFullscreen();
            } else if (videoRef.current.mozRequestFullScreen) { // Firefox
              videoRef.current.mozRequestFullScreen();
            } else if (videoRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
              videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) { // IE/Edge
              videoRef.current.msRequestFullscreen();
            }
          }
        videoRef.current.play();
    }

    

    useEffect(()=>{
        fn_getRdvCategoryList(setCategory1List, setCategory2List);
        fn_getRdvList(params, setLoading, setList, setVideo);
        fn_getBookmarkList(setBookmarkList);
        fn_getAv19List(setAv19List);

        const video = document.querySelector("#rdvVideo");

        if(video!=null){
            video.addEventListener("timeupdate", function () {
                if(video.currentTime == video.duration){
                    fn_next();
                }
            });
        }
        
    }, [params]);

    if(loginInfo.memberId != 'rhzhzh3') return (<div><Link to={"/login?returnUrl=" + encodeURIComponent("/crizel/rdv/list")}>로그인 후 이용하여주시기 바랍니다.</Link></div>)
    else if(loading) return <Loading/>
    return (
        <>
            <div>
                <video id="rdvVideo" src={video} ref={videoRef} autoPlay controls preload="auto">
                    <source src={video} type="video/mp4"/>
                    <source src={video} type="video/ogg"/>
                    <source src={video} type="video/webm"/>
                    <source src={video} type="video/avi"/>
                    <source src={video} type="video/mp3"/>
                    <source src={video} type="video/mkv"/>
                    <source src={video} type="video/mov"/>
                    <source src={video} type="video/wmv"/>
                    <source src={video} type="video/vob"/>    
                </video>                                        
                <ul className="tab-menu">
                    <li>
                        <button onClick={(e)=>{
                            e.preventDefault();
                            fn_prev();
                        }} className="btn btn-success">이전</button>
                    </li>
                    <li>
                        <button onClick={(e)=>{
                            e.preventDefault();
                            fn_fullScreen();
                        }} className="btn btn-primary">전체화면</button>
                    </li>
                    <li>
                        <button onClick={(e)=>{
                            e.preventDefault();
                            fn_next();
                        }} className="btn btn-success">다음</button>
                    </li>
                </ul>
            </div>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <div>
                    <label htmlFor="order1">
                        <input type="radio" name="order" id="order1" value="recent" defaultChecked={params.order == 'recent'} onChange={(e)=>{
                            const tmp = {...params, order : "recent"}
                            setParams(tmp);
                            navigate('/crizel/rdv/list?' + new URLSearchParams(new FormData(document.searchForm)) );
                        }}/> 최신
                    </label>
                    <label htmlFor="order2">
                        <input type="radio" name="order" id="order2" value="old" defaultChecked={params.order == 'old'} onChange={(e)=>{
                            const tmp = {...params, order : "old"}
                            setParams(tmp);
                            navigate('/crizel/rdv/list?' + new URLSearchParams(new FormData(document.searchForm)) );
                        }}/> 옛날
                    </label>
                    <label htmlFor="order3">
                        <input type="radio" name="order" id="order3" value="random" defaultChecked={params.order == 'random'} onChange={(e)=>{
                            const tmp = {...params, order : "random"}
                            setParams(tmp);
                            navigate('/crizel/rdv/list?' + new URLSearchParams(new FormData(document.searchForm)) );
                        }}/> 랜덤
                    </label>
                </div>
                <select defaultValue={params.category1} onChange={(e)=>{    
                    const tmp = {...params, category1 : e.target.value}
                    setParams(tmp);
                    navigate('/crizel/rdv/list?' + new URLSearchParams(new FormData(document.searchForm)) );
                }}>
                    <option value="">카테고리1</option>
                    {
                        category1List!=null && category1List.length>0?(
                            category1List.map((ob, i)=>{
                                return (
                                    <option key={i} value={ob}>{ob}</option>
                                );
                            })
                        ):''
                    }
                </select>
                <select defaultValue={params.category2} onChange={(e)=>{    
                    const tmp = {...params, category2 : e.target.value}
                    setParams(tmp);
                    navigate('/crizel/rdv/list?' + new URLSearchParams(new FormData(document.searchForm)) );
                }}>
                    <option value="">카테고리2</option>
                    {
                        category2List!=null && category2List.length>0?(
                            category2List.map((ob, i)=>{
                                return (
                                    <option key={i} value={ob}>{ob}</option>
                                );
                            })
                        ):''
                    }
                </select>
                <select defaultValue={params.searchKeyword} onChange={(e)=>{
                    const tmp = {...params, searchKeyword : e.target.value};
                    setParams(tmp);
                    navigate('/crizel/rdv/list?' + new URLSearchParams(new FormData(document.searchForm)) );
                }}>
                    <option value="">즐겨찾기</option>
                    {
                        bookmarkList!=null && bookmarkList.length>0?(
                            bookmarkList.map((ob, i)=>{
                                return (
                                    <option key={i} value={ob.KEYWORD}>{ob.KEYWORD}</option>
                                );
                            })
                        ):''
                    }
                </select>
                <select  onChange={(e)=>{
                    if(e.target.value != ''){
                        window.open(e.target.value);
                    }
                }}>
                    <option value="">AV19 즐겨찾기</option>
                    {
                        av19List!=null && av19List.length>0?(
                            av19List.map((ob, i)=>{
                                return (
                                    <option key={i} value={ob.URL}>{ob.TITLE}</option>
                                );
                            })
                        ):''
                    }
                </select>
                <input type="text" name="searchKeyword" defaultValue={params.searchKeyword} onKeyDown={(e)=>{                                                        
                    if(e.key == 'Enter'){
                        const tmp = {...params, searchKeyword : e.target.value}
                        setParams(tmp);
                        navigate('/crizel/rdv/list?' + new URLSearchParams(new FormData(document.searchForm)) );
                    }
                }} placeholder='검색어 입력'/>



                <div>
                    <button onClick={(e)=>{
                        if(document.querySelector("#bookmarkList").classList.contains("display-n") == true){
                            document.querySelector("#bookmarkList").classList.remove("display-n");
                        }else{
                            document.querySelector("#bookmarkList").classList.add("display-n");
                        }
                    }} className="btn btn-primary">즐겨찾기 관리</button>    
                </div>   
                
                <table id="bookmarkList" className="table display-n">
                    <colgroup>
                        <col style={{width:'60%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>키워드</th>
                            <th>순서</th>
                            <th>수정/삭제</th>
                        </tr>                                    
                    </thead>                                
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <button onClick={(e)=>{
                                    e.preventDefault();
                                    fn_bookmarkInsert();
                                }} className="btn btn-primary">등록</button>
                            </td>
                        </tr>
                        {
                            bookmarkList!=null && bookmarkList.length>0?(
                                bookmarkList.map((ob, i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>{ob.KEYWORD}</td>
                                            <td>{ob.SORT_ORD}</td>
                                            <td>
                                                <button className="btn btn-success" onClick={(e)=>{
                                                    e.preventDefault();
                                                    fn_bookmarkUpdate(ob);
                                                }}>수정</button>
                                                <button onClick={(e)=>{
                                                    fn_bookmarkDeleteAction(ob, setBookmarkList);
                                                }} className="btn btn-danger">삭제</button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ):''
                        }
                    </tbody>
                </table>
            </form>
            <button className="btn btn-primary" onClick={(e)=>{
                e.preventDefault();
                document.querySelector("#av19Mng").setAttribute("open", 'open');
            }}>AV19 관리</button>
            <Av19 av19List={av19List} setAv19List={setAv19List} fn_getAv19List={fn_getAv19List}/>
            <table className="table">
                <colgroup>
                    <col style={{width:'15%'}}/>
                    <col style={{width:'15%'}}/>
                    <col style={{width:'15%'}}/>
                    <col style={{width:'30%'}}/>
                    <col style={{width:'20%'}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>날짜</th>
                        <th>카테고리1</th>
                        <th>카테고리2</th>
                        <th>제목</th>
                        <th>수정/삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length>0?(
                            list.map((ob, i)=>{
                                return (
                                    <tr key={i} className={Number(params.index) == i ? 'active' : ''}>
                                        <td>
                                            <span className="mob-title">날짜</span>
                                            {ob.registerDate}
                                        </td>
                                        <td>
                                            <span className="mob-title">카테고리1</span>
                                            {ob.category1}
                                        </td>
                                        <td>
                                            <span className="mob-title">카테고리2</span>
                                            {ob.category2}
                                        </td>
                                        <td className="text-left">
                                            <span className="mob-title">제목</span>
                                            <button onClick={(e)=>{
                                                e.preventDefault();
                                                setVideo("https://crizel.duckdns.org/" + ob.rdvPath);
                                                const tmp = {...params, index : i}
                                                setParams(tmp);
                                            }}>{ob.rdvName}</button>
                                        </td>
                                        <td>
                                            <button onClick={(e)=>{
                                                e.preventDefault();
                                                fn_fullScreen();
                                            }} className="btn btn-primary">전체화면</button>
                                            <button className="btn btn-success" onClick={(e)=>{
                                                e.preventDefault();
                                                fn_update(ob);
                                            }}>수정</button>
                                            <button className="btn btn-danger" onClick={(e)=>{
                                                e.preventDefault();
                                                fn_deleteAction(ob, params, setVideo, fn_getRdvList, setLoading, setList);
                                            }}>삭제</button>
                                        </td>
                                    </tr>
                                );
                            })
                        ):  (
                            <tr>
                                <td colSpan={5}>데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            
            <dialog id="update-wrap">
                <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                    <input type="hidden" name="rdvNo" defaultValue={info.rdvNo}/>
                    <table className="table">
                        <colgroup>
                            <col style={{width:'25%'}}/>
                            <col style={{width:'75%'}}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>카테고리1</th>
                                <th>
                                    <select defaultValue={info.category1} onChange={(e)=>{
                                        e.preventDefault();
                                        const tmp = {...info, category1 : e.target.value}
                                        setInfo(tmp);
                                    }}>
                                        <option value="">카테고리1</option>
                                        {
                                            category1List!=null && category1List.length>0?(
                                                category1List.map((ob, i)=>{
                                                    return (
                                                        <option key={i} value={ob}>{ob}</option>                                                                    
                                                    );
                                                })
                                            ):''
                                        }
                                    </select>
                                    <input type="text" name="category1" defaultValue={info.category1}/>
                                </th>                                            
                            </tr>
                            <tr>
                                <th>카테고리2</th>
                                <th>
                                    <select defaultValue={info.category2} onChange={(e)=>{
                                        e.preventDefault();
                                        const tmp = {...info, category2 : e.target.value}
                                        setInfo(tmp);
                                    }}>
                                        <option value="">카테고리2</option>
                                        {
                                            category2List!=null && category2List.length>0?(
                                                category2List.map((ob, i)=>{
                                                    return (
                                                        <option key={i} value={ob}>{ob}</option>                                                                    
                                                    );
                                                })
                                            ):''
                                        }
                                    </select>
                                    <input type="text" name="category2" defaultValue={info.category2}/>
                                </th>                                            
                            </tr>
                            <tr>
                                <th>파일명</th>
                                <th>
                                    <input type="text" name="rdvPath" defaultValue={info.rdvPath} onChange={(e)=>{
                                        let name = e.target.value.substring(e.target.value.lastIndexOf('/')+1, e.target.value.length)
                                        console.log(name);
                                        const tmp = {...info, rdvPath : e.target.value, rdvName : name}
                                        setInfo(tmp);
                                    }}/>
                                    <input type="text" name="rdvName" defaultValue={info.rdvName} onChange={(e)=>{
                                        let path = info.rdvPath.substring(0, info.rdvPath.lastIndexOf("/")+1) + e.target.value
                                        const tmp = {...info, rdvPath : path, rdvName : e.target.value}
                                        setInfo(tmp);
                                    }}/>
                                </th>                                            
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <textarea name="memo" defaultValue={info.memo}></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="btn-area text-center">
                        <button onClick={(e)=>{e.preventDefault(); fn_postForm(document.postForm, params, setLoading, setList, setVideo, fn_dlgClose, setBookmarkList);}} className="btn btn-primary">확인</button>
                        <button onClick={(e)=>{e.preventDefault(); fn_dlgClose();}} className="btn btn-danger">닫기</button>
                    </div>
                </form>
            </dialog>

            <dialog id="bookmark-update-dlg">
                <form name="bookmarkPostForm" onSubmit={(e)=>{e.preventDefault();}}>
                    <input type="hidden" name="bNo" defaultValue={bookmarkInfo.bNo}/>
                    <table className="table">
                        <colgroup>
                            <col style={{width:'25%'}}/>
                            <col style={{width:'75%'}}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>키워드</th>
                                <td>
                                    <input type="text" name="keyword" defaultValue={bookmarkInfo.keyword}/>
                                </td>
                            </tr>
                            <tr>
                                <th>순서</th>
                                <td>
                                    <input type="number" name="sortOrd" defaultValue={bookmarkInfo.sortOrd}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="btn-area">
                        <button onClick={(e)=>{e.preventDefault(); fn_bookmarkPostForm(document.bookmarkPostForm, setBookmarkList, fn_bmDlgClose);}} className="btn btn-primary">확인</button>
                        <button onClick={(e)=>{e.preventDefault(); fn_bmDlgClose();}} className="btn btn-danger">닫기</button>
                    </div>
                </form>
            </dialog>
        </>
    );
}


function Av19(props){

    const av19List = props.av19List;
    const setAv19List = props.setAv19List;
    const fn_getAv19List = props.fn_getAv19List;

    const [av19Info, setAv19Info] = useState({
        no : ''
        , title : ''
        , url : ''
        , bookmarkFlag : ''
    });
    
    return (
        <dialog id="av19Mng">
            <form name="av19Form" onSubmit={(e)=>{e.preventDefault();}}>    
                <input type="hidden" name="no" defaultValue={av19Info.no}/>
                <div className="item-list">
                    <table className="table list">
                        <tbody>
                            {
                                av19List!=null && av19List.length>0 ? (
                                    av19List.map((ob, i)=>{
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <span className="mob-title">번호</span>
                                                    {ob.NO}
                                                </td>
                                                <td>
                                                    <span className="mob-title">제목</span>
                                                    {ob.TITLE}
                                                </td>
                                                <td>
                                                    <span className="mob-title">URL</span>
                                                    {ob.URL}
                                                </td>
                                                <td>
                                                    <span className="mob-title">북마크</span>
                                                    {ob.BOOKMARK_FLAG == 'Y' ? '즐겨찾기' : '일반'}
                                                </td>
                                                <td>
                                                    <button onClick={(e)=>{
                                                        e.preventDefault();
                                                        const tmp = {...av19Info
                                                            , no : ob.NO
                                                            , title : ob.TITLE
                                                            , url : ob.URL
                                                            , bookmarkFlag : ob.BOOKMARK_FLAG
                                                        }   
                                                        console.log(tmp);
                                                        setAv19Info(tmp);
                                                    }} className="btn btn-success">수정</button>
                                                    <button onClick={(e)=>{
                                                        e.preventDefault();
                                                        fn_av19DeleteAction(ob.NO, fn_getAv19List);
                                                    }} className="btn btn-danger">삭제</button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : ''
                            }
                        </tbody>
                    </table>
                </div>
                <div className="item-wrap">
                    <div className="item">
                        <label htmlFor="title">제목</label>
                        <div>
                            <input type="text" name="title" id="title" value={av19Info.title} onChange={(e)=>{
                                const tmp = {...av19Info, title : e.target.value}
                                setAv19Info(tmp);
                            }}/>
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="url">URL</label>
                        <div>
                            <input type="text" name="url" id="url" value={av19Info.url} onChange={(e)=>{
                                const tmp = {...av19Info, url : e.target.value}
                                setAv19Info(tmp);
                            }}/>
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="bookmarkFlag">즐겨찾기</label>
                        <div>
                            <select name="bookmarkFlag" id="bookmarkFlag" value={av19Info.bookmarkFlag} onChange={(e)=>{
                                const tmp = {...av19Info, bookmarkFlag : e.target.value}
                                setAv19Info(tmp);
                            }}>
                                <option value="N">일반</option>
                                <option value="Y">즐겨찾기</option>
                            </select>
                        </div>
                    </div>                    
                </div>
                <div className="btn-area">
                    <button onClick={(e)=>{
                        e.preventDefault();
                        fn_av19Form(document.av19Form, fn_getAv19List);
                    }} className="btn btn-primary">확인</button>
                    <button onClick={(e)=>{
                        e.preventDefault();
                        const form = document.av19Form;
                        form.no.value = "";
                        form.title.value = "";
                        form.url.value = ""
                        form.bookmarkFlag.value = 'N'
                    }} className="btn btn-success">신규등록</button>
                    <button onClick={(e)=>{
                        e.preventDefault();
                        document.querySelector("#av19Mng").removeAttribute("open");
                    }} className="btn btn-danger">닫기</button>
                </div>
            </form>
        </dialog>
    )
}