import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import { fn_deleteAction, fn_getApiAniList, fn_updateAction } from 'api/crizel/ani';

export default function App(props){
    const navigate = useNavigate();

    const loginInfo = useSelector(getLoginInfo);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState(null);
    
    const [info, setInfo] = useState({
        aniNo : ''
        , title : ''
        , aniTime : ''
        , day : ''
        , keyword : ''
        , site : ''
        , deleteYn : ''
        , img : ''
        , year : ''
        , quarter : ''
    });

    const [searchParams, setSearchParams] = useSearchParams();

    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate()-1);
    const dayWeekArr = ['일', '월', '화', '수', '목', '금', '토'];

    const [params, setParams] = useState({
        day : searchParams.get("day") || dayWeekArr[date.getDay()]
    });

    const fn_getAniList = async (params)=>{        
        setLoading(true);
        const data = await fn_getApiAniList(params);
        setList(data.list);
        setLoading(false);
    }
    

    const fn_upate = (info)=>{
        setInfo({
            aniNo : info.aniNo
            , title : info.title
            , aniTime : info.aniTime
            , day : info.day
            , keyword : info.keyword
            , site : info.site
            , deleteYn : info.deleteYn
            , img : info.img
            , year : info.year
            , quarter : info.quarter
        });

        document.querySelector("#update-wrap").setAttribute("open", "open");
        
    }   

    const fn_close =()=>{
        document.querySelector("#update-wrap").removeAttribute("open");
    }

     

    

    useEffect(()=>{
        const accessToken = sessionStorage.getItem("accessToken");
        const memberName = sessionStorage.getItem("memberName");
        if(accessToken!=null && accessToken!=''){
            fn_getAniList(params);
        }else{
            alert('로그인 후 이용하여주시기 바랍니다.');
            navigate('/login?returnUrl=' + encodeURIComponent(window.location.href));
        }


        window.addEventListener('keydown', (e)=>{
            if(e.key == 'Escape'){
                fn_close();
            }
        })

    }, []);

    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <div className="btn-area">
                    <button onClick={(e)=>{
                        e.preventDefault();
                        if(list!=null && list.length>0){
                            list.forEach((ob, i)=>{
                                window.open("https://nyaa.si/?f=0&c=1_4&q=" + ob.keyword);
                            })
                        }
                    }} className="btn btn-primary">전체링크</button>
                </div>
                <ul className="tab-menu">
                    {
                        dayWeekArr.map((ob, i)=>{
                            return (
                                <li key={i}>
                                    <button onClick={(e)=>{e.preventDefault();
                                        const tmpParams = {
                                            ...params
                                            , day : ob
                                        };
                                        setParams(tmpParams);
                                        fn_getAniList(tmpParams);
                                        navigate("/crizel/ani?day=" + ob);
                                    }} className={params.day == ob ? 'active' : ''}>{ob}</button>
                                </li>
                            );
                        })
                    }
                </ul>
            </form>
        {
            loading == true ? (
                <Loading/>
            ) : (
                <>
                    <table className="table list">
                        <thead>
                            <tr>
                                <th>연도</th>
                                <th>분기</th>
                                <th>요일</th>
                                <th>시간</th>
                                <th>제목</th>
                                <th>키워드</th>
                                <th>이미지</th>
                                <th>수정/삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            list!=null && list.length>0 ? (
                                list.map((ob, i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <span className={"str_" + ob.aniNo}>{ob.year}</span>
                                                <input type="number" name="year" className={"display-n val_" + ob.aniNo} defaultValue={ob.year}/>
                                            </td>
                                            <td>
                                                <span className={"str_" + ob.aniNo}>{ob.quarter}</span>
                                                <select name="quarter" className={"display-n val_" + ob.aniNo} defaultValue={ob.quarter}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </td>
                                            <td>
                                                <span className={"str_" + ob.aniNo}>{ob.day}</span>
                                                <select name="day" className={"display-n val_" + ob.aniNo} defaultValue={ob.day}>
                                                    <option value="일">일</option>
                                                    <option value="월">월</option>
                                                    <option value="화">화</option>
                                                    <option value="수">수</option>
                                                    <option value="목">목</option>
                                                    <option value="금">금</option>
                                                    <option value="토">토</option>
                                                </select>
                                            </td>
                                            <td>
                                                {ob.aniTime}
                                            </td>
                                            <td>
                                                <span className={"str_" + ob.aniNo}><a href={"https://nyaa.si/?f=0&c=1_4&q=" + ob.keyword} target="_blank">{ob.title}</a></span>   
                                                <input type="text" name="title" className={"display-n val_" + ob.aniNo} defaultValue={ob.title}/>                                            
                                            </td>
                                            <td>
                                                <span className={"str_" + ob.aniNo}><a href={"https://nyaa.si/?f=0&c=1_4&q=" + ob.keyword} target="_blank">{ob.keyword}</a></span>   
                                                <input type="text" name="keyword" className={"display-n val_" + ob.aniNo} defaultValue={ob.keyword}/>                                                     
                                            </td>
                                            <td>
                                                <span className={"str_" + ob.aniNo}><a href={ob.site} target="_blank"><img src={ob.img}/></a></span>
                                            </td>
                                            <td>
                                                <button onClick={(e)=>{e.preventDefault(); fn_upate(ob);}} className="btn btn-success">수정</button>
                                                <button onClick={(e)=>{e.preventDefault(); fn_deleteAction(ob.aniNo, params, fn_getAniList)}} className="btn btn-danger">삭제</button>
                                            </td>
                                        </tr>
                                    );
                                }) 
                            ) : (
                                <tr>
                                    <td>데이터가 없습니다.</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                    <dialog id="update-wrap">
                        <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                            <input type="hidden" name="aniNo" defaultValue={info.aniNo}/>
                            <table className="table">
                                <colgroup>
                                    <col style={{width:'20%'}}/>
                                    <col style={{width:'30%'}}/>
                                    <col style={{width:'20%'}}/>
                                    <col style={{width:'30%'}}/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>연도</th>
                                        <td>
                                            <input type="number" name="year" defaultValue={info.year} onChange={(e)=>{
                                                const tmp = {...info, year : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                        </td>
                                        <th>분기</th>
                                        <td>
                                            <label>
                                                <input type="radio" name="quarter" value="1" checked={info.quarter == '1'} onChange={(e)=>{
                                                const tmp = {...info, quarter : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                                1분기
                                            </label>
                                            <label>
                                                <input type="radio" name="quarter" value="2" checked={info.quarter == '2'} onChange={(e)=>{
                                                const tmp = {...info, quarter : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                                2분기
                                            </label>
                                            <label>
                                                <input type="radio" name="quarter" value="3" checked={info.quarter == '3'} onChange={(e)=>{
                                                const tmp = {...info, quarter : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                                3분기
                                            </label>
                                            <label>
                                                <input type="radio" name="quarter" value="4" checked={info.quarter == '4'} onChange={(e)=>{
                                                const tmp = {...info, quarter : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                                4분기
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>요일</th>
                                        <td>
                                            <label>
                                                <input type="radio" name="day" value="일" checked={info.day == '일'} onChange={(e)=>{
                                                    const tmp = {...info, day : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                                일
                                            </label>
                                            <label>
                                                <input type="radio" name="day" value="월" checked={info.day == '월'} onChange={(e)=>{
                                                    const tmp = {...info, day : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                                월
                                            </label>
                                            <label>
                                                <input type="radio" name="day" value="화" checked={info.day == '화'} onChange={(e)=>{
                                                    const tmp = {...info, day : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                                화
                                            </label>
                                            <label>
                                                <input type="radio" name="day" value="수" checked={info.day == '수'}onChange={(e)=>{
                                                    const tmp = {...info, day : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                                수
                                            </label>
                                            <label>
                                                <input type="radio" name="day" value="목" checked={info.day == '목'}onChange={(e)=>{
                                                    const tmp = {...info, day : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                                목
                                            </label>
                                            <label>
                                                <input type="radio" name="day" value="금" checked={info.day == '금'}onChange={(e)=>{
                                                    const tmp = {...info, day : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                                금
                                            </label>
                                            <label>
                                                <input type="radio" name="day" value="토" checked={info.day == '토'}onChange={(e)=>{
                                                    const tmp = {...info, day : e.target.value}
                                                    setInfo(tmp);
                                                }}/>
                                                토
                                            </label>
                                        </td>
                                        <th>시간</th>
                                        <td>
                                            <input type="text" name="aniTime" defaultValue={info.aniTime} onChange={(e)=>{
                                                const tmp = {...info, aniTime : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>제목</th>
                                        <td colSpan={3}>
                                            <input type="text" name="title" defaultValue={info.title} onChange={(e)=>{
                                                const tmp = {...info, title : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>키워드</th>
                                        <td colSpan={3}>
                                            <input type="text" name="keyword" defaultValue={info.keyword} onChange={(e)=>{
                                                const tmp = {...info, keyword : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>사이트</th>
                                        <td>
                                            <input type="text" name="site" defaultValue={info.site} onChange={(e)=>{
                                                const tmp = {...info, site : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                        </td>
                                        <th>이미지</th>
                                        <td>
                                            <input type="text" name="img" defaultValue={info.img} onChange={(e)=>{
                                                const tmp = {...info, img : e.target.value}
                                                setInfo(tmp);
                                            }}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="btn-area text-center">
                                <button onClick={(e)=>{e.preventDefault(); fn_updateAction(document.postForm, params, fn_getAniList, fn_close);}} className="btn btn-primary">확인</button>
                                <button onClick={(e)=>{e.preventDefault(); fn_close();}} className="btn btn-danger">닫기</button>
                            </div>
                        </form>
                    </dialog>
                </>
            )
        }
        </>
    );


}