import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';
import { useLoginInfo } from 'hook/loginInfo';

export default function App(props){
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState(null);

    const loginInfo = useLoginInfo();

    const [searchParams, setSearchParams] = useSearchParams();

    const [viewImg1, setViewImg1] = useState("");
    const [viewImg2, setViewImg2] = useState("");

    const date = new Date();

    const [params, setParams] = useState({
        searchStartDate : searchParams.get("searchStartDate") || date.getFullYear() + "-01-01"
        , searchEndDate : searchParams.get("searchEndDate") || date.getFullYear() + "-12-31"
    });

    const fn_getDrawingList = async (params)=>{        
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/drawingList"
        + "?searchStartDate=" + params.searchStartDate
        + "&searchEndDate=" + params.searchEndDate

        fn_authCheck();

        const res = await fetch(url, {
            headers : {
                Authorization :  'Bearer ' + sessionStorage.getItem("accessToken")
            }
        });
        const data = await res.json();

        setList(data.drawingList);

        setLoading(false);
    }

    const fn_view = (ob)=>{
        document.querySelector("#view-wrap").setAttribute("open", "open");
        setViewImg1("https://crizel.duckdns.org/" + ob.path)
        setViewImg2("https://crizel.duckdns.org/crizel/upload/drawing/" + ob.sysFileName)
    }
    const fn_viewClose = ()=>{
        document.querySelector("#view-wrap").removeAttribute("open");
    }

    useEffect(()=>{
        fn_getDrawingList(params);
    }, []);

    return (
        <>
            {
                loginInfo.memberId == 'rhzhzh3' ? (
                    <ul className="tab-menu">
                        <li>
                            <Link to="/drawing" className="active">그림</Link>
                        </li>
                        <li>
                            <Link to="/drawing/item" className="active">사진</Link>
                        </li>
                    </ul>
                ) : ''
            }
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="date" name="searchStartDate" defaultValue={params.searchStartDate} onChange={(e)=>{
                    const tmpParams = {
                        ...params
                        , searchStartDate : e.target.value
                    }
                    setParams(tmpParams);
                    fn_getDrawingList(params);
                }}/>
                <input type="date" name="searchEndDate" defaultValue={params.searchEndDate} onChange={(e)=>{
                    const tmpParams = {
                        ...params
                        , searchEndDate : e.target.value
                    }
                    setParams(tmpParams);
                    fn_getDrawingList(params);
                }}/>
            </form>
        {
            loading == true ? (
                <Loading/>
            ) : (
                <>
                    <ul className="gallery five">
                        {
                            list!=null && list.length>0 ? (
                                list.map((ob, i)=>{
                                    return (
                                        <li key={i}>
                                            <button onClick={(e)=>{e.preventDefault(); fn_view(ob);}}>
                                                <img src={"https://crizel.duckdns.org/crizel/upload/drawing/" + ob.sysFileName}/>
                                                <span style={{display:'block', width:'100%', textAlign:'center'}}>{ob.registerDate.split(".")[0]}</span>
                                            </button>
                                        </li>
                                    );
                                }) 
                            ) : (
                                <li>
                                    데이터가 없습니다.
                                </li>
                            )
                        }
                    </ul>

                </>
            )
        }
            <dialog id="view-wrap" >
                <div className="btn-area text-right">
                    <button onClick={(e)=>{e.preventDefault(); fn_viewClose()}} className="btn btn-danger">닫기</button>
                </div>
                <ul className="gallery two">
                    <li>
                        <img src={viewImg1}/>
                    </li>
                    <li>
                        <img src={viewImg2}/>
                    </li>
                </ul>
            </dialog>
        </>
    );


}