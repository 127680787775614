import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import Loading from 'component/Loading';
import { fn_authCheck } from 'component/info/serverInfo';

export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const loginInfo = useSelector(getLoginInfo);

    const [timeList, setTimeList] = useState(null);

    const [params, setParams] = useState({
        corp : searchParams.get('corp') || ''
        , timeNo : searchParams.get('timeNo') || ''
    });     

    const date = new Date();

    const [info, setInfo] = useState({
        resvNo : ''
        , corp : searchParams.get('corp') || ''
        , timeNo : searchParams.get('timeNo') || ''
        , resvDate : date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
        , userId : sessionStorage.getItem('memberId')
        , resvName : sessionStorage.getItem('memberName')
        , resvPhone : ''
        , status : '0'
        , registerDate : ''
        , corpName : ''
        , startTime : ''
        , endTime : ''
    });

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    const fn_getResvInfo = async(params)=>{
        setLoading(true);
        await fn_authCheck();
        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/resvInfo'
        + "?corp=" + params.corp
        + "&timeNo=" + params.timeNo
        ;

        const res = await fetch(url, {
            method : 'get'
            , headers : {
                Authorization : 'Bearer ' + sessionStorage.getItem('accessToken')
            }
        });
        const data = await res.json();

        if(data.timeInfo!=null){
            setInfo({
                ...info
                , corp : data.timeInfo.corp
                , corpName : data.timeInfo.corpName
                , startTime : data.timeInfo.startTime
                , endTime : data.timeInfo.endTime
            });
        }

        if(data.resvInfo != null){
            setInfo({
                ...info
                , resvNo : data.resvInfo.resvNo
                , corp : data.resvInfo.corp
                , timeNo : data.resvInfo.timeNo
                , resvDate : data.resvInfo.resvDate
                , userId : data.resvInfo.userId
                , resvName : data.resvInfo.resvName
                , resvPhone : data.resvInfo.resvPhone
                , status : data.resvInfo.status
                , registerDate : data.resvInfo.registerDate
            });
        }

        setLoading(false);
    }

    const fn_postForm = async ()=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/resvInsertAction'
        const form = document.postForm;
        const formData = new FormData(form);

        await fn_authCheck();

        if(form.resvName.value == ''){
            alert('이름을 입력하여주시기 바랍니다.');
            form.resvName.focus();
        }else if(form.resvPhone.value == ''){
            alert('전화번호를 입력하여주시기 바랍니다.');
            form.resvPhone.focus();
        }else{ 
            if(window.confirm('예약하시겠습니까?')){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                    , headers : {
                        Authorization : 'Bearer ' + sessionStorage.getItem("accessToken")
                    }
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        navigate('/pg/reserve/insertComp');
                    }else{
                        alert(data.resultMsg!=null&&data.resultMsg!=''?data.resultMsg:'처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                });
            }
        }

        
    }

    useEffect(()=>{
        fn_getResvInfo(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                            <input type="hidden" name="corp" defaultValue={info.corp}/>
                            <input type="hidden" name="timeNo" defaultValue={info.timeNo}/>
                            <table className="table">
                                <colgroup>
                                    <col style={{width:'30%'}}/>
                                    <col style={{width:'70%'}}/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>예약날짜</th>
                                        <td>
                                            {info.resvDate}
                                            <input type="hidden" name="resvDate" defaultValue={info.resvDate}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>골프장</th>
                                        <td>
                                            {info.corpName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>시간</th>
                                        <td>
                                            {info.startTime} ~ {info.endTime}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>예약자명</th>
                                        <td>
                                            <input type="text" name="resvName" defaultValue={info.resvName}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>예약자 연락처</th>
                                        <td>
                                            <input type="text" name="resvPhone" defaultValue={info.resvPhone}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="btn-area text-center">
                                <button onClick={(e)=>{
                                    e.preventDefault();
                                    fn_postForm();
                                }} className="btn btn-primary">예약하기</button>
                                <button onClick={(e)=>{
                                    e.preventDefault();
                                    if(window.confirm('취소하시겠습니까?')){
                                        navigate('/pg/');
                                    }
                                }} className="btn btn-danger">취소</button>
                            </div>
                        </form>
                    </>  
                )
            }
        </>      
    );
}

