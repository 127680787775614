import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [img, setImg] = useState("");

    var iX, iY;
    var bDraw = false;
    
    var canvas;
    var ctx;
    var bb;


    const fn_drawStart = (e)=>{
        iX = e.clientX - bb.left;
        iY = e.clientY - bb.top;
        bDraw = true;

        console.log(bDraw);
    }

    const fn_drawEnd = (e)=>{
        if( bDraw ){
            var iNewX = e.clientX - bb.left;
            var iNewY = e.clientY - bb.top;
        
            ctx.beginPath();
            ctx.moveTo( iX, iY );
            ctx.lineTo( iNewX, iNewY );
            ctx.closePath();
            ctx.stroke();
        
            iX = iNewX;
            iY = iNewY;
        }
    }

    const fn_drawClose = (e)=>{
        bDraw = false;
    }

    const fn_drawSave = (e)=>{
        var canvas = document.getElementById("main");
        var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // MIME 타입 지정
        setImg(canvas.toDataURL("image/png"));
    }

    const fn_postDraw = (e)=>{
        var canvas = document.getElementById("main");
        var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // MIME 타입 지정
        setImg(canvas.toDataURL("image/png"));
        document.postForm.submit();
    }

    const fn_setEraser = ()=>{
        ctx.strokeStyle = "white"
        ctx.lineJoin = "round";
        ctx.lineWidth = 10;
    }

    const fn_setWrite = ()=>{
        ctx.strokeStyle = "black"
        ctx.lineJoin = "round";
        ctx.lineWidth = 2;
    }

    useEffect(()=>{
        canvas = document.getElementById("main");
        ctx = canvas.getContext("2d");
        bb = canvas.getBoundingClientRect();
        ctx.strokeStyle = "black"
        ctx.lineJoin = "round";
        ctx.lineWidth = 2;
    }, []);

    return (
        <>
            <canvas id="main" style={{display:"inline-block", width:"500px",  height:"500px", border:'1px solid red', backgroundColor:'white'}}
                onMouseDown={(e)=>{fn_drawStart(e)}} onTouchStart={(e)=>{fn_drawStart(e)}}
                onMouseMove={(e)=>{fn_drawEnd(e)}} onTouchMove={(e)=>{fn_drawEnd(e);}}
                onMouseUp={(e)=>{fn_drawClose(e)}} onTouchEnd={(e)=>{fn_drawClose(e)}}
            ></canvas>

            <button id="write" onClick={(e)=>{fn_setWrite();}}>쓰기</button>
            <button id="eraser" onClick={(e)=>{fn_setEraser();}}>지우기</button>

            <button id="save" onClick={(e)=>{e.preventDefault(); fn_drawSave(e);}}>저장</button>
            <button id="post" onClick={(e)=>{e.preventDefault(); fn_postDraw(e);}}>전송</button>

            <img id="preview" src={img} style={{display:"inline-block", width:"500px",  height:"500px", backgroundColor:'white'}}/>

            <form name="postForm" action="/canvasRecieve.jsp" method="post">
                <input type="hidden" name="img" defaultValue={img}/>
            </form>

            <input type="hidden" id="font-color" name="font-color" value="black"/>            
        </>        
    );
}

