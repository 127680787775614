import { useLoginInfo } from "hook/loginInfo";
import { Link, useNavigate } from "react-router-dom";
import {useEffect, useState} from 'react'
import Loading from 'component/Loading'
import RoleError from 'component/RoleError'
import { api_commonFetch } from "api/commonFetch";


export default function Home(){

    const loginInfo = useLoginInfo();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [list, setList] = useState([]);



    const fn_getDrawingItemList = async()=>{
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/crizelApi/drawingItemList'
        const config = {
            method : 'get'
            , headers : {
                Authorization : "Bearer " + sessionStorage.getItem('accessToken')
            }
        }
        const data = await api_commonFetch(url, config);
        setList(data.drawingItemList);
        setLoading(false);
    }

    const fn_uploadAction = async (i)=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/drawingUploadAction'
        let formData = new FormData();
        formData.append('path', document.querySelector("#row_" + i + "[name=path]").value);
        formData.append('upload', document.querySelector("#row_" + i + "[name=upload]").files[0]);
        const config = {
            method : 'post'
            , body : formData
            , headers : {
                Authorization : "Bearer "  + sessionStorage.getItem('accessToken')
            }
        }
        const data = await api_commonFetch(url, config);

        if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            navigate('/drawing/list');
        }else{
            alert('처리중 오류가 발생하였습니다.');
        }

    }

    const fn_drawingItemDeleteAction = async(ob)=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/drawingItemDeleteAction'  
        let formData = new FormData();
        formData.append('no', ob.gNo);
        const config = {method : "delete", body : formData}
        const data = await api_commonFetch(url, config);

        if(data.result>0){
            alert('정상적으로 처리되었습니다.');        
            fn_getDrawingItemList();
        }else{
            alert('처리중 오류가 발생하였습니다.');
        }

    }

    useEffect(()=>{
        fn_getDrawingItemList();
    }, []);

    if(loading) return <Loading/>
    else if(loginInfo.memberId != 'rhzhzh3') return <RoleError/>
    return (
        <>
            <ul className="tab-menu">
                <li>
                    <Link to="/drawing/list">그림</Link>
                </li>
                <li>
                    <Link to="/drawing/item" className="active">사진</Link>
                </li>
            </ul>

            <table className="table">
                <colgroup>
                </colgroup>
                <tbody>
                    {
                        list!=null && list.length>0?(
                            list.map((ob, i)=>{
                                return (
                                    <tr key={i}>
                                        <td>
                                            <img src={"https://crizel.duckdns.org/" + ob.img}/>
                                            <input type="hidden" name="path" id={"row_" + i} defaultValue={ob.img}/>
                                            <input type="file" name="upload" id={"row_" + i}/>
                                            <button className="btn btn-primary" onClick={(e)=>{e.preventDefault(); fn_uploadAction(i);}}>확인</button>
                                            <button className="btn btn-danger" onClick={(e)=>{e.preventDefault(); fn_drawingItemDeleteAction(ob)}}>삭제</button>
                                        </td>
                                    </tr>
                                );
                            })
                        ):(
                            <tr>
                                <td colSpan={2}>데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>
    );
}