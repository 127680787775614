import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        itemNo : searchParams.get("itemNo") || ''
        , projectNo : searchParams.get("projectNo") || ''
    });

    const [info, setInfo] = useState({
        itemNo : ''
        , projectNo : ''
        , typeNo : ''
        , itemName : ''
        , itemType : 'T'
        , sortOrd : '0'
        , useFlag : 'Y'
        , deleteFlag : ''
    });

    const [typeList, setTypeList] = useState([]);


    const fn_getDataInfo = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/itemInfo"
        + "?projectNo=" + params.projectNo
        + "&itemNo=" + params.itemNo

        const res = await fetch(url, {
                    method : 'get'
                    , headers : {
                        Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                    }
                });
        const data = await res.json();
        fn_reAuth(fn_getDataInfo, data);

        if(data.info!=null){
            setInfo({
                itemNo  : data.info.itemNo
                , projectNo  : data.info.projectNo
                , typeNo  : data.info.typeNo
                , itemName  : data.info.itemName
                , itemType  : data.info.itemType
                , sortOrd : data.info.sortOrd
                , useFlag : data.info.useFlag
                , deleteFlag  : data.info.deleteFlag
            });
        }
    }

    const fn_getTypeList = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/typeList"
        + "?projectNo=" + params.projectNo

        const res = await fetch(url);
        const data = await res.json();

        setTypeList(data.typeList);
    }

    const fn_postForm = ()=>{
        const form = document.postForm;
        const formData = new FormData(form);
        let url = "";
        let msg = "";

        
        if(form.itemNo.value == ""){
            url = apiServer + "/crizelApi/app/qr/itemInsertAction";
            msg = "등록하시겠습니까?"
        }else{
            url = apiServer + "/crizelApi/app/qr/itemUpdateAction";
            msg = "수정하시겠습니까?"
        }

        if(form.typeNo.value == ""){
            alert('타입을 선택하여주시기 바랍니다.');
            form.typeNo.focus();
        }else if(form.itemName.value == ""){
            alert('항목명을 입력하여주시기 바랍니다.');
            form.itemName.focus();
        }else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : "post"
                    , cache : 'no-cache'
                    , body : formData
                    , headers : {
                        Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                    }
                }).then((res)=>res.json()).then((data)=>{
                    fn_reAuth(fn_postForm, data);
                    
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/qr/projectItem/list?projectNo=' + params.projectNo)
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
        
        

    }


    useEffect(()=>{

        fn_getTypeList(params);

        fn_getDataInfo(params);
        
    }, []);

    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="projectNo" defaultValue={params.projectNo}/>
                <input type="hidden" name="itemNo" defaultValue={params.itemNo}/>
                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>타입</th>
                            <td>
                                <select name="typeNo" value={info.typeNo} onChange={(e)=>{setInfo({...info, typeNo : e.target.value})}}>
                                    <option value="">타입 선택</option>
                                    {
                                        typeList.map((ob, i)=>{
                                            return (
                                                <option value={ob.typeNo}>{ob.typeName}</option>                                                
                                            );
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>항목명</th>
                            <td>
                                <input type="text" name="itemName" defaultValue={info.itemName}/>
                            </td>
                        </tr>
                        <tr>
                            <th>항목 구분</th>
                            <td>
                                <label>
                                    <input type="radio" name="itemType" defaultValue="T" checked={info.itemType == 'T'} onChange={(e)=>{setInfo({...info, itemType : 'T'})}}/>
                                    입력
                                </label>
                                <label>
                                    <input type="radio" name="itemType" defaultValue="P" checked={info.itemType == 'P'} onChange={(e)=>{setInfo({...info, itemType : 'P'})}}/>
                                    선택
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>순서</th>
                            <td>
                                <input type="number" name="sortOrd" value={info.sortOrd} onChange={(e)=>{setInfo({...info, sortOrd : e.target.value})}}/>
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="useFlag" defaultValue="Y" checked={info.useFlag == 'Y'} onChange={(e)=>{setInfo({...info, useFlag : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="useFlag" defaultValue="N" checked={info.useFlag == 'N'} onChange={(e)=>{setInfo({...info, useFlag : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-area">
                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className="btn btn-primary">확인</button>
                    <button onClick={(e)=>{e.preventDefault(); if(window.confirm('취소하시겠습니까?')){navigate('/qr/type/list?projectNo=' + params.projectNo)}}} className="btn btn-danger">취소</button>
                </div>
            </form>
        </>        
    );
}

