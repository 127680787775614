import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import { apiServer } from 'component/info/serverInfo';
import { fn_getStudyList } from 'api/study';

import Loading from 'component/Loading'

export default function App(){
    const navigate = useNavigate();

    window.document.title = "CRIZEL - Study";

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState([]);
    const [category1List, setCategory1List] = useState([]);
    const [category2List, setCategory2List] = useState([]);

    const [searchParmas, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        category1 : searchParmas.get("category1") || ''
        , category2 : searchParmas.get("category1") || ''
        , searchKeyword : searchParmas.get("searchKeyword") || ''
    });

    const fn_getData = async ()=>{
        setLoading(true);
        const data = await fn_getStudyList(params)
        setList(data.list);
        setCategory1List(data.category1List);
        setCategory2List(data.category2List);
        setLoading(false);
    }

    useEffect(()=>{
        fn_getData(params);
    }, [params]);

    if(loading) return <Loading/>
    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault()}}>
                <select name="category1" defaultValue={params.category1} onChange={(e)=>{
                    const tmp = {...params, category1 : e.target.value}
                    setParams(tmp);
                    navigate('/studyList?' + new URLSearchParams(params));
                }}>
                    <option value="">카테고리1</option>      
                    {
                        category1List!=null && category1List.length>0 ? (
                            category1List.filter(ob=>ob!='').map((ob, i)=>(
                                <option key={i} value={ob}>{ob}</option>
                            ))
                        ) : ''
                    }             
                </select>
                <select name="category2" defaultValue={params.category2} onChange={(e)=>{
                    const tmp = {...params, category2 : e.target.value}
                    setParams(tmp);
                    navigate('/studyList?' + new URLSearchParams(params));
                }}>
                    <option value="">카테고리2</option>          
                    {
                        category2List!=null && category2List.length>0 ? (
                            category2List.filter(ob=>ob!='').map((ob, i)=>(
                                <option key={i} value={ob}>{ob}</option>
                            ))
                        ) : ''
                    }           
                </select>
                <input type="text" name="searchKeyword" defaultValue={params.searchKeyword} onKeyDown={(e)=>{if(e.key == 'Enter'){
                    const tmp = {...params, searchKeyword : e.target.value}
                    setParams(tmp);
                    navigate('/studyList?' + new URLSearchParams(params));
                }}}/>
                <button onClick={(e)=>{
                    e.preventDefault();
                    const form = document.searchForm;
                    const tmp = {...params
                        , category1 : form.category1.value
                        , category2 : form.category2.value
                        , searchKeyword : form.searchKeyword.value
                    }
                    setParams(tmp);
                    navigate('/studyList?' + new URLSearchParams(params));
                }} className="btn btn-primary">검색</button>
            </form>
            {
                list!=null && list.length>0 ? (
                    <table className="table list">
                        <colgroup>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>카테고리1</th>
                                <th>카테고리2</th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>작성일</th>
                            </tr>
                        </thead>
                        <tbody>
                    {
                        list.map((ob, i)=>(
                            <tr key={i}>
                                <td>
                                    <span className="mob-title">카테고리1</span>
                                    {ob.category1}
                                </td>
                                <td>    
                                    <span className="mob-title">카테고리2</span>
                                    {ob.category2}
                                </td>
                                <td className="text-left">
                                    <span className="mob-title">제목</span>
                                    <Link to={'/studyView?no=' + ob.no}>{ob.title}</Link>
                                </td>
                                <td>
                                    <span className="mob-title">작성자</span>
                                    {ob.userName}
                                </td>
                                <td>
                                    <span className="mob-title">작성일</span>
                                    {ob.regdate}
                                </td>
                            </tr>
                        ))
                    }
                        </tbody>
                    </table>
                ) : (
                    <div className="no-data">데이터가 없습니다.</div>
                )  
            }
        </>        
    );
}

