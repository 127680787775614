import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import MeetingHeader from 'component/include/MeetingHeader'
import MbHeader from 'component/include/MbHeader'
import NomalHeader from 'component/include/NomalHeader'
import QrHeader from 'component/include/QrHeader'
import CouponHeader from 'component/include/CouponHeader'
import CrizelHeader from 'component/include/CrizelHeader'
import PGHeader from 'component/include/PGHeader'

export default function App(props){
    const navigate = useNavigate();
    const currUrl = window.location.href

    const fn_toggleMenu = ()=>{
        const header = document.querySelector(".header");
        if(header){
            if(header.classList.contains('active')){
                header.classList.remove('active');
            }else{
                header.classList.add('active');
            }
        }
    }

    const fn_closeMenu = ()=>{
        const header = document.querySelector(".header");
        if(header){
            if(header.classList.contains('active')){
                header.classList.remove('active');
            }else{
                header.classList.add('active');
            }
        }
    }

    useEffect(()=>{
        document.querySelectorAll(".header .menu li a").forEach((ob)=>{
            ob.addEventListener('click', ()=>{
                fn_closeMenu();
            });
        });
        document.querySelectorAll(".header .menu li button").forEach((ob)=>{
            ob.addEventListener('click', ()=>{
                fn_closeMenu();
            });
        });

    }, []);

    return (
        <>
            <button className="mob-btn" onClick={(e)=>{e.preventDefault(); fn_toggleMenu();}}>메뉴</button>
            {
                currUrl.indexOf('/mediaboard') > -1 ? (
                    <MbHeader props={props}/>
                ) : currUrl.indexOf('/qr')>-1?(
                    <QrHeader/>
                ): currUrl.indexOf("/coupon")>-1?(                    
                    <CouponHeader props={props}/>
                ): currUrl.indexOf("/meeting")>-1?(
                    <MeetingHeader props={props}/>
                ) : currUrl.indexOf('/crizel/')>-1?(
                    <CrizelHeader/>
                ) : currUrl.indexOf('/pg')>-1?(
                    <PGHeader/>
                ) : (
                    <NomalHeader props={props}/>
                )
            }
        </>        
    );
}