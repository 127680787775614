import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import Main from 'page/Main';
import NotFound from 'page/NotFound';
import Header from 'component/include/Header';
import Footer from 'component/include/Footer';

import MeetingMain from 'page/meeting/Main'
import MeetingInsert from 'page/meeting/Insert'
import MeetingView from 'page/meeting/View'


export const meeting = [
    {path : "/meeting/", element : <MeetingMain/>}
    , {path : "/meeting/insert", element : <MeetingInsert/>}
    , {path : "/meeting/view", element : <MeetingView/>}
]