import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const [list, setList] = useState([]);

    const [params, setParmas] = useState({

    });

    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/deviceList"
        
        const res = await fetch(url);
        const data = await res.json();

        setList(data.deviceList);

    }


    useEffect(()=>{
        fn_getData(params);
    }, []);


    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
            </form>
            <div className="btn-area">
                <Link to="/mediaboard/device/insert" className="btn btn-primary">등록</Link>
            </div>

            <a href={apiServer + "/crizelApi/mediaBoard/deviceList"} target="_blank">기기관리 목록 데이터 URL</a>

            <table className="table list">
                <colgroup>
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>기기명</th>
                        <th>기기 고유번호</th>
                        <th>기기 모델명</th>
                        <th>기기 사용 IP</th>
                        <th>기기 사용 버전</th>
                        <th>사용여부</th>
                        <th>작성자</th>
                        <th>수정일</th>
                        <th>스케쥴 관리</th>
                        <th>조작</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length > 0 ? (
                            list.map((ob, i)=>(
                                <tr key={i}>
                                    <td>
                                        <span className="mob-title">번호</span>
                                        {ob.deviceSeq}
                                    </td>
                                    <td>
                                        <span className="mob-title">기기명</span>
                                        <Link to={"/mediaboard/device/insert?deviceSeq=" + ob.deviceSeq}>{ob.deviceName}</Link>
                                    </td>
                                    <td>
                                        <span className="mob-title">기기 고유번호</span>
                                        {ob.deviceId}
                                    </td>
                                    <td>
                                        <span className="mob-title">기기 모델명</span>
                                        {ob.deviceModel}
                                    </td>
                                    <td>
                                        <span className="mob-title">기기 사용 IP</span>
                                        {ob.deviceIp}
                                    </td>
                                    <td>
                                        <span className="mob-title">기기 사용 버전</span>
                                        {ob.deviceVersion}
                                    </td>
                                    <td>
                                        <span className="mob-title">사용여부</span>
                                        {ob.activeFlag=='Y'?'사용':'미사용'}
                                    </td>
                                    <td>
                                        <span className="mob-title">작성자</span>
                                        {ob.registerOperName}
                                    </td>
                                    <td>
                                        <span className="mob-title">수정일</span>
                                        {ob.updateDate}
                                    </td>
                                    <td>
                                        <Link to={"/mediaboard/schedule/list?deviceSeq=" + ob.deviceSeq}>스케쥴 관리</Link>
                                    </td>
                                    <td>
                                        <Link to={"/mediaboard/device/control?deviceId=" + ob.deviceId + "&deviceSeq=" + ob.deviceSeq}>조작</Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={10} className="text-center">데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>        
    );

}