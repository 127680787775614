import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';

import {apiServer, wsServer} from 'component/info/serverInfo';

export default function App(){
    const navigate = useNavigate();

    const ws = useRef([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const [info, setInfo] = useState({
        schNo : ''
        , schTitle : ''
        , delFlag : ''
        , registerOper : ''
        , registerDate : ''
        , updateOper : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteDate : ''
    });

    const [contentList, setContentList] = useState([]);

    const schNo = searchParams.get("schNo") || ''

    const [params, setParams] = useState({
        schNo : searchParams.get("schNo") || ''
    });

    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/scheduleInfo?schNo=" + params.schNo;

        console.log("fn_getData");

        const res = await fetch(url);
        const data = await res.json();
        if(data.scheduleInfo!=null){
        setInfo({
            schNo : data.scheduleInfo.schNo
            , schTitle : data.scheduleInfo.schTitle
            , delFlag : data.scheduleInfo.delFlag
            , registerOper : data.scheduleInfo.registerOper
            , registerDate : data.scheduleInfo.registerDate
            , updateOper : data.scheduleInfo.updateOper
            , updateDate : data.scheduleInfo.updateDate
            , deleteOper : data.scheduleInfo.deleteOper
            , deleteDate : data.scheduleInfo.deleteDate
        });
        }
        
        setContentList(data.contentList);
    }

    const fn_contentInsert = ()=>{
        let url = apiServer + "/crizelApi/mediaBoard/contentInsertAction";
        let formData = new FormData();
        const contentTitle = document.querySelector(".val_0[name=contentTitle]");
        const contentOrder = document.querySelector(".val_0[name=contentOrder]");
        const upload = document.querySelector(".val_0[name=upload]");
        const filePlayTime = document.querySelector(".val_0[name=filePlayTime]");
        const schNo = document.querySelector("[name=postForm] [name=schNo]");
    
        if(contentTitle!=null && contentOrder!=null && upload!=null && schNo!=null && filePlayTime!=null){
            formData.append("contentTitle", contentTitle.value);
            formData.append("contentOrder", contentOrder.value);
            formData.append("upload", upload.files[0]);
            formData.append("filePlayTime", filePlayTime.value);
            formData.append("schNo", schNo.value);

            if(contentTitle.value == ''){
                alert('컨텐츠명을 입력하여주시기 바랍니다.');
            }else if(contentOrder.value == '' || contentOrder.value == 0){
                alert('컨텐츠 순서를 입력하여주시기 바랍니다.');
            }else if(upload.files == null || upload.files.length == 0){
                alert('컨텐츠를 입력하여주시기 바랍니다.');
            }else if(filePlayTime.value == '' || filePlayTime.value == 0){
                alert('컨텐츠 재생시간을 입력하여주시기 바랍니다.');
            }else{
                if(window.confirm("등록하시겠습니까?")){
                    fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                    }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');          
                        fn_sendWebSocket();  
                        fn_getData(params);
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                    }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    })
                }
            }
        }
    }

    const fn_contentUpdate = (contentNo)=>{
        let url = apiServer + "/crizelApi/mediaBoard/contentUpdateAction";
        const str = document.querySelectorAll(".str_" + contentNo);
        const val = document.querySelectorAll(".val_" + contentNo);

        if(val[0].classList.contains("display-n") == true){
            str.forEach((ob)=>{ob.classList.add('display-n')})
            val.forEach((ob)=>{ob.classList.remove('display-n')})
        }else{
            

            if(document.querySelector(".val_" + contentNo + "[name=contentTitle]").value.value == ''){
                alert('컨텐츠명을 입력하여주시기 바랍니다.');
            }else if(document.querySelector(".val_" + contentNo + "[name=contentOrder]").value.value == '' || document.querySelector(".val_" + contentNo + "[name=contentOrder]").value.value == 0){
                alert('컨텐츠 순서를 입력하여주시기 바랍니다.');
            }else if(document.querySelector(".val_" + contentNo + "[name=filePlayTime]").value == '' || document.querySelector(".val_" + contentNo + "[name=filePlayTime]").value == 0){
                alert('컨텐츠 재생시간을 입력하여주시기 바랍니다.');
            }else{
                let formData = new FormData();
                formData.append("schNo", document.postForm.schNo.value);
                formData.append("contentNo", contentNo);
                formData.append("contentTitle", document.querySelector(".val_" + contentNo + "[name=contentTitle]").value);
                formData.append("contentOrder", document.querySelector(".val_" + contentNo + "[name=contentOrder]").value);
                formData.append("upload", document.querySelector(".val_" + contentNo + "[name=upload]").files[0]);
                formData.append("filePlayTime", document.querySelector(".val_" + contentNo + "[name=filePlayTime]").value);

                if(window.confirm('수정하시겠습니까?')){
                    fetch(url, {
                        method : 'post'
                        , cache : 'no-cache'
                        , body : formData
                    }).then((res)=>res.json()).then((data)=>{
                        if(data.result>0){
                            alert('정상적으로 처리되었습니다.');
                            val.forEach((ob)=>{ob.classList.add('display-n')})
                            str.forEach((ob)=>{ob.classList.remove('display-n')})
                            fn_getData(params);
                        }else{
                            alert('처리중 오류가 발생하였습니다.');
                        }
                    }).catch((err)=>{
                        alert('처리중 오류가 발생하였습니다. : ' + err);
                    });
                }                
            }

        }
    }

    const fn_contentDelete = (contentNo)=>{
        let url = apiServer + "/crizelApi/mediaBoard/contentDeleteAction";
        let formData = new FormData();
        formData.append("contentNo", contentNo);

        if(window.confirm("삭제하시겠습니까?")){
        fetch(url, {
            method : "post"
            , cache : 'no-cache'
            , body : formData
        }).then((res)=>res.json()).then((data)=>{
            if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            fn_sendWebSocket();  
            fn_getData(params);
            }else{
            alert('처리중 오류가 발생하였습니다.');
            }
        }).catch((err)=>{
            alert('처리중 오류가 발생하였습니다 : ' + err);
        });
        }

    }

    const fn_postForm = ()=>{
        let url = apiServer + "/crizelApi/mediaBoard/schduleInsertAction";
        const form = document.querySelector("[name=postForm]");
        if(form!=null){
        let formData = new FormData(form);
        let msg = "등록하시겠습니까?";

        if(form.schNo.value != ''){
            msg = "수정하시겠습니까?"
        }

        if(window.confirm(msg)){
            fetch(url, {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
            }).then((res)=>res.json()).then((data)=>{
            if(data.result>0){
                alert('정상적으로 처리되었습니다.');
                navigate('/mediaboard/scheduleList')
            }else{
                alert('처리중 오류가 발생하였습니다');
            }
            }).catch((err)=>{
            alert('처리중 오류가 발생하였습니다. : ' + err);
            });
        }
        }
    }


    const fn_sendWebSocket = ()=>{
        ws.current.send(JSON.stringify({
        event : 'mediaBoardContentReload'	
        , data : "Y"
        }));
    }

    useEffect(()=>{
        
        fn_getData(params);

        ws.current = new WebSocket(wsServer)
        ws.current.onopen = () => {
            console.log('connected')
        };
        ws.current.onmessage = (e) => {
            console.log(e.data);
        };

        ws.current.onerror = (e) => {
            console.log(e.message);
        };

        ws.current.onclose = (e) => {
            console.log(e.code, e.reason);
            ws.current.onopen = () => {
                console.log('re connected')
            };
        };

        ws.current.onmessage = (e)=>{
            const obj = JSON.parse(e.data);

            console.log(obj);           

        }   

    }, []);

    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="schNo" defaultValue={info.schNo}/>
                <table className="table">
                    <colgroup>
                        <col style={{width : '30%'}}/>
                        <col style={{width : '70%'}}/>
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                        <th>
                            제목
                        </th>
                        <td>
                            <input type="text" name="schTitle" defaultValue={info.schTitle}/>
                        </td>
                        </tr>            
                    </tbody>
                </table>
                {
                schNo != null && schNo != '' ? (
                        <table className="table list">
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>제목</th>
                                    <th>순서</th>
                                    <th>파일</th>
                                    <th>재생시간(초)</th>
                                    <th>수정/삭제</th>
                                </tr>                            
                            </thead>
                            <tbody id="contentList">
                                <tr>
                                    <td>
                                        신규 등록
                                    </td>
                                    <td>
                                        <span className="mob-title">제목</span>
                                        <input type="text" name="contentTitle" placeholder="제목" className="val_0"/>
                                    </td>
                                    <td>
                                        <span className="mob-title">순서</span>
                                        <input type="number" name="contentOrder" defaultValue="0" className="val_0"/>
                                    </td>
                                    <td>
                                        <span className="mob-title">파일</span>
                                        <input type="file" name="upload" placeholder="파일" className="val_0"/>
                                    </td>
                                    <td>
                                        <span className="mob-title">재생시간(초)</span>
                                        <input type="number" name="filePlayTime" defaultValue="3" className="val_0"/>
                                    </td>
                                    <td>
                                        <button onClick={(e)=>{e.preventDefault(); fn_contentInsert();}} className="btn btn-primary">등록</button>
                                    </td>
                                </tr>
                                {
                                    contentList!=null && contentList.length>0 ? (
                                        contentList.map((ob, i)=>(
                                        <tr key={i} id={"content_" + ob.contentNo}>
                                            <td>
                                                {ob.contentNo}                            
                                            </td>
                                            <td>
                                            <span className="mob-title">제목</span>
                                                <span className={"str_" + ob.contentNo}>{ob.contentTitle}</span>
                                                <input type="text" className={"display-n val_" + ob.contentNo} name="contentTitle" defaultValue={ob.contentTitle}/>
                                            </td>
                                            <td>
                                            <span className="mob-title">순서</span>
                                                <span className={"str_" + ob.contentNo}>{ob.contentOrder}</span>
                                                <input type="number" className={"display-n val_" + ob.contentNo} name="contentOrder" defaultValue={ob.contentOrder}/>
                                            </td>
                                            <td className="text-left">
                                            <span className="mob-title">파일</span>
                                                <span className={"str_" + ob.contentNo}><a href={apiServer + "/upload/" + ob.sysFileName} target="_blank">{ob.orgFileName}</a></span>
                                                <input type="file" className={"display-n val_" + ob.contentNo} name="upload" />
                                            </td>
                                            <td>
                                            <span className="mob-title">재생시간(초)</span>
                                                <span className={"str_" + ob.contentNo}>{ob.filePlayTime}</span>
                                                <input type="number" className={"display-n val_" + ob.contentNo} name="filePlayTime" defaultValue={ob.filePlayTime}/>
                                            </td>
                                            <td>
                                            <button onClick={(e)=>{e.preventDefault(); fn_contentUpdate(ob.contentNo);}} className="btn btn-success">수정</button>
                                            <button onClick={(e)=>{e.preventDefault(); fn_contentDelete(ob.contentNo);}} className="btn btn-danger">삭제</button>
                                            </td>
                                        </tr>
                                        ))
                                    ) : ''
                                }
                            </tbody>
                        </table>
                ) : (
                    <div className="no-data">
                    스케쥴 작성 후 컨텐츠 등록이 가능합니다.
                    </div>
                )
                }

                <div className="btn-area">
                    <button className="btn btn-primary" onClick={(e)=>{e.preventDefault(); fn_postForm();}}>확인</button>
                    <button className="btn btn-danger" onClick={(e)=>{if(window.confirm('취소하시겠습니까?')){navigate(-1)}}}>취소</button>
                </div>

            </form>
        </>        
    );
}

