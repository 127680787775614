import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading';

import {fn_getScsFoodMenuList, fn_getDayOfWeek} from 'api/main'

import clean from 'resource/audio/clean.mp3'
import lunch from 'resource/audio/lunch.mp3'
import end from 'resource/audio/end.mp3'


export default function App(){
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const date = new Date();
    const endDate = new Date(date.getFullYear(), date.getMonth()+1, 0);

    const [timeStr, setTimeStr] = useState(new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[0] + "(" + fn_getDayOfWeek(new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[0]) + ") " + new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[1].split(".")[0]);

    const [params, setParams] = useState({
        searchStartDate : searchParams.get('searchStartDate') || (date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0'))
        , searchEndDate : searchParams.get('searchEndDate') || (endDate.getFullYear() + '-' + String(endDate.getMonth()+1).padStart(2, '0') + '-' + String(endDate.getDate()).padStart(2, '0'))
        , noHeader : searchParams.get('noHeader') || ''
    });

    const [list, setList] = useState([]);
   

    const fn_getDataList = async (params)=>{
        setLoading(true);
        setList(await fn_getScsFoodMenuList(params));
        setLoading(false);
    }

    useEffect(()=>{        
        fn_getDataList(params);
        const header = document.querySelector("header");
        if(header && params.noHeader == 'Y'){
            header.classList.add('display-n')
        }

        setInterval(()=>{
            const date = new Date();
            const dateStr = new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[0];
            const timeStr = new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[1].split(".")[0]
            setTimeStr(dateStr + "(" + fn_getDayOfWeek(dateStr) + ") " + timeStr )

            
            if(timeStr == '08:29:55'){
                const audio = document.querySelector("#audio1");
                audio.play();
            }else if(timeStr == '12:09:55'){
                const audio = document.querySelector("#audio2");
                audio.play();
            }else if(timeStr == '18:00:00'){
                const audio = document.querySelector("#audio3");
                audio.play();
            }

        }, 1000);

        setInterval(()=>{
            window.location.reload();
        }, 1000 * 60 * 60)
        
    }, [params]);

    if(loading) return <Loading/>
    return (
        <>
            <audio className="display-n" id="audio1" src={clean} controls/>
            <audio className="display-n" id="audio2" src={lunch} controls/>
            <audio className="display-n" id="audio3" src={end} controls/>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="date" name="searchStartDate" defaultValue={params.searchStartDate} onChange={(e)=>{
                    const tmp = {...params, searchStartDate : e.target.value}
                    setParams(tmp);
                }}/>
                <input type="date" name="searchEndDate" defaultValue={params.searchEndDate} onChange={(e)=>{
                    const tmp = {...params, searchEndDate : e.target.value}
                    setParams(tmp);
                }}/>
                <input type="hidden" name="noHeader" defaultValue={params.noHeader}/>
            </form>
            <table className="table">
                <colgroup>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'40%'}}/>
                    <col style={{width:'40%'}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th colSpan={3}>
                            {
                                params.noHeader != 'Y' ? (
                                    <button onClick={(e)=>{
                                        const tmp = {...params, noHeader : 'Y'}
                                        setParams(tmp);
                                        navigate("/?noHeader=Y")
                                    }}>{timeStr}</button>
                                ) : (
                                    <span>{timeStr}</span>
                                )
                            }
                            
                        </th>
                    </tr>
                    <tr>
                        <th>날짜</th>
                        <th>메뉴</th>
                        <th>이미지</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length>0 ? (
                            list.filter(ob=>ob.menuItem!=null&&ob.menuItem!='').map((ob, i)=>{
                                return (
                                    <tr key={i} >
                                        <td>
                                            {ob.menuDate.substring(0, 10)} ({fn_getDayOfWeek(ob.menuDate.substring(0, 10))})
                                        </td>
                                        <td dangerouslySetInnerHTML={{__html : ob.menuItem.split(" ").join("<br>")}}>
                                            
                                        </td>
                                        <td>
                                            {
                                                ob.img!=null && ob.img!='' ? (
                                                    <a href={"https://crizel.duckdns.org/crizel/upload/scsFoodMenu/" + ob.img} target="_blank">
                                                        <img src={"https://crizel.duckdns.org/crizel/upload/scsFoodMenu/" + ob.img}/>                                                                        
                                                    </a>
                                                ) : (
                                                    <img src={"https://crizel.duckdns.org/images/noimage.jpg"}/>
                                                )
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                        ) : ''
                    }
                </tbody>
            </table>
        </> 
    );
}

