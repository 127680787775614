import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';

export default function App(props){
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const date = props.date;

    const [params, setParams] = useState({
        date : date
    });

    const [foodInfo, setFoodInfo] = useState({
        menuNo : ''
        , menuDate : date
        , menuItem : ''
        , menuLike : ''
        , registerDate : ''
        , url : ''
        , img : ''
    });

    const fn_getFoodInfo = async (params)=>{
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/foodInfo"
        + "?menuDate=" + params.date
        ;

        const res = await fetch(url);
        const data = await res.json();

        if(data.foodInfo!=null){
            setFoodInfo({
                menuNo : data.foodInfo.MENU_NO
                , menuDate : data.foodInfo.MENU_DATE
                , menuItem : data.foodInfo.MENU_ITEM
                , menuLike : data.foodInfo.MENU_LIKE
                , registerDate : data.foodInfo.REGISTER_DATE
                , url : data.foodInfo.URL
                , img : data.foodInfo.IMG
            })
        }
        setLoading(false);
    }

    
    const fn_insert = async (id)=>{
        document.querySelector(id).setAttribute("open", 'open');
    }


    const fn_close = (id)=>{
        document.querySelector(id).removeAttribute("open");
    }

    const fn_postForm = ()=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/foodInsertAction"
        const form = document.postForm;
        const formData = new FormData(form);

        fetch(url, {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
        }).then((res)=>res.json()).then((data)=>{
            if(data.result>0){
                fn_close("#food-dlg");
                fn_getFoodInfo(params);
            }else{
                if(data.result == -99){
                    alert('첨부할 수 없는 확장자가 포함되었습니다.');
                }else{
                    fn_close("#food-dlg");
                    fn_getFoodInfo(params);
                }
            }
        }).catch((err)=>{
            alert('처리중 오류가 발생하였습니다.');
        });
    }



    useEffect(()=>{
        fn_getFoodInfo(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <table className="table">
                            <colgroup>
                                <col style={{width : '25%'}}/>
                                <col style={{width : '75%'}}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>날짜</th>
                                    <td>
                                        {foodInfo.menuDate}
                                    </td>
                                </tr>
                                <tr>
                                    <th>메뉴</th>
                                    <td>
                                        <span dangerouslySetInnerHTML={{__html : foodInfo.menuItem!=null&&foodInfo.menuItem!=''?foodInfo.menuItem.split(" ").join("<br>"):''}}></span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이미지</th>
                                    <td>  
                                        <img src={"https://crizel.duckdns.org/crizel/upload/scsFoodMenu/" + foodInfo.img}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="btn-area">
                            <button onClick={(e)=>{
                                e.preventDefault();
                                fn_insert("#food-dlg");
                            }} className="btn btn-success">수정</button>
                        </div>

                        <dialog id="food-dlg">
                            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                                <input type="hidden" name="menuNo" defaultValue={foodInfo.menuNo}/>
                                <table className="table">
                                    <colgroup>
                                        <col style={{width : '25%'}}/>
                                        <col style={{width : '75%'}}/>
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>날짜</th>
                                            <td>
                                                <input type="date" name="menuDate" defaultValue={foodInfo.menuDate}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>메뉴</th>
                                            <td>
                                                <textarea name="menuItem" defaultValue={foodInfo.menuItem}></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이미지</th>
                                            <td>  
                                                <input type="file" name="upload"/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="btn-area">
                                    <button onClick={(e)=>{
                                        e.preventDefault();
                                        fn_postForm();
                                    }} className="btn btn-primary">확인</button>
                                    <button onClick={(e)=>{e.preventDefault(); fn_close('#food-dlg')}} className="btn btn-danger">닫기</button>
                                </div>
                            </form>
                        </dialog>
                    </>
                )
            }
        </>
    );
}