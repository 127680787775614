import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import { fn_getGirlsImgList } from 'api/crizel/girls';
import { useLoginInfo } from 'hook/loginInfo';

export default function App(props){
    const navigate = useNavigate();

    const loginInfo = useLoginInfo();
    const dispatch = useDispatch();

    const addLiRef = useRef(null);

    const [loading, setLoading] = useState(true);

    const [totalList, setTotalList] = useState(null);
    const [list, setList] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const [memberId, setMemberId] = useState(sessionStorage.getItem('memberId') || '');

   

    const [params, setParams] = useState({
        name : searchParams.get('name') || ''
        , pageNo : searchParams.get('pageNo') || '1'
        , countList : searchParams.get('countList') || '20'
    });

    

    const fn_goPage = (params ,pList)=>{
        let pageNo = Number(params.pageNo);
		let countList = Number(params.countList);
		let startRow = 0;
		let endRow = 0;
		startRow = (pageNo - 1) * countList+1;
		endRow = pageNo * countList;

        const tmpList = pList!=null?pList:totalList;

        let tmp = pageNo == 1 ? [] : list;

        if(pList!=null && pList.length>0){
            for(let i=startRow; i<=endRow; i++){
                tmp.push(tmpList[i]);
            }  
        }
         

        setList(tmp);

    }

    useEffect(()=>{

        if(loginInfo.memberId == 'rhzhzh3'){
            fn_getGirlsImgList(params, setLoading, setTotalList, fn_goPage);
        }else{
            navigate('/')
        }

    }, []);

    if(loading) return <Loading/>
    return (
        <>
            <h1>{params.name}</h1>
            <ul className="girls-gallery">
                {
                    list!=null && list.length>0 ? (
                        list.map((ob, i)=>{
                            return (
                                ob!=null ? (
                                    <li key={i}>
                                        <img src={"https://crizel.duckdns.org/" + ob.img}/>
                                    </li>
                                ) : ''
                            );
                        })
                    ) : (
                        <li>
                            데이터가 없습니다.
                        </li>
                    )
                }
            </ul>
            <div className="btn-area">
                <button onClick={(e)=>{
                    e.preventDefault();
                    const tmp = {...params, pageNo : Number(params.pageNo)+1}
                    setParams(tmp)
                    fn_goPage(tmp, null);
                }} className="btn btn-primary">추가</button>
            </div>
        </>
    );
}