import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link} from 'react-router-dom';
import CouponFooter from './CouponFooter';


export default function App(props){
    const navigate = useNavigate();
    const currUrl = window.location.href

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    return (
        <>
            {
                currUrl.indexOf('/coupon') > -1 ? (
                    <CouponFooter/>
                ) : (
                    <footer>
                        rhzhzh3@naver.com
                    </footer>
                )
            }
        </>
    );
}

