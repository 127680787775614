import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading';

export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [timeList, setTimeList] = useState(null);

    const [params, setParams] = useState({
        corp : searchParams.get('corp') || ''
    });     

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    const fn_timeList = async(params)=>{
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/timeViewList'
        + "?corp=" + params.corp
        ;

        const res = await fetch(url);
        const data = await res.json();

        setTimeList(data.timeList);
        setLoading(false);
    }

    useEffect(()=>{
        fn_timeList(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <table className="table list">
                            <thead>
                                <tr>
                                    <th>시간</th>
                                    <th>요금</th>
                                    <th>신청인원 / 최대신청인원</th>
                                    <th>선택</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                timeList!=null && timeList.length>0 ? (
                                    timeList.map((ob, i)=>{
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    {ob.startTime} ~ {ob.endTime}
                                                </td>
                                                <td>
                                                    {
                                                        ob.discountAmount!='' && ob.discountAmount!='0' ? (
                                                            ob.discountAmount
                                                        ) : ob.basicAmount
                                                    }
                                                </td>
                                                <td>
                                                    {ob.resvCount} / {ob.maxCount}
                                                </td>
                                                <td>
                                                    {
                                                        ob.resvCount == ob.maxCount ? (
                                                            <span>예약불가</span>
                                                        ) : (
                                                            <Link to={"/pg/reserve/insert?corp=" + ob.corp + "&timeNo=" + ob.timeNo} 
                                                                className="btn btn-primary">선택</Link>
                                                        )
                                                    }
                                                </td>
                                            </tr>                                            
                                        );
                                    })
                                ) : ''
                            }
                            </tbody>
                        </table>
                    </>  
                )
            }
        </>      
    );
}

