import { api_commonFetch } from "./commonFetch";


export const fn_list = async(params)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/rt/rtList"
    + "?pageNo=" + params.pageNo
    const config = {
        method : 'get'
        , cache : 'no-cache'
    }
    const data = await api_commonFetch(url, config);
    return data;

}

export const fn_categoryList = async(params)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/rt/rtCategoryList"
    const config = {
        method : 'get'
        , cache : 'no-cache'
    }
    const data = await api_commonFetch(url, config);
    return data;

}


export const fn_info = async(params)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/rt/rtInfo"
    + "?no=" + params.no
    ;
    const config = {
        method : 'get'
        , cache : 'no-cache'
    }
    const data = await api_commonFetch(url, config);
    return data;
}

export const fn_ApiPostForm = async (form)=>{
    const formData = new FormData(form);
    let url = "";
    let msg = "";
    let result = 0;

    if(form.no.value == ""){
        url = process.env.REACT_APP_API_URL + "/crizelApi/rt/rtInsertAction";
        msg = "등록하시겠습니까?"
    }else{
        url = process.env.REACT_APP_API_URL + "/crizelApi/rt/rtUpdateAction";
        msg = "수정하시겠습니까?"
    }

    if(form.rstrTitle.value == ""){
        alert('식당이름을 입력하여주시기 바랍니다.');
        form.rstrTitle.focus();
    }else if(form.rstrLocation.value == ""){
        alert('식당주소를 입력하여주시기 바랍니다.');
        form.rstrLocation.focus();
    }else{
        if(window.confirm(msg)){

            const config = {
                method : 'post'
                , cache : 'no-cache'
                , body : formData
                , headers : {
                    Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                }  
            }
            const data = await api_commonFetch(url, config);

            if(data.result>0){
                alert('정상적으로 처리되었습니다.');
            }else{
                alert('처리중 오류가 발생하였습니다.');
            }
            result = data.result
            return result;
        }        
    }

}


export const fn_rtInfo = async(params)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/rt/rtInfo"
    + "?no=" + params.no
    ;
    const config = {
        method : 'get'
        , cache : 'no-cache'
    }
    const data = await api_commonFetch(url, config);

    return data
}