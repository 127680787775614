import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    return (
        <>
            로딩중
        </>        
    );
}

