import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        projectNo : searchParams.get("projectNo") || ''
    });

    const [info, setInfo] = useState({
        projectNo  : ''
        , projectName  : ''
        , orgFile_name  : ''
        , sysFile_name  : ''
        , useFlag  : 'Y'
        , deleteFlag  : ''
        , registerOper  : ''
        , registerOperName  : ''
        , registerDate  : ''
    });


    const fn_getDataInfo = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/projectInfo"
        + "?projectNo=" + params.projectNo

        const res = await fetch(url, {
                    method : 'get'
                    , headers : {
                        Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                    }
                });
        const data = await res.json();
        fn_reAuth(fn_getDataInfo, data);

        if(data.info!=null){
            setInfo({
                projectNo  : data.info.projectNo
                , projectName  : data.info.projectName
                , orgFile_name  : data.info.orgFile_name
                , sysFile_name  : data.info.sysFile_name
                , useFlag  : data.info.useFlag
                , deleteFlag  : data.info.deleteFlag
                , registerOper  : data.info.registerOper
                , registerOperName  : data.info.registerOperName
                , registerDate  : data.info.registerDate
            });
        }
    }

    const fn_postForm = ()=>{
        const form = document.postForm;
        const formData = new FormData(form);
        let url = "";
        let msg = "";

        
        if(form.projectNo.value == ""){
            url = apiServer + "/crizelApi/app/qr/projectInsertAction";
            msg = "등록하시겠습니까?"
        }else{
            url = apiServer + "/crizelApi/app/qr/projectUpdateAction";
            msg = "수정하시겠습니까?"
        }

        if(form.projectName.value == ""){
            alert('프로젝트명을 입력하여주시기 바랍니다.');
            form.projectName.focus();
        }else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : "post"
                    , cache : 'no-cache'
                    , body : formData
                    , headers : {
                        Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                    }
                }).then((res)=>res.json()).then((data)=>{
                    fn_reAuth(fn_postForm, data);
                    
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/qr/project/list')
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
        
        

    }


    useEffect(()=>{

        fn_getDataInfo(params);
        
    }, []);

    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="projectNo" defaultValue={params.projectNo}/>
                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>프로젝트명</th>
                            <td>
                                <input type="text" name="projectName" defaultValue={info.projectName}/>
                            </td>
                        </tr>
                        <tr>
                            <th>첨부파일</th>
                            <td>
                                <input type="file" name="upload" onClick={(e)=>{e.preventDefault(); alert('기능 미구현');}}/>
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="useFlag" defaultValue="Y" checked={info.useFlag == 'Y'} onChange={(e)=>{setInfo({...info, useFlag : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="useFlag" defaultValue="N" checked={info.useFlag == 'N'} onChange={(e)=>{setInfo({...info, useFlag : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-area">
                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className="btn btn-primary">확인</button>
                    <button onClick={(e)=>{e.preventDefault(); if(window.confirm('취소하시겠습니까?')){navigate('/qr/project/list')}}} className="btn btn-danger">취소</button>
                </div>
            </form>
        </>        
    );
}

