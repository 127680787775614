import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_ApiPostForm, fn_categoryList, fn_info } from 'api/restaurant';


export default function App(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState({
        no : searchParams.get('no') || ''
    });
    const [info, setInfo] = useState({
        no  : ''
        , category1  : ''
        , category2  : ''
        , rstrTitle  : ''
        , rstrLocation  : ''
        , rstrReview  : ''
        , linkUrl  : ''
        , userName  : sessionStorage.getItem("memberName")
        , registerIp  : ''
        , registerId  : ''
        , registerDate  : ''
    });
    const [category1List, setCategory1List] = useState(null);
    const [category2List, setCategory2List] = useState(null);
    const [fileList, setFileList] = useState(null);

    const fn_getRtInfo = async(params)=>{
        setLoading(true)
        const data = await fn_info(params);

        if(data.rtInfo!=null){
            setInfo({
                no  : data.rtInfo.no
                , category1  : data.rtInfo.category1
                , category2  : data.rtInfo.category2
                , rstrTitle  : data.rtInfo.rstrTitle
                , rstrLocation  : data.rtInfo.rstrLocation
                , rstrReview  : data.rtInfo.rstrReview
                , linkUrl  : data.rtInfo.linkUrl
                , userName  : data.rtInfo.userName
                , registerIp  : data.rtInfo.registerIp
                , registerId  : data.rtInfo.registerId
                , registerDate  : data.rtInfo.registerDate
            });
        }

        setFileList(data.rtFileList)

        setLoading(false)
    }

    const fn_getCategoryList = async (params)=>{
        const data = await fn_categoryList(params);
        setCategory1List(data.rtCategory1List);
        setCategory2List(data.rtCategory2List);
    }

    const fn_postForm = async ()=>{
        const result = await fn_ApiPostForm(document.postForm)
        if(result>0){
            navigate('/rt')
        }
    }
    
    const fn_fileDeleteAction = (fileNo)=>{
        
    }
    useEffect(()=>{
        if(sessionStorage.getItem("accessToken")!=null && sessionStorage.getItem("accessToken")!=""){
            fn_getRtInfo(params);
        }else{
            alert('로그인 후 이용하여주시기 바랍니다.');
            navigate("/login?returnUrl=" + encodeURIComponent(window.location.href));
        }
    }, []);

    return (
        <>
           <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="no" defaultValue={info.no}/>
            <table className="table">
                    <colgroup>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '30%'}}/>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '30%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>카테고리1</th>
                            <td>
                                <select onChange={(e)=>{
                                    let tmp = {...info, category1 : e.target.value}
                                    setInfo(tmp);      
                                    document.postForm.category1.value = e.target.value;                                                                  
                                }} value={info.category1}>
                                    <option value="">카테고리1</option>
                                    {
                                        category1List!=null && category1List.length>0?(
                                            category1List.map((ob, i)=>{
                                                return (
                                                    <option key={i} value={ob.category1}>{ob.category1}</option>
                                                );
                                            })
                                        ):''
                                    }
                                </select>
                                <input type="text" name="category1" defaultValue={info.category1}/>
                            </td>
                            <th>카테고리2</th>
                            <td>
                                <select onChange={(e)=>{
                                    let tmp = {...info, category2 : e.target.value}
                                    setInfo(tmp);      
                                    document.postForm.category2.value = e.target.value;                                                                  
                                }} value={info.category1}>
                                    <option value="">카테고리2</option>
                                    {
                                        category2List!=null && category2List.length>0?(
                                            category2List.map((ob, i)=>{
                                                return (
                                                    <option key={i} value={ob.category2}>{ob.category2}</option>
                                                );
                                            })
                                        ):''
                                    }
                                </select>
                                <input type="text" name="category2" defaultValue={info.category2}/>
                            </td>
                        </tr>
                        <tr>
                            <th>작성자</th>
                            <td colSpan={3}>
                                <input type="text" name="userName" defaultValue={info.userName}/>
                            </td>
                        </tr>
                        <tr>
                            <th>식당이름</th>
                            <td colSpan={3}>
                                <input type="text" name="rstrTitle" defaultValue={info.rstrTitle}/>
                            </td>
                        </tr>
                        <tr>
                            <th>식당주소</th>
                            <td colSpan={3}>
                                <input type="text" name="rstrLocation" defaultValue={info.rstrLocation}/>
                            </td>
                        </tr>
                        <tr>
                            <th>외부링크</th>
                            <td colSpan={3}>
                                <input type="text" name="linkUrl" defaultValue={info.linkUrl}/>
                            </td>
                        </tr>
                        <tr>
                            <th>이미지</th>
                            <td colSpan={3}>
                                <input type="file" multiple name="upload"/>
                                {
                                    fileList!=null && fileList.length>0 ? (
                                        fileList.map((ob, i)=>{
                                            return (
                                                <div key={i}>
                                                    {ob.oriFileName} <buttion className="btn btn-danger" onClick={(e)=>{e.preventDefault(); fn_fileDeleteAction(ob.fileNo)}}>삭제</buttion>
                                                </div>
                                            );
                                        })
                                    ) : ''
                                }
                            </td>
                        </tr>
                    </tbody>
            </table>
            <div className="btn-area text-center">
                <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className="btn btn-primary">확인</button>
                <Link to={"/rt/list"} className="btn btn-list">목록</Link>
            </div>
           </form>
        </>        
    );
}

