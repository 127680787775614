
import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';


//export const apiServer="https://crizelr.duckdns.org"
//export const apiServer = "http://localhost:38080"
export const apiServer = process.env.REACT_APP_API_URL;
export const wsServer="wss://crizelr.duckdns.org/webSocket"

export const fn_reAuth = async (callFn, data)=>{

    if(data.result == "A"){

        let url = process.env.REACT_APP_API_URL + "/crizelApi/member/reAuth";

        const refreshToken = sessionStorage.getItem('refreshToken')

        await fetch(url, {
            method : 'post'
            , cache : 'no-cache'
            , headers : {
                Authorization :  'Bearer ' + refreshToken
            }
        }).then((res)=>res.json()).then((data)=>{
            sessionStorage.setItem("accessToken", data.accessToken);
            sessionStorage.setItem("refreshToken", data.refreshToken);
            sessionStorage.setItem("memName", data.memName);
        })

        callFn();
        return;
    }else if(data.result == "R"){
        window.location.href="/login?returnUrl=" + encodeURIComponent(window.location.href);
        return;
    }
    

    /*
    let url = apiServer + "/crizelApi/member/reAuth";

    const refreshToken = sessionStorage.getItem('refreshToken')

    await fetch(url, {
        method : 'post'
        , cache : 'no-cache'
        , headers : {
            Authorization :  'Bearer ' + refreshToken
        }
    }).then((res)=>res.json()).then((data)=>{
        if(data.result == 'S'){
            sessionStorage.setItem('accessToken', data.accessToken);
        }else{
            alert('로그인이 필요합니다.');
            window.location.href=loginUrl;
        }
    })
    */
}

export const fn_authCheck = async ()=>{

    let url = process.env.REACT_APP_API_URL + "/crizelApi/member/reAuth";

    const refreshToken = sessionStorage.getItem('refreshToken')

    let obj = {
        accessToken : sessionStorage.getItem('accessToken')
    };

    if(refreshToken!=null && refreshToken!=''){
        await fetch(url, {
            method : 'post'
            , cache : 'no-cache'
            , headers : {
                Authorization :  'Bearer ' + refreshToken
            }
        }).then((res)=>res.json()).then((data)=>{
            if(data.result == 'R'){
                sessionStorage.setItem("accessToken", data.accessToken);
                obj = {
                    'accessToken' : data.accessToken
                }
            }else if(data.result == 'F'){
                alert('로그인 후 이용하여주시기 바랍니다.');
                window.location.href='/login?returnUrl=' + encodeURIComponent(window.location.href);
            }
        })
    }

    

    return obj;
}