import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        no : searchParams.get('no') || ''
    });

    const [info, setInfo] = useState({
        no : ''
        , category1 : ''
        , category2 : ''
        , title : ''
        , path : ''
        , thumbnail : ''
        , registerDate : ''
    });
    const [list, setList] = useState(null);

    const [video, setVideo] = useState('');

    const fn_getMovieInfo = async (params)=>{
        const url = process.env.REACT_APP_API_URL + "/crizelApi/movieInfo"
        + '?no=' + params.no;
        const res = await fetch(url);
        const data = await res.json();
        if(data.movieInfo!=null){
            setInfo({
                no : data.movieInfo.no
                , category1 : data.movieInfo.category1
                , category2 : data.movieInfo.category2
                , title : data.movieInfo.title
                , path : data.movieInfo.path
                , thumbnail : data.movieInfo.thumbnail
                , registerDate : data.movieInfo.registerDate
            });

            setVideo("https://crizel.duckdns.org" + data.movieInfo.path);
        }
        
    }

    const fn_getMovieList = async (params)=>{
        let url = process.env.REACT_APP_API_URL + "/crizelApi/movieList"
        + "?searchKeyword=" + params.searchKeyword
        + "&category2=" + params.category2;


        const res = await fetch(url);
        const data = await res.json();
        setList(data.movieList);
    }


    useEffect(()=>{
        fn_getMovieInfo(params);
        fn_getMovieList(params);
    }, []);

    return (
        <>
            <video id="movieVideo" src={video} autoPlay controls>
                <source src={video} type="video/mp4"/>
                <source src={video} type="video/ogg"/>
                <source src={video} type="video/webm"/>
                <source src={video} type="video/avi"/>
                <source src={video} type="video/mp3"/>
                <source src={video} type="video/mkv"/>
                <source src={video} type="video/mov"/>
                <source src={video} type="video/wmv"/>
                <source src={video} type="video/vob"/>
            </video>

            <div className="text-center">
                <Link to="/movie" className="btn btn-list">목록</Link>
            </div>

            <table className="table">
                <tbody>
                    {
                        list!=null && list.length>0 ? (
                            list.map((ob, i)=>{
                                return (
                                    <tr key={i} className={params.no == ob.no ? 'active' : ''}>
                                        <td>
                                            <button onClick={(e)=>{
                                                e.preventDefault();
                                                const tmp = {
                                                    ...params
                                                    , no : ob.no
                                                }
                                                setParams(tmp);
                                                fn_getMovieInfo(tmp);
                                                navigate('/movieView?no=' + ob.no);
                                            }}>{ob.title}</button>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : ''
                    }
                </tbody>
            </table>
        </>        
    );
}

