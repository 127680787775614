import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    return (
        <>
            ERR
        </>        
    );
}

