import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

export default function App(props){
    const navigate = useNavigate();
    window.document.title = "CRIZEL - Meeting";

    const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') || '');
    const [refreshToken, setRefreshToken] = useState(sessionStorage.getItem('accessToken') || '');
    const [memberName, setMemberName] = useState(sessionStorage.getItem('memberName') || '');


    const fn_logout = ()=>{
        sessionStorage.setItem('accessToken', '');
        sessionStorage.setItem('refreshToken', '');
        sessionStorage.setItem('memberName', '');
        window.location.href='/';
    }  

    const fn_login = ()=>{
        navigate("/login?returnUrl=" + window.location.href);
    }

    return(
        <>
            <header className="header">
                <ul className="menu depth1">
                    <li>
                        {
                            accessToken != null && accessToken != '' ? (
                                <button onClick={(e)=>{e.preventDefault(); fn_logout();}}>로그아웃</button>
                            ) : (
                                <button onClick={(e)=>{e.preventDefault(); fn_login();}} >로그인</button>
                            )
                        }
                    </li>
                </ul>
            </header>
        </>
    );
}