import { api_commonFetch } from "./commonFetch";

export const fn_getScsFoodMenuList = async(params) =>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/scsFoodMenuList"
    + "?searchStartDate=" + params.searchStartDate
    + "&searchEndDate=" + params.searchEndDate
    let config = {
        method : 'get'
    };

    const data = await api_commonFetch(url, config);

    return data.list;
}

export const fn_getDayOfWeek = (val)=>{
    let date = new Date(val.split("-")[0], val.split("-")[1]-1, val.split("-")[2])
    let arr = ['일', '월', '화', '수', '목', '금', '토'];
    return arr[date.getDay()];
}