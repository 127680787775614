import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading';

import {fn_getScsFoodMenuList, fn_getDayOfWeek} from 'api/main'

import { api_commonFetch } from 'api/commonFetch';


export default function App(){
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const date = new Date();
    const endDate = new Date(date.getFullYear(), date.getMonth()+1, 0);

    const [timeStr, setTimeStr] = useState(new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[0] + "(" + fn_getDayOfWeek(new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[0]) + ") " + new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[1].split(".")[0]);

    const [params, setParams] = useState({
        searchStartDate : searchParams.get('searchStartDate') || (date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0'))
        , searchEndDate : searchParams.get('searchEndDate') || (endDate.getFullYear() + '-' + String(endDate.getMonth()+1).padStart(2, '0') + '-' + String(endDate.getDate()).padStart(2, '0'))
        , noHeader : searchParams.get('noHeader') || ''
    });

    const [list, setList] = useState([]);

    const [menuDate, setMenuDate] = useState('');
    const [menuItem, setMenuItem] = useState('');
    const [img, setImg] = useState('');
   

    const fn_getDataList = async (params)=>{
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/crizelApi/scsFoodMenuList'
        + '?searchStartDate=' + params.searchStartDate
        + '&searchEndDate=' + params.searchStartDate
        const config = {method : 'get'}
        const data = await api_commonFetch(url, config);
        const list = data.list;

        if(list!=null && list.length>0){
            list.forEach((ob)=>{
                setMenuDate(ob.menuDate);
                setMenuItem(ob.menuItem);
                setImg(ob.img);
            })
        }else{
            setMenuDate("");
            setMenuItem("");
            setImg("");
        }
        
        setLoading(false);
    }

    useEffect(()=>{        
        fn_getDataList(params);
        const header = document.querySelector("header");
        if(header && params.noHeader == 'Y'){
            header.classList.add('display-n')
        }

        setInterval(()=>{
            const date = new Date();
            const dateStr = new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[0];
            const timeStr = new Date(date.getTime() + (9 * 60 * 60 * 1000)).toISOString().split("T")[1].split(".")[0]
            setTimeStr(dateStr + "(" + fn_getDayOfWeek(dateStr) + ") " + timeStr )

            try{
                if(timeStr == '08:29:55'){
                    const audio = document.querySelector("#audio1");
                    audio.play();
                }else if(timeStr == '12:09:55'){
                    const audio = document.querySelector("#audio2");
                    audio.play();
                }else if(timeStr == '18:00:00'){
                    const audio = document.querySelector("#audio3");
                    audio.play();
                }
            }catch(err){
                console.log(err);
            }

        }, 1000);

        setInterval(()=>{
            window.location.reload();
        }, 1000 * 60 * 60)
        
    }, [params]);

    if(loading) return <Loading/>
    return (
        <>
            <audio className="display-n" id="audio1" src={"https://crizel.duckdns.org/crizel/upload/audio/clean.mp3"} controls/>
            <audio className="display-n" id="audio2" src={"https://crizel.duckdns.org/crizel/upload/audio/lunch.mp3"} controls/>
            <audio className="display-n" id="audio3" src={"https://crizel.duckdns.org/crizel/upload/audio/end.mp3"} controls/>
            <span style={{display:'block', width:'100%', textAlign:'center'}}>{timeStr}</span>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="date" name="searchStartDate" defaultValue={params.searchStartDate} onChange={(e)=>{
                    const tmp = {...params, searchStartDate : e.target.value}
                    setParams(tmp);
                }}/>
                <input type="hidden" name="noHeader" defaultValue={params.noHeader}/>
            </form>

            <div className="scs-food-menu">
                {
                    menuDate != "" ? (
                        <>
                            <div className="title">
                                <button onClick={(e)=>{
                                    e.preventDefault(); const tmp = {...params, noHeader : 'Y'}
                                    setParams(tmp);
                                    window.location.href='/food?noHeader=Y';
                                }}>{menuDate.substring(0, 10)} 식단표</button>
                            </div>
                            <div className={"content"}>
                                <div className={"menu-item" + (img==''?' two':'')}>
                                    {
                                        menuItem.split(" ")!=null && menuItem.split(" ").length>0 ? (
                                            menuItem.split(" ").map((ob, i)=>{
                                                return (
                                                    <div key={i}>
                                                        <span>{ob}</span>
                                                    </div>
                                                );
                                            })
                                        ) : ''
                                    }
                                </div>
                                <div className={"img" + (img==''?" display-n":"")}>
                                    {
                                        img != '' ? (
                                            <img src={"https://crizel.duckdns.org/crizel/upload/scsFoodMenu/" + img}/>
                                        ) : ''
                                    }     
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="no-data">
                            데이터가 없습니다.
                        </div>
                    )
                }
                
            </div>
        </> 
    );
}

