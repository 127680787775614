import { apiServer } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';

import { setLoginInfo } from 'actions/loginInfo';


import { Provider, useDispatch } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { login } from 'reducers/loginInfo';



export default function App(){
    const navigate = useNavigate();

    window.document.title = "CRIZEL - Login";

    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        memberId : searchParams.get("memberId") || ''
        , memberPw : searchParams.get("memberPw") || ''
        , returnUrl : searchParams.get("returnUrl") || '/'
    });

    const fn_loginAction = ()=>{        
        let url = apiServer + "/crizelApi/member/loginAction"
        let formData = new FormData(document.loginForm);

        fetch(url, {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
        }).then((res)=>res.json()).then((data)=>{
            if(data.result>0){
                sessionStorage.setItem('accessToken', data.accessToken);
                sessionStorage.setItem('refreshToken', data.refreshToken);
                    
                fetch(process.env.REACT_APP_API_URL + "/crizelApi/member/loginInfo", {
                    method : 'post'
                    , cache : 'no-cache'
                    , headers : {
                        Authorization : "Bearer " + data.accessToken
                    }
                }).then((res)=>res.json()).then((data2)=>{

                    const tmp = {
                        memberId : data2.memberId
                        , memberName : data2.memberName
                        , roleList : data2.roleList
                        , profileImg : data2.profileImg
                    }
                    dispatch(login({ loginInfo: tmp }));

                    window.location.replace(params.returnUrl);
                });

                
            }else{
                alert('계정을 확인하여주시기 바랍니다.');
            }
        }).catch((err)=>{
            alert('처리중 오류가 발생하였습니다. : ' + err);
        })

    }

    useEffect(()=>{
        document.loginForm.memberId.focus();
        if(params.memberId!='' && params.memberPw!=''){
            if(params.memberId == 'rhzhzh3'){
                const tmp = {...params, returnUrl : '/crizel'}
                setParams(tmp);
            }
            fn_loginAction();
        }
    }, []);

    return (
        <>
            <form name="loginForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="returnUrl" defaultValue={params.returnUrl}/>
                <img src={"https://crizel.duckdns.org/images/crizel.png"} className="logo"/>
                <div className="login-items">
                    <label for="memberId">아이디</label>
                    <input type="text" name="memberId" id="memberId" defaultValue={params.memberId}/>
                </div>
                <div className="login-items">
                    <label for="memberPw">비밀번호</label>
                    <input type="password" name="memberPw" id="memberPw" defaultValue={params.memberPw}/>
                </div>
                
                <div className="btn-area">
                    <button className='btn btn-primary' onClick={(e)=>{e.preventDefault(); fn_loginAction();}}>로그인</button>                    
                </div>
            </form>
        </>        
    );
}

