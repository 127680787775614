import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import Main from 'page/Main';
import NotFound from 'page/NotFound';
import Header from 'component/include/Header';
import Footer from 'component/include/Footer';

import ScheduleList from 'page/mediaBoard/ScheduleList';
import ScheduleInsert from 'page/mediaBoard/ScheduleInsert';
import ScheduleView from 'page/mediaBoard/ScheduleView';

import MediaboardMain from 'page/mediaBoard/Main';

import MediaboardDeviceList from 'page/mediaBoard/device/List';
import MediaboardDeviceInsert from 'page/mediaBoard/device/Insert';
import MediaboardDeviceControl from 'page/mediaBoard/device/Control';

import MediaboardContentList from 'page/mediaBoard/content/List';
import MediaboardContentInsert from 'page/mediaBoard/content/Insert';

import MediaboardNoticeList from 'page/mediaBoard/notice/List';
import MediaboardNoticeInsert from 'page/mediaBoard/notice/Insert';

import MediaboardScheduleList from 'page/mediaBoard/schedule/List';
import MediaboardScheduleInsert from 'page/mediaBoard/schedule/Insert';

import MediaboardStatic from 'page/mediaBoard/static/List';

import MediaboardPreview from 'page/mediaBoard/Preview';

export const mediaboard = [
    {path : "/mediaboard/", element : <MediaboardMain/>}
    , {path : "/mediaboard/device/list", element : <MediaboardDeviceList/>}
    , {path : "/mediaboard/device/insert", element : <MediaboardDeviceInsert/>}
    , {path : "/mediaboard/device/control", element : <MediaboardDeviceControl/>}
    , {path : "/mediaboard/content/list", element : <MediaboardContentList/>}
    , {path : "/mediaboard/content/insert", element : <MediaboardContentInsert/>}
    , {path : "/mediaboard/notice/list", element : <MediaboardNoticeList/>}
    , {path : "/mediaboard/notice/insert", element : <MediaboardNoticeInsert/>}
    , {path : "/mediaboard/schedule/list", element : <MediaboardScheduleList/>}
    , {path : "/mediaboard/schedule/insert", element : <MediaboardScheduleInsert/>}
    , {path : "/mediaboard/static", element : <MediaboardStatic/>}
    , {path : "/mediaboard/scheduleList", element : <ScheduleList/>}
    , {path : "/mediaboard/scheduleInsert", element : <ScheduleInsert/>}
    , {path : "/mediaboard/scheduleView", element : <ScheduleView/>}
    , {path : "/mediaboard/preview", element : <MediaboardPreview/>}
];