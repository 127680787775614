import { fn_reAuth } from 'component/info/serverInfo';
import Loading from 'component/Loading'
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [params, setParmas] = useState({
        custId : searchParams.get("custId") || '0001'
        , couponNo : ''
    });

    const [couponList, setCouponList] = useState([]);


    const fn_getCouponList = async (params)=>{
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + "/crizelApi/coupon/couponUseList"
        + "?custId=" + params.custId

        const res = await fetch(url);
        const data = await res.json();

        setCouponList(data.couponUseList);
        setLoading(false);

    }

    const fn_searchUseCd = async (params)=>{

        document.querySelector("#searchUseCdWrap").setAttribute("open", "open");

    }

    useEffect(()=>{
        fn_getCouponList(params);
    }, []);


    return (
        <>
            {
                loading==true ? (
                    <Loading/>
                ) : (
                    couponList!=null&&couponList.length>0?(
                        <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>이름</th>
                                        <th>연락처</th>
                                        <th>금액</th>
                                        <th>사용날짜</th>
                                        <th>승인</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        couponList.map((ob, i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {ob.couponNo}
                                                    </td>
                                                    <td>
                                                        {ob.userName}
                                                    </td>
                                                    <td>
                                                        {ob.userPhone}
                                                    </td>
                                                    <td>
                                                        {ob.price}
                                                    </td>
                                                    <td>
                                                        {ob.registerDate}
                                                    </td>
                                                    <td>
                                                        {
                                                            ob.useFlag == 'W' ? (
                                                                <>
                                                                    승인 대기
                                                                </>                                                                
                                                            ) : (
                                                                <>
                                                                    승인 완료 ({ob.approvalDate})
                                                                </>         
                                                            )
                                                        }
                                                                                                            
                                                    </td>
                                                </tr>                                                    
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <>
                        데이터가 없습니다.
                        </>
                    )
                )
            }
        </>        
    );
}
