import { api_commonFetch } from 'api/commonFetch';
import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import Loading from 'component/Loading'
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){

    const [loading, setLoading] = useState(true);
    const [params, setParams] = useSearchParams();
    const [vo, setVO] = useState({

    });
    const [couponUseList, setCouponUseList] = useState([]);

    const fn_getCouponUseList = async (vo)=>{
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/crizelApi/coupon/couponUseList'
        const config = {method : 'get', headers : {Authorization : "Bearer " + sessionStorage.getItem('accessToken')}}
        const data = await api_commonFetch(url, config);
        setCouponUseList(data.couponUseList)
        setLoading(false);
    }

    const fn_view = ()=>{

    }

    useEffect(()=>{
        fn_getCouponUseList(vo);
    }, []);


    return (        
        <>
            {loading == true ? (<Loading/>) : ''}
            
            <div className="coupon-use-list">
                {
                    couponUseList!=null && couponUseList.length>0 ? (
                        couponUseList.map((ob, i)=>{
                            return (
                                <div key={i} className="coupon-use-item">
                                    <div className="corp-info">
                                        {ob.corpName}
                                    </div>
                                    <div className="coupon-use-info">
                                        <span className="discount-percent">{ob.discountPercent}</span>
                                        <div className="use-dates">
                                            {ob.useDate}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : ''
                }
            </div>
        </>        
    );
}

