import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import Main from 'page/pg/Main';

import CorpList from 'page/pg/corp/List'
import CorpInsert from 'page/pg/corp/Insert'
import CorpTimeList from 'page/pg/corp/TimeList'


import ReserveTimeList from 'page/pg/reserve/TimeList'
import ReserveInsert from 'page/pg/reserve/Insert'
import ReserveInsertComp from 'page/pg/reserve/InsertComp'
import ReserveList from 'page/pg/reserve/ReserveList'



export const pg = [
    {path:'/pg', element : <Main/>}
    , {path:'/pg/corp/list', element : <CorpList/>}
    , {path:'/pg/corp/insert', element : <CorpInsert/>}
    , {path:'/pg/corp/timeList', element : <CorpTimeList/>}
    , {path:'/pg/reserve/timeList', element : <ReserveTimeList/>}
    , {path:'/pg/reserve/insert', element : <ReserveInsert/>}
    , {path:'/pg/reserve/insertComp', element : <ReserveInsertComp/>}
    , {path:'/pg/reserve/reserveList', element : <ReserveList/>}
];