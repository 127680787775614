import React, {useState, useEffect,} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';


import Main from 'page/Main';
import Food from 'page/Food';
import NotFound from 'page/NotFound';

import Login from 'page/Login'

import Register from 'page/Register'
import RegisterComp from 'page/RegisterComp'

import StudyList from 'page/study/List';
import StudyView from 'page/study/View';

import Drawing from 'page/drawing/List'
import DrawingItem from 'page/drawing/item/page'

import History from 'page/History'

import Movie from 'page/Movie'
import MovieView from 'page/MovieView'


export const common = [
    {path:'/', element : <Main/>}
    , {path:'/food', element : <Food/>}
    , {path:'/login', element : <Login/>}
    , {path:'/register', element : <Register/>}
    , {path:'/registerComp', element : <RegisterComp/>}
    , {path:'/history', element : <History/>}
    , {path:'/studyList', element : <StudyList/>}
    , {path:'/studyView', element : <StudyView/>}
    , {path:'/drawing/list', element : <Drawing/>}
    , {path:'/drawing/item', element : <DrawingItem/>}
    , {path:'/movie', element : <Movie/>}
    , {path:'/movieView', element : <MovieView/>}
];