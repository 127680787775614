import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const navigate = useNavigate();

    const [searchparams, setSearchParams] = useSearchParams();

    const [contentList, setContentList] = useState([]);

    const [params, setParams] = useState({
        schSeq : searchparams.get('schSeq') || ''
        , deviceSeq : searchparams.get('deviceSeq') || ''
    });

    const [info, setInfo] = useState({
        schSeq : ''
        , deviceSeq : ''
        , contentSeq : ''
        , schTitle : ''
        , schType : '0'
        , schStartDate : ''
        , schEndDate : ''
        , schAllday : 'Y'
        , schStartTime : ''
        , schEndTime : ''
        , schPlayTime : '10'
        , schPlayOrd : '0'
        , activeFlag : 'Y'
        , deleteFlag : ''
        , registerOper : ''
        , registerDate : ''
        , updateOper : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteDate : ''
    });

    const fn_postForm = ()=>{
        let url = '';
        let msg = '';
        let formData = new FormData(document.postForm);


        if(document.postForm.schSeq.value == ''){
            url = apiServer + "/crizelApi/mediaBoard/scheduleInsertAction";
            msg = '등록하시겠습니까?'
        }else{
            url = apiServer + "/crizelApi/mediaBoard/scheduleUpdateAction";
            msg = '수정하시겠습니까?'
        }


        if(document.postForm.schTitle.value == ''){
            alert('스케쥴명을 입력하여주시기 바랍니다.');
            document.postForm.schTitle.focus();
        } else if(document.postForm.contentSeq.value == ''){
            alert('컨텐츠를 선택하여주시기 바랍니다.');
            document.postForm.contentSeq.focus();
        } else if(document.postForm.schStartDate.value == ''){
            alert('스케쥴 시작날짜를 선택하여주시기 바랍니다.');
            document.postForm.schStartDate.focus();
        } else if(document.postForm.schEndDate.value == ''){
            alert('스케쥴 종료날짜를 선택하여주시기 바랍니다.');
            document.postForm.schEndDate.focus();
        } else if(document.postForm.schAllday.value == 'N' && document.postForm.schStartTime.value == ''){
            alert('스케쥴 시작시간을 선택하여주시기 바랍니다.');
            document.postForm.schStartTime.focus();
        } else if(document.postForm.schAllday.value == 'N' && document.postForm.schEndTime.value == ''){
            alert('스케쥴 종료시간을 선택하여주시기 바랍니다.');
            document.postForm.schEndTime.focus();
        } else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/mediaboard/schedule/list?deviceSeq=' + params.deviceSeq);
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
    }


    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/scheduleInfo"
        + "?schSeq=" + params.schSeq

        const res = await fetch(url);
        const data = await res.json();
        const scheduleInfo = data.scheduleInfo;

        if(scheduleInfo!=null){
            setInfo({
                ...info
                , schSeq : scheduleInfo.schSeq
                , deviceSeq : scheduleInfo.deviceSeq
                , contentSeq : scheduleInfo.contentSeq
                , schTitle : scheduleInfo.schTitle
                , schType : scheduleInfo.schType
                , schStartDate : scheduleInfo.schStartDate
                , schEndDate : scheduleInfo.schEndDate
                , schAllday : scheduleInfo.schAllday
                , schStartTime : scheduleInfo.schStartTime
                , schEndTime : scheduleInfo.schEndTime
                , schPlayTime : scheduleInfo.schPlayTime
                , schPlayOrd : scheduleInfo.schPlayOrd
                , activeFlag : scheduleInfo.activeFlag
                , deleteFlag : scheduleInfo.deleteFlag
                , registerOper : scheduleInfo.registerOper
                , registerDate : scheduleInfo.registerDate
                , updateOper : scheduleInfo.updateOper
                , updateDate : scheduleInfo.updateDate
                , deleteOper : scheduleInfo.deleteOper
                , deleteDate : scheduleInfo.deleteDate
            });
        }
        
    }

    const fn_getContentList = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/contentList"

        const res = await fetch(url);
        const data = await res.json();

        setContentList(data.contentList);
    }

    useEffect(()=>{
        fn_getContentList(params);
        fn_getData(params);
    }, []);


    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="schSeq" defaultValue={info.schSeq}/>
                <input type="hidden" name="deviceSeq" defaultValue={params.deviceSeq}/>
                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>스케쥴명</th>
                            <td>
                                <input type="text" name="schTitle" defaultValue={info.schTitle}/>
                            </td>
                        </tr>
                        <tr>
                            <th>컨텐츠 선택</th>
                            <td>
                                <select name="contentSeq" value={info.contentSeq} onChange={(e)=>{setInfo({...info, contentSeq : e.target.value})}}>
                                    <option value="">컨텐츠 선택</option>
                                    {
                                        contentList!=null && contentList.length>0 ? (
                                            contentList.map((ob, i)=>(
                                                <option value={ob.contentSeq} key={i}>{ob.contentName}</option>
                                            ))
                                        ) : ''
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>스케쥴 타입</th>
                            <td>
                                <label>
                                    <input type="radio" name="schType" defaultValue={"0"} checked={info.schType == '0'} onChange={(e)=>{setInfo({...info, schType : '0'})}}/>
                                    시간 스케쥴
                                </label>
                                <label>
                                    <input type="radio" name="schType" defaultValue={"1"} checked={info.schType == '1'} onChange={(e)=>{setInfo({...info, schType : '1'})}}/>
                                    긴급 스케쥴
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>스케쥴 시작 날짜</th>
                            <td>
                                <input type="date" name="schStartDate" defaultValue={info.schStartDate}/>
                            </td>
                        </tr>
                        <tr>
                            <th>스케쥴 종료 날짜</th>
                            <td>
                                <input type="date" name="schEndDate" defaultValue={info.schEndDate}/>
                            </td>
                        </tr>
                        <tr>
                            <th>종일사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="schAllday" defaultValue="Y" checked={info.schAllday == 'Y'} onChange={(e)=>{setInfo({...info, schAllday : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="schAllday" defaultValue="N" checked={info.schAllday == 'N'} onChange={(e)=>{setInfo({...info, schAllday : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>스케쥴 시작시간</th>
                            <td>
                                <input type="time" name="schStartTime" defaultValue={info.schStartTime}/>
                            </td>
                        </tr>
                        <tr>
                            <th>스케쥴 종료시간</th>
                            <td>
                                <input type="time" name="schEndTime" defaultValue={info.schEndTime}/>
                            </td>
                        </tr>
                        <tr>
                            <th>스케쥴 재생시간</th>
                            <td>
                                <input type="number" name="schPlayTime" defaultValue={info.schPlayTime}/>
                            </td>
                        </tr>
                        <tr>
                            <th>스케쥴 재생순서</th>
                            <td>
                                <input type="number" name="schPlayOrd" defaultValue={info.schPlayOrd}/>
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="Y" checked={info.activeFlag == 'Y'} onChange={(e)=>{setInfo({...info, activeFlag : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="activeFlag" defaultValue="N" checked={info.activeFlag == 'N'} onChange={(e)=>{setInfo({...info, activeFlag : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-area">
                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className='btn btn-primary'>확인</button>
                    <button onClick={(e)=>{e.preventDefault(); if(window.confirm('취소하시겠습니까?')){navigate('/mediaboard/content/list')}}} className='btn btn-danger'>취소</button>
                </div>
            </form>
        </>        
    );

}