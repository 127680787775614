import { api_commonFetch } from "./commonFetch";

export const api_getLoginInfo = async ()=>{
    const url = process.env.REACT_APP_API_URL + "/crizelApi/member/loginInfo"
    const config = {
        method : 'post'            
        , cache : 'no-cache'
        , headers : {
            Authorization : 'Bearer ' + sessionStorage.getItem('accessToken')
        }
    };
    const data = await api_commonFetch(url, config);

    return data;
}