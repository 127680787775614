import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [temp, setTemp] = useState(0);
    const [rain, setRain] = useState(0);
    const [humidity, setHumidity] = useState(0);
    const [pty, setPty] = useState(0);
    const [sky, setSky] = useState(0);
    const [skyStr, setSkyStr] = useState('');
    const [ptyStr, setPtyStr] = useState('');

    const [time, setTime] = useState('');

    const fn_getWeather = async ()=>{
        const url = process.env.REACT_APP_API_URL + "/crizelApi/mediaBoard/weatherInfo"
        const res = await fetch(url);
        const data = await res.json();
        if(data.weatherInfo!=null){
            setTemp(data.weatherInfo.temp);
            setRain(data.weatherInfo.rain);
            setHumidity(data.weatherInfo.humidity);
            setPty(data.weatherInfo.pty);
            setSky(data.weatherInfo.sky);
        }

        if(data.weatherInfo.sky == 1){
            setSkyStr('맑음');
        }else if(data.weatherInfo.sky == 3){
            setSkyStr('구름많음');
        }else if(data.weatherInfo.sky == 4){
            setSkyStr('흐림');
        }

        if(data.weatherInfo.pty == 0){
            setPtyStr('없음');
        }else if(data.weatherInfo.pty == 1){
            setPtyStr('비');
        }else if(data.weatherInfo.pty == 2){
            setPtyStr('비/눈');
        }else if(data.weatherInfo.pty == 3){
            setPtyStr('눈');
        }else if(data.weatherInfo.pty == 5){
            setPtyStr('빗방울');
        }else if(data.weatherInfo.pty == 6){
            setPtyStr('빗방울눈날림');
        }else if(data.weatherInfo.pty == 7){
            setPtyStr('눈날림');
        }
    }    


    const fn_getWeather_old = async ()=>{
        
        const apiKey = "GffeDSBWITUGxYaX4itATuSm97XH5tfcpnmHLj%2B8eKq9umHp%2B7nvQZePbjz6s%2B19TamszrJgjh%2BF5CuReb%2FF2w%3D%3D";

        let date = new Date();
        let todayStr = date.getFullYear() + String(date.getMonth()+1).padStart(2, '0')  + String(date.getDate()).padStart(2, '0');
        let toTimeStr = String(date.getHours()-1).padStart(2, 0) + "00";

        let url = "https://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getUltraSrtNcst"
        + "?serviceKey=" + apiKey
        + "&numOfRows=10"
        + "&pageNo=1"
        + "&base_date=" + todayStr
        + "&base_time=" + toTimeStr
        + "&nx=80"
        + "&ny=75"
        + "&dataType=json"
        ;

        const res = await fetch(url);
        const data = await res.json();
        const list = data.response.body.items.item;

        /*
        T1H	기온	        ℃	10
        RN1	1시간 강수량	mm	8
        UUU	동서바람성분	m/s	12
        VVV	남북바람성분	m/s	12
        REH	습도	        %	8
        PTY	강수형태	    코드값	4
        VEC	풍향	        deg	10
        WSD	풍속	        m/s	10
        */

        if(list!=null && list.length>0){
            list.forEach((ob, i)=>{
                console.log(ob.category);
                if(ob.category == 'T1H'){           //  기온
                    setTemp(ob.obsrValue);
                }else if(ob.category == 'RN1'){     //  강수량
                    setRain(ob.obsrValue);
                }else if(ob.category == 'REH'){     //  습도
                    setHumidity(ob.obsrValue);
                }
            })
        }else{
            console.log('test');
        }
        

        console.log(list);
    }

    useEffect(()=>{
        fn_getWeather();


        setInterval(()=>{
            let date = new Date();
            let str = date.getFullYear()
            + "-" + String(date.getMonth()+1).padStart(2, '0')
            + "-" + String(date.getDate(0)).padStart(2, '0')
            + " " + String(date.getHours()).padStart(2, '0')
            + ":" + String(date.getMinutes()).padStart(2, '0')
            + ":" + String(date.getSeconds()).padStart(2, '0')
            setTime(str);
        }, 1000); //1초 간격으로 시간 변경

        
        setInterval(()=>{
            fn_getWeather();
        }, 1000 * 60 * 1); //1분 간격으로 날씨정보 생신
    }, []);

    return (
        <>
            <h3>진주시 중앙동 기상초단기예보 (출처 : <a href="https://www.data.go.kr/tcs/dss/selectApiDataDetailView.do?publicDataPk=15084084" target="_blank">https://www.data.go.kr/tcs/dss/selectApiDataDetailView.do?publicDataPk=15084084</a>)</h3>
            <table className="table list">
                <colgroup>
                    <col style={{width:'16%'}}/>
                    <col style={{width:'16%'}}/>
                    <col style={{width:'16%'}}/>
                    <col style={{width:'16%'}}/>
                    <col style={{width:'16%'}}/>
                    <col style={{width:'16%'}}/>
                </colgroup>
                <thead>
                    <tr> 
                        <th>기온</th>
                        <th>습도</th>
                        <th>대기상태</th>
                        <th>강수상태</th>
                        <th>강수량</th>
                        <th>시간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span className="mob-title">기온</span>
                            {temp} ℃
                        </td>
                        <td>
                            <span className="mob-title">습도</span>
                            {humidity} %
                        </td>
                        <td>
                            <span className="mob-title">대기상태</span>
                            {skyStr} ({sky})
                        </td>
                        <td>
                            <span className="mob-title">강수상태</span>
                            {ptyStr} ({pty})
                        </td>
                        <td>
                            <span className="mob-title">강수량</span>
                            {rain=='강수없음'?0:rain} mm
                        </td>
                        <td>
                            <span className="mob-title">시간</span>
                            {time}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>        
    );
}

