import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import Loading from 'component/Loading';
import Canvas from 'component/Canvas'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function App(){
    const navigate = useNavigate();


    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    


    const [params, setParams] = useState({
        meetingNo : searchParams.get("meetingNo") || ''
    });

    const [info, setInfo] = useState({
        meetingNo : ''
        , meetingDate : ''
        , content : ''
        , deleteFlag : ''
        , registerOper : ''
        , registerOperName : ''
        , registerDate : ''
        , updateOper : ''
        , updateOperName : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteOperName : ''
        , deleteDate : ''
    });

    const fn_getDataInfo = async (params)=>{
        setLoading(true);

        let url = process.env.REACT_APP_API_URL + "/crizelApi/meeting/meetingInfo"
        + "?meetingNo=" + params.meetingNo

        const res = await fetch(url);
        const data = await res.json();

        if(data!=null && data.meetingInfo!=null){
            setInfo({
                meetingNo : data.meetingInfo.meetingNo
                , meetingDate : data.meetingInfo.meetingDate
                , content : data.meetingInfo.content
                , deleteFlag : data.meetingInfo.deleteFlag
                , registerOper : data.meetingInfo.registerOper
                , registerOperName : data.meetingInfo.registerOperName
                , registerDate : data.meetingInfo.registerDate
                , updateOper : data.meetingInfo.updateOper
                , updateOperName : data.meetingInfo.updateOperName
                , updateDate : data.meetingInfo.updateDate
                , deleteOper : data.meetingInfo.deleteOper
                , deleteOperName : data.meetingInfo.deleteOperName
                , deleteDate : data.meetingInfo.deleteDate
            });
        }


        setLoading(false);
    }


    const fn_postForm = ()=>{
        let url = "";
        let msg = "";
        const form = document.postForm
        const formData = new FormData(form);

        if(form.meetingNo.value == ""){
            url = process.env.REACT_APP_API_URL + "/crizelApi/meeting/meetingInsertAction";
            msg = '등록하시겠습니까?';
        }else{
            url = process.env.REACT_APP_API_URL + "/crizelApi/meeting/meetingUpdateAction";
            msg = '수정하시겠습니까?';
        }

        if(form.meetingDate.value == ""){
            alert('날짜를 입력하여주시기 바랍니다.');
            form.meetingDate.focus();
        }else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : 'post'
                    , cache : 'no-cache'
                    , body : formData
                    , headers : {
                        Authorization :  'Bearer ' + sessionStorage.getItem("accessToken")
                    }
                }).then((res)=>res.json()).then((data)=>{
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/meeting/');
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                        window.location.reload();
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
    }

    

    useEffect(()=>{
        

        fn_getDataInfo(params);
        
        
    }, []);

    return (
        <>
        {
            loading == true ? (
                <Loading/>
            ) : (
                <form name='postForm' onSubmit={(e)=>{e.preventDefault();}}>
                    <input type="hidden" name="meetingNo" defaultValue={info.meetingNo}/>
                    <table className="table">
                        <colgroup>
                            <col style={{width:'25%'}}/>
                            <col style={{width:'75%'}}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    날짜
                                </th>
                                <td>
                                    <input type="date" name="meetingDate" defaultValue={info.meetingDate}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="editor-wrap">
                                        <ReactQuill
                                            defaultValue={info.content}
                                            onChange={(value)=>{
                                                setInfo({
                                                    ...info
                                                    ,  content : value
                                                })   
                                            }
                                            }
                                            />
                                        <input type="hidden" name="content" defaultValue={info.content}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div>
                                        <Canvas/>
                                    </div>   
                                    <ul className="img-wrap">                            
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div className="btn-area">
                        <button onClick={(e)=>{e.preventDefault(); fn_postForm()}} className="btn btn-primary">확인</button>
                        <Link to={"/meeting"} className="btn btn-list">목록</Link>
                    </div>
                </form>

            )
        }      
        </>        
    );
}

