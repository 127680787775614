import { api_commonFetch } from "api/commonFetch";


export const fn_getRdvList = async (params, setLoading, setList, setVideo)=>{        
    setLoading(true);
    let url = process.env.REACT_APP_API_URL + "/crizelApi/rdvList"
    + "?order=" + params.order
    + "&searchKeyword=" + params.searchKeyword
    + "&category1=" + params.category1
    + "&category2=" + params.category2

    const config = {
        method : 'get'
        , cache : 'no-cache'
        , headers : {
            Authorization :  'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }

    const data = await api_commonFetch(url, config);

    setList(data.rdvList.content);
    setLoading(false);

    if(data.rdvList!=null && data.rdvList.length>0){
        setVideo("https://crizel.duckdns.org" + data.rdvList[Number(params.index)].rdvPath);
    }
}

export const fn_getRdvCategoryList = async (setCategory1List, setCategory2List)=>{

    const url = process.env.REACT_APP_API_URL + "/crizelApi/rdvCategoryList"
    const config = {
        method : 'get'
        , cache : 'no-cache'
        , headers : {
            Authorization :  'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }
    const data = await api_commonFetch(url, config);
    setCategory1List(data.category1List);
    setCategory2List(data.category2List);
}

export const fn_deleteAction = async (info, params, setVideo, fn_getRdvList, setLoading, setList)=>{

    const url = process.env.REACT_APP_API_URL + "/crizelApi/rdvDeleteAction"
    let formData = new FormData();
    formData.append("rdvNo", info.RDV_NO);

    if(window.confirm("삭제하시겠습니까?")){
        setVideo(null);

        const config = {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
            , headers : {
                Authorization : 'Bearer ' + sessionStorage.getItem('accessToken')
            }
        }

        const data = await api_commonFetch(url, config)

        if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            fn_getRdvList(params, setLoading, setList, setVideo);
        }else{
            alert('처리중 오류가 발생하였습니다.');
        }
    }

}

export const fn_postForm = async (form, params, setLoading, setList, setVideo, fn_dlgClose, setBookmarkList)=>{

    const formData = new FormData(form,);
    let url = process.env.REACT_APP_API_URL + "/crizelApi/rdvUpdateAction";
    
    if(form.rdvPath.value == ""){
        alert('경로를 입력하여주시기 바랍니다.');
        form.rdvPath.focus();
    }else if(form.rdvName.value == ""){
        alert('제목을 입력하여주시기 바랍니다.');
        form.rdvName.focus();
    }else{
        if(window.confirm('수정하시겠습니까?')){

            const config = {
                method : 'post'
                , cache : 'no-cache'
                , body : formData
                , headers : {
                    Authorization : 'Bearer ' + sessionStorage.getItem('accessToken')
                }
            }

            const data = await api_commonFetch(url, config);

            if(data.result>0){
                alert('정상적으로 처리되었습니다.');
                fn_dlgClose();
                fn_getRdvList(params, setLoading, setList, setVideo);
                fn_getBookmarkList(setBookmarkList);
            }else{
                alert('처리중 오류가 발생하였습니다.');
            }
        }
    }


}


export const fn_getBookmarkList = async (setBookmarkList)=>{
    const url = process.env.REACT_APP_API_URL + "/crizelApi/rdvBookmarkList"
    const config = {
        method : 'get'
        , cache : 'no-cache'
        , headers : {
            Authorization :  'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }
    const data = await api_commonFetch(url, config);
    setBookmarkList(data.rdvBookmarkList);
}

export const fn_bookmarkDeleteAction = async (info, setBookmarkList)=>{

    let formData = new FormData();
    formData.append("bNo", info.B_NO);
    let url = process.env.REACT_APP_API_URL + "/crizelApi/rdvBookmarkDeleteAction"

    if(window.confirm('삭제하시겠습니까?')){
        const config = {
            method : 'post'
            , cache : 'no-cache'
            , headers : {
                Authorization :  'Bearer ' + sessionStorage.getItem('accessToken')
            }
        }

        const data = await api_commonFetch(url, config);
        if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            fn_getBookmarkList(setBookmarkList);
        }else{
            alert('처리중 오류가 발생하였습니다.');
        }
    }
}

export const fn_bookmarkPostForm = async (form, setBookmarkList, fn_bmDlgClose)=>{

    const formData = new FormData(form);
    let url = "";
    let msg = "";

    if(form.bNo.value == ""){
        url = process.env.REACT_APP_API_URL + "/crizelApi/rdvBookmarkInsertAction"
        msg = "등록하시겠습니까?"
    }else{
        url = process.env.REACT_APP_API_URL + "/crizelApi/rdvBookmarkUpdateAction"
        msg = "수정하시겠습니까?"
    }

    if(form.keyword.value == ''){
        alert('키워드를 입력하여주시기 바랍니다.');
        form.keyword.value.focus();
    }else{
        if(window.confirm(msg)){
            const config = {
                method : 'post'
                , cache : 'no-cache'
                , body : formData
                , headers : {
                    Authorization :  'Bearer ' + sessionStorage.getItem('accessToken')
                }
            }
            const data = await api_commonFetch(url, config);
            if(data.result>0){
                alert('정상적으로 처리되었습니다.');
                fn_getBookmarkList(setBookmarkList);
                fn_bmDlgClose();
            }else{
                alert('처리중 오류가 발생하였습니다.');
            }
        }
    }
}


export const fn_av19Form = async (form, fn_getAv19List)=>{
    const formData = new FormData(form);
    let url = '';
    let msg = '';

    if(form.no.value == ''){
        url = process.env.REACT_APP_API_URL + '/crizelApi/av19InsertAction'
        msg = '등록하시겠습니까?';
    }else{
        url = process.env.REACT_APP_API_URL + '/crizelApi/av19UpdateAction'
        msg = '수정하시겠습니까?';
    }

    if(window.confirm(msg)){

        const config = {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
            , headers : {
                'Authorization' : 'Bearer ' + sessionStorage.getItem('accessToken') 
            }
        }

        const data = await api_commonFetch(url, config)
        if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            fn_getAv19List();
        }else{
            alert(data.resultMsg!=null&&data.resultMsg!=''?data.resultMsg:'처리중 오류가 발생하였습니다.');
        }
    }
}


export const fn_av19DeleteAction = async (no, fn_getAv19List)=>{
    const url = process.env.REACT_APP_API_URL + '/crizelApi/av19DeleteAction'
    let formData = new FormData();
    formData.append('no', no);

    if(window.confirm('삭제하시겠습니까?')){

        const config = {
            method : 'post'
            , cache : 'no-cache'
            , body : formData
            , headers : {
                'Authorization' : 'Bearer ' + sessionStorage.getItem('accessToken') 
            }
        }

        const data = await api_commonFetch(url, config);

        if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            fn_getAv19List();
        }else{
            alert(data.resultMsg!=null&&data.resultMsg!=''?data.resultMsg:'처리중 오류가 발생하였습니다.');
        }
    }
}

export const fn_getAv19List = async (setAv19List)=>{
    const url = process.env.REACT_APP_API_URL + "/crizelApi/av19List"

    const config = {
        method : 'get'
        , cache : 'no-cache'
        , headers : {
            'Authorization' : 'Bearer ' + sessionStorage.getItem('accessToken')
        } 
    }
    
    const data = await api_commonFetch(url, config);
    setAv19List(data.av19List);
}