import { api_commonFetch } from "api/commonFetch";


export const fn_getApiAniList = async (params)=>{        
    let url = process.env.REACT_APP_API_URL + "/crizelApi/aniList"
    + "?day=" + params.day

    const config = {
        method : 'get'
        , cache : 'no-cache'
        , headers : {
            Authorization :  'Bearer ' + sessionStorage.getItem("accessToken")
        }
    }

    const data = await api_commonFetch(url, config);

    return data;
}


export const fn_updateAction = async (form, params, fn_getAniList, fn_close)=>{
    const formData = new FormData(form);
    let url = process.env.REACT_APP_API_URL + "/crizelApi/aniUpdateAction"
    const config = {
        method : 'post'
        , cache : 'no-cache'
        , body : formData
        , headers : {
            Authorization : 'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }

    const data = await api_commonFetch(url, config);
    if(data.result>0){
        alert('정상적으로 처리되었습니다.');
        fn_getAniList(params);
        fn_close();
    }else{
        alert('처리중 오류가 발생하였습니다.');
    }
}  

export const fn_deleteAction = async (aniNo, params, fn_getAniList)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/aniDeleteAction";
    let formData = new FormData();
    formData.append("aniNo", aniNo);
    const config = {
        method : "post"
        , cache : 'no-cache'
        , body : formData
        , headers : {
            Authorization : 'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }

    if(window.confirm('삭제하시겠습니까?')){

        const data = await api_commonFetch(url, config);
        if(data.result>0){
            alert('정상적으로 처리되었습니다.');
            fn_getAniList(params);
        }else{
            alert('처리중 오류가 발생하였습니다.');
        }
    }
}