import { api_commonFetch } from 'api/commonFetch';
import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import Loading from 'component/Loading'
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [vo, setVO] = useState({
        
    });

    const [maxCoupon, setMaxCoupon] = useState(0);

    const fn_getMaxPubCnt = async(vo)=>{
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/crizelApi/coupon/maxPubCnt'
        const config = {method : 'get'}
        const data = await api_commonFetch(url, config);
        setMaxCoupon(data.maxCoupon);
        setLoading(false);
    }


    useEffect(()=>{
        fn_getMaxPubCnt(vo);
    }, []);


    return (
        <>
           {loading == true ? (<Loading/>) : ""}

           {
            maxCoupon > 0 ? (
                <>
                    maxCoupon : {maxCoupon}
                </>
            ) : (
                <div className="no-coupon">
                    <img src="https://crizel.duckdns.org/images/noimage.jpg"/>
                    <span className="txt">쿠폰이 없습니다...</span>
                    <Link to="/coupon">돌아가기</Link>
                </div>
            )
           }

           

        </>        
    );
}

