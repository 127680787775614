import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo'

export default function App(){

    const [list, setList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);

    const [searchparams, setSearchParams] = useSearchParams();
    const [params, setParmas] = useState({
        deviceSeq : searchparams.get('deviceSeq') || ''
    });

    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/noticeList"
        + "?deviceSeq=" + params.deviceSeq
        
        const res = await fetch(url);
        const data = await res.json();

        setList(data.noticeList);

    }

    const fn_getDeviceList = async(params)=>{
        const url = apiServer + "/crizelApi/mediaBoard/deviceList"
        const res = await fetch(url);
        const data = await res.json();
        setDeviceList(data.deviceList);
    }


    useEffect(()=>{
        fn_getDeviceList(params);
        fn_getData(params);
    }, [params]);


    return (
        <>
            <form name="searchForm" onSubmit={(e)=>{e.preventDefault();}}>
                <select name="deviceSeq" value={params.deviceSeq} onChange={(e)=>{ const tmp = {...params, deviceSeq : e.target.value}; setParmas(tmp) }}>
                    <option value="">기기 선택</option>
                    {
                        deviceList!=null && deviceList.length>0 ? (
                            deviceList.map((ob, i)=>{
                                return (
                                    <option value={ob.deviceSeq} key={i}>{ob.deviceName}</option>
                                );
                            })
                        ) : ''
                    }
                </select>
            </form>
            <div className="btn-area">
                <Link to="/mediaboard/notice/insert" className="btn btn-primary">등록</Link>
            </div>

            <a href={apiServer + "/crizelApi/mediaBoard/noticeList?deviceSeq=1"} target="_blank">공지사항 목록 데이터 URL</a>

            <table className="table list">
                <colgroup>
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>기기</th>
                        <th>제목</th>
                        <th>시작시간</th>
                        <th>종료시간</th>
                        <th>사용여부</th>
                        <th>작성자</th>
                        <th>수정일</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length > 0 ? (
                            list.map((ob, i)=>(
                                <tr key={i}>
                                    <td>
                                        <span className="mob-title">번호</span>
                                        {ob.contentSeq}
                                    </td>
                                    <td>
                                        <span className="mob-title">기기</span>
                                        {ob.deviceName}
                                    </td>
                                    <td className="text-left">
                                        <span className="mob-title">제목</span>
                                        <Link to={"/mediaboard/notice/insert?noticeSeq=" + ob.noticeSeq}>{ob.noticeTitle}</Link>
                                    </td>
                                    <td>
                                        <span className="mob-title">시작시간</span>
                                        {ob.startTime}
                                    </td>
                                    <td>
                                        <span className="mob-title">종료시간</span>
                                        {ob.endTime}
                                    </td>
                                    <td>
                                        <span className="mob-title">사용여부</span>
                                        {ob.activeFlag == 'Y' ? '사용' : '미사용'}
                                    </td>
                                    <td>
                                        <span className="mob-title">작성자	</span>
                                        {ob.registerOper}
                                    </td>
                                    <td>
                                        <span className="mob-title">수정일</span>
                                        {ob.updateDate}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} className="text-center">데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>        
    );

}