import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import Slider from 'component/coupon/main/Slider'
import { api_commonFetch } from 'api/commonFetch';
import { useLoginInfo } from 'hook/loginInfo';

export default function App(){
    const navigate = useNavigate();

    const loginInfo = useLoginInfo();

    const [bannerBtnList, setBannerBtnList] = useState([]);

    const [corpList, setCorpList] = useState([]);

    const [gradeInfo, setGradeInfo] = useState({
        corp : ''
        , webId : ''
        , ci : ''
        , di : ''
        , grade : ''
        , webPhone1 : ''
        , webPhone2 : ''
        , nbPhone1 : ''
        , nbPhone2 : ''
        , status : ''
        , etc1 : ''
        , etc2 : ''
        , etc3 : ''
        , etc4 : ''
        , etc5 : ''
        , createDate : ''
        , createOper : ''
        , updateDate : ''
        , updateOper : ''
        , invCnt : '0'
        , useCnt : '0'
    });

    const fn_getCorpList = async()=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/coupon/corpList'
        const config = {method : 'get'}
        const data = await api_commonFetch(url, config);
        setCorpList(data.corpList);
    }

    const fn_getGradeInfo = async()=>{
        const url = process.env.REACT_APP_API_URL + '/crizelApi/coupon/gradeInfo'
        const config = {method : 'get', headers : {Authorization : "Bearer " + sessionStorage.getItem('accessToken')}}
        const data = await api_commonFetch(url, config);
        const info = data.gradeInfo

        if(info){
            const tmp = {
                ...gradeInfo
                , corp : info.corp
                , webId : info.webId
                , ci : info.ci
                , di : info.di
                , grade : info.grade
                , webPhone1 : info.webPhone1
                , webPhone2 : info.webPhone2
                , nbPhone1 : info.nbPhone1
                , nbPhone2 : info.nbPhone2
                , status : info.status
                , etc1 : info.etc1
                , etc2 : info.etc2
                , etc3 : info.etc3
                , etc4 : info.etc4
                , etc5 : info.etc5
                , createDate : info.createDate
                , createOper : info.createOper
                , updateDate : info.updateDate
                , updateOper : info.updateOper
                , invCnt : info.invCnt
                , useCnt : info.useCnt
            }
            setGradeInfo(tmp);
        }
    }

    useEffect(()=>{
        fn_getCorpList();
        fn_getGradeInfo();
    }, []);

    return (
        <>
            <div className="grade-info">
                <span className="name-str">{loginInfo.memberName}</span> 님의 등급은 {gradeInfo.grade} 입니다.
            </div>

            <div className="main-btns">
                <div className="main-btn">
                    <Link to="/coupon/user/couponList">
                        <img src="https://crizel.duckdns.org/images/noimage.jpg"/>
                        <span className="btn-title">쿠폰 가져오기</span>
                    </Link>
                </div>
                <div className="main-btn">
                    <Link to="/coupon/user/couponInventory">
                        <img src="https://crizel.duckdns.org/images/noimage.jpg"/>
                        <span className="btn-title">내 쿠폰</span>
                    </Link>
                </div>
            </div>

            <div className="main-banner">
                <Slider/>
            </div>

            <div className="main-corp-list">
                <h3>쿠폰 쓸 곳, 여기 있어요!</h3>
                <ul>
                    {
                        corpList!=null && corpList.length>0 ? (
                            corpList.map((ob, i)=>{
                                return(
                                    <li key={i}>
                                        <a href={ob.url} target="_blank">
                                            <img src="https://crizel.duckdns.org/images/noimage.jpg"/>
                                            <span>{ob.corpName}</span>
                                        </a>
                                    </li>
                                );
                            })
                        ) : ''
                    }
                </ul>
            </div>

        </>        
    );
}

