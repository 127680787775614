import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [params, setParams] = useState({
        userNo : searchParams.get("userNo") || ''
        , projectNo : searchParams.get("projectNo") || ''
    });

    const [info, setInfo] = useState({
        userNo : ''
        , projectNo : ''
        , typeNo : ''
        , groupNo : ''
        , userName : ''
        , userNumber : ''
        , userUuid : ''
        , useFlag : 'Y'
        , deleteFlag : ''
        , registerOper : ''
        , registerOperName : ''
        , registerDate : ''
    });

    const [typeList, setTypeList] = useState([]);
    const [groupList, setGroupList] = useState([]);


    const fn_getDataInfo = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/userInfo"
        + "?projectNo=" + params.projectNo
        + "&userNo=" + params.userNo

        const res = await fetch(url, {
            method : 'get'
            , headers : {
                Authorization : "Bearer " + sessionStorage.getItem("accessToken")
            }
        });
        const data = await res.json();
        fn_reAuth(fn_getDataInfo, data);

        if(data.info!=null){
            console.log(info);
            setInfo({
                userNo: data.info.userNo
                , projectNo: data.info.projectNo
                , typeNo: data.info.typeNo
                , groupNo: data.info.groupNo
                , userName: data.info.userName
                , userNumber: data.info.userNumber
                , userUuid: data.info.userUuid
                , useFlag: data.info.useFlag
                , deleteFlag: data.info.deleteFlag
                , registerOper: data.info.registerOper
                , registerOperName: data.info.registerOperName
                , registerDate: data.info.registerDate
            });
            console.log(info);
        }
    }

    const fn_getTypeList = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/typeList"
        + "?projectNo=" + params.projectNo
        ;

        const res = await fetch(url, {
            method : 'get'
            , headers : {
                Authorization : "Bearer " + sessionStorage.getItem("accessToken")
            }
        });
        const data = await res.json();
        fn_reAuth(fn_getTypeList, data);
        
        setTypeList(data.typeList);
    }

    const fn_getGroupList = async (params)=>{
        let url = apiServer + "/crizelApi/app/qr/groupList"
        + "?projectNo=" + params.projectNo
        ;

        const res = await fetch(url, {
            method : 'get'
            , headers : {
                Authorization : "Bearer " + sessionStorage.getItem("accessToken")
            }
        });
        const data = await res.json();
        fn_reAuth(groupList, data);
        
        setGroupList(data.groupList);
    }

    const fn_postForm = ()=>{
        const form = document.postForm;
        const formData = new FormData(form);
        let url = "";
        let msg = "";

        
        if(form.userNo.value == ""){
            url = apiServer + "/crizelApi/app/qr/userInsertAction";
            msg = "등록하시겠습니까?"
        }else{
            url = apiServer + "/crizelApi/app/qr/userUpdateAction";
            msg = "수정하시겠습니까?"
        }

        if(form.typeNo.value == ""){
            alert('사용자 타입을 선택하여주시기 바랍니다.');
            form.typeNo.focus();
        }else if(form.groupNo.value == ""){
            alert('사용자 그룹을 선택하하여주시기 바랍니다.');
            form.groupNo.focus();
        }else if(form.userName.value == ""){
            alert('사용자명을 입력하여주시기 바랍니다.');
            form.userName.focus();
        }else{
            if(window.confirm(msg)){
                fetch(url, {
                    method : "post"
                    , cache : 'no-cache'
                    , body : formData
                    , headers : {
                        Authorization : "Bearer " + sessionStorage.getItem("accessToken")
                    }
                }).then((res)=>res.json()).then((data)=>{
                    fn_reAuth(fn_postForm, data);
                    
                    if(data.result>0){
                        alert('정상적으로 처리되었습니다.');
                        navigate('/qr/user/list?projectNo=' + params.projectNo)
                    }else{
                        alert('처리중 오류가 발생하였습니다.');
                    }
                }).catch((err)=>{
                    alert('처리중 오류가 발생하였습니다.');
                    window.location.reload();
                });
            }
        }
    }


    useEffect(()=>{

        fn_getTypeList(params);
        fn_getGroupList(params);

        fn_getDataInfo(params);
        
    }, []);

    return (
        <>
            <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="projectNo" defaultValue={params.projectNo}/>
                <input type="hidden" name="userNo" defaultValue={params.userNo}/>
                <table className="table">
                    <colgroup>
                        <col style={{width:'25%'}}/>
                        <col style={{width:'75%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>사용자 타입</th>
                            <td>
                                <select name="typeNo" value={info.typeNo} onChange={(e)=>{setInfo({...info, typeNo : e.target.value})}}>
                                    <option value="">사용자 타입 선택</option>
                                    {
                                        typeList.map((ob, i)=>{
                                            return (
                                                <option value={ob.typeNo} key={i}>{ob.typeName}</option>
                                            );
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>사용자 그룹</th>
                            <td>
                                <select name="groupNo" value={info.groupNo} onChange={(e)=>{setInfo({...info, groupNo : e.target.value})}}>
                                    <option value="">사용자 그룹 선택</option>
                                    {
                                        groupList.map((ob, i)=>{
                                            return (
                                                <option value={ob.groupNo} key={i}>{ob.groupName}</option>
                                            );
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>사용자명</th>
                            <td>
                                <input type="text" name="userName" defaultValue={info.userName}/>
                            </td>
                        </tr>
                        <tr>
                            <th>사용자 구분번호 (학번, 사번 등)</th>
                            <td>
                                <input type="text" name="userNumber" defaultValue={info.userNumber}/>
                            </td>
                        </tr>
                        <tr>
                            <th>사용자 기기 ID</th>
                            <td>
                                <input type="text" name="userUuid" defaultValue={info.userUuid}/>
                            </td>
                        </tr>
                        <tr>
                            <th>사용여부</th>
                            <td>
                                <label>
                                    <input type="radio" name="useFlag" defaultValue="Y" checked={info.useFlag == 'Y'} onChange={(e)=>{setInfo({...info, useFlag : 'Y'})}}/>
                                    사용
                                </label>
                                <label>
                                    <input type="radio" name="useFlag" defaultValue="N" checked={info.useFlag == 'N'} onChange={(e)=>{setInfo({...info, useFlag : 'N'})}}/>
                                    미사용
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-area">
                    <button onClick={(e)=>{e.preventDefault(); fn_postForm();}} className="btn btn-primary">확인</button>
                    <button onClick={(e)=>{e.preventDefault(); if(window.confirm('취소하시겠습니까?')){navigate('/qr/user/list?projectNo=' + params.projectNo)}}} className="btn btn-danger">취소</button>
                </div>
            </form>
        </>        
    );
}

