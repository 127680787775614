import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading';
import { fn_authCheck } from 'component/info/serverInfo';

export default function App(){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    const [info, setInfo] = useState({
        corp : ''
        , corpName : ''
        , corpLocation : ''
        , corpAddr : ''
        , corpTel : ''
        , hole : '9'
        , useFlag : 'Y'
        , deleteFlag : 'N'
        , registerOper : ''
        , registerDate : ''
        , updateOper : ''
        , updateDate : ''
        , deleteOper : ''
        , deleteDate : ''
    });
    const [corpLocationList, setCorpLocationList] = useState(null);
    const [timeList, setTimeList] = useState(null);

    const [params, setParams] = useState({
        corp : searchParams.get('corp') || ''
    });     

    const accessToken = sessionStorage.getItem("accessToken") || ''
    const memberId = sessionStorage.getItem("memberId") || ''

    const fn_getCorpInfo = async (params)=>{
        setLoading(true);
        await fn_authCheck();
        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/corpInfo'
        + "?corp=" + params.corp

        const res = await fetch(url);
        const data = await res.json();

        if(data.corpInfo!=null){
            setInfo({
                corp : data.corpInfo.corp
                , corpName : data.corpInfo.corpName
                , corpLocation : data.corpInfo.corpLocation
                , corpAddr : data.corpInfo.corpAddr
                , corpTel : data.corpInfo.corpTel
                , hole : data.corpInfo.hole
                , useFlag : data.corpInfo.useFlag
                , deleteFlag : data.corpInfo.deleteFlag
                , registerOper : data.corpInfo.registerOper
                , registerDate : data.corpInfo.registerDate
                , updateOper : data.corpInfo.updateOper
                , updateDate : data.corpInfo.updateDate
                , deleteOper : data.corpInfo.deleteOper
                , deleteDate : data.corpInfo.deleteDate
            });
        }
        setLoading(false);
    }

    const fn_getCorpLocationList = async ()=>{
        let url = process.env.REACT_APP_API_URL + '/crizelApi/parkGolf/corpLocationList'

        const res = await fetch(url);
        const data = await res.json();

        setCorpLocationList(data.corpLocationList);
        setLoading(false);
    }

    const fn_timeList = async(params)=>{
        setLoading(true);
        let url = process.env.REACT_APP_API_URL + '/crizelApi/pg/timeList'
        + "?corp=" + params.corp
        ;

        const res = await fetch(url);
        const data = await res.json();

        setTimeList(data.timeList);
        setLoading(false);
    }

    useEffect(()=>{
        fn_getCorpLocationList();
        fn_getCorpInfo(params);
        fn_timeList(params);
    }, []);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                    <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                        <input type="hidden" name="corp" defaultValue={info.corp}/>
                        <table className="table">
                            <colgroup>
                                <col style={{width : '30%'}}/>
                                <col style={{width : '70%'}}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>골프장 명</th>
                                    <td>
                                        <input type="text" name="corpName" defaultValue={info.corpName}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>골프장 지역</th>
                                    <td>
                                        <input type="text" name="corpLocation" defaultValue={info.corpLocation}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>골프장 주소</th>
                                    <td>
                                        <input type="text" name="corpAddr" defaultValue={info.corpAddr}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>골프장 연락처</th>
                                    <td>
                                        <input type="number" name="corpTel" defaultValue={info.corpTel}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>골프장 홀</th>
                                    <td>
                                        <input type="number" name="hole" defaultValue={info.hole}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td>
                                        <label>
                                            <input type="radio" name="useFlag" value="Y" defaultChecked={info.useFlag == 'Y'} onClick={(e)=>{
                                                const tmp = {...info, useFlag : 'Y'}
                                                setInfo(tmp);
                                            }}/> 사용
                                        </label>
                                        <label>
                                            <input type="radio" name="useFlag" value="N" defaultChecked={info.useFlag == 'N'} onClick={(e)=>{
                                                const tmp = {...info, useFlag : 'N'}
                                                setInfo(tmp);
                                            }}/> 미사용
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="btn-area text-center">
                            <button onClick={(e)=>{
                                e.preventDefault();
                            }} className="btn btn-primary">확인</button>
                            <button onClick={(e)=>{
                                e.preventDefault();
                                if(window.confirm('목록으로 이동하시겠습니까?')){
                                    navigate('/pg/corp/list')
                                }
                            }} className="btn btn-list">목록</button>
                        </div>
                    </form>
                    </>  
                )
            }
        </>      
    );
}

