import { api_commonFetch } from "./commonFetch";

export const fn_getStudyList = async (params)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/studyList?" + new URLSearchParams(params)
    const config = {
        method : 'get'
        , cache : 'no-cache'
    }
    const data = await api_commonFetch(url, config);

    return ({
        list : data.list
        , category1List : data.category1List
        , category2List : data.category2List
    });

}

export const fn_getStudyInfo = async (params)=>{
    let url = process.env.REACT_APP_API_URL + "/crizelApi/studyInfo?no=" + params.no;

    const config = {
        method : 'get'
        , cache : 'no-cache'
    }
    const data = await api_commonFetch(url, config);

    return {
        info : data.info
        , category1List : data.category1List
        , category2List : data.category2List
    }
}