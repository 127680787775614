import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_authCheck } from 'component/info/serverInfo';

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from 'features/loginInfo/loginInfoSlice';
import GsList from './GsList';

export default function App(props){
    const navigate = useNavigate();

    const loginInfo = useSelector(getLoginInfo);
    const dispatch = useDispatch();

    const [yearList, setYearList] = useState(null);


    const [loading, setLoading] = useState(true);

    const [list, setList] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const [memberId, setMemberId] = useState(sessionStorage.getItem('memberId') || '');


    let date = new Date();

    const [dayList, setDayList] = useState([]);

    const [params, setParams] = useState({
        year : searchParams.get("year") || date.getFullYear()
        , month : searchParams.get("month") || date.getMonth()+1
        , date : searchParams.get("date") || date.getDate()       
        , dateStr : searchParams.get("dateStr") || ""
        , today : searchParams.get("today") || date.getFullYear() + "-" + String(date.getMonth()+1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0')
    });

    const fn_setCalendar = async (params)=>{
        setLoading(true);

        let year = params.year;
        let month = params.month;
        let dateStr = params.date;
        date = new Date(year, month-1, dateStr);


        let yearMonth = "";
        let toDate = date.getDate();
        date = new Date(year, month-1, date.getDate())
        yearMonth = date.getFullYear() + "-" + String(date.getMonth()+1).padStart(2, '0');

        
        date = new Date(year, Number(month), 0);
        const lastDate = date.getDate();

        
        date = new Date(year, month-1, 1);
        const startDay = date.getDay();

        const afterEmpty = 7 - ((lastDate + startDay) % 7);


        let prevDate = new Date(year, month-1, 0);
        const prevYearMonth = prevDate.getFullYear() + "-" + String(prevDate.getMonth()+1).padStart(2, '0');

        let nextDate = new Date(year, month, 1);
        const nextYearMonth = nextDate.getFullYear() + "-" + String(nextDate.getMonth()+1).padStart(2, '0');
        let nextDateDate = nextDate.getDate();


        let arr = new Array();
        let index = 1;
        for(let i=0; i<=lastDate + startDay + afterEmpty - 1; i++){
            if(i<startDay){
                arr.push(prevYearMonth + "-" + String(prevDate.getDate() - (startDay - i - 1)).padStart(2, '0'));
            }else if(index <= lastDate){
                arr.push(yearMonth + "-" + String(index++).padStart(2, '0'));
            }else{
                arr.push(nextYearMonth + "-" + String(nextDateDate++).padStart(2, '0'));
            }
        }

        setDayList(arr);

        setLoading(false);
    }

    useEffect(()=>{
        fn_setCalendar(params);

        let date = new Date()
        let tmp = [];
        for(let i=(date.getFullYear())+1; i>2019; i--){
            tmp.push(i);
        }
        setYearList(tmp);
    }, [params]);

    return (
        <>
            {
                loading == true ? (
                    <Loading/>
                ) : (
                    <>
                        <form name="calendarSearchForm">
                            <div className="text-center"> 
                                <select name="year" defaultValue={params.year} onChange={(e)=>{
                                    const tmp = {
                                        ...params
                                        , year : e.target.value
                                        , date : '01'
                                    };
                                    setParams(tmp);
                                    console.log(tmp);
                                    fn_setCalendar(params);
                                    navigate('/crizel/diary?' + new URLSearchParams(new FormData(document.calendarSearchForm)))
                                }}>
                                    {
                                        yearList.map((ob, i)=>{
                                            return (
                                                <option key={i} value={ob}>{ob}</option>
                                            )
                                        })
                                    }
                                </select>
                                <select name="month" defaultValue={String(params.month).padStart(2, '0')} onChange={(e)=>{
                                    const tmp = {
                                        ...params
                                        , month : e.target.value
                                        , date : '01'
                                    };
                                    setParams(tmp);
                                    console.log(tmp);
                                    fn_setCalendar(params);
                                    navigate('/crizel/diary?' + new URLSearchParams(new FormData(document.calendarSearchForm)))
                                }}>
                                    <option value="01">1 월</option>
                                    <option value="02">2 월</option>
                                    <option value="03">3 월</option>
                                    <option value="04">4 월</option>
                                    <option value="05">5 월</option>
                                    <option value="06">6 월</option>
                                    <option value="07">7 월</option>
                                    <option value="08">8 월</option>
                                    <option value="09">9 월</option>
                                    <option value="10">10 월</option>
                                    <option value="11">11 월</option>
                                    <option value="12">12 월</option>
                                </select>
                            </div>
                        </form>
                        <div className="calendar">
                            <div className="calendar-header">
                            <div className="days sun">일</div>
                            <div className="days">월</div>
                            <div className="days">화</div>
                            <div className="days">수</div>
                            <div className="days">목</div>
                            <div className="days">금</div>
                            <div className="days sat">토</div>
                            </div>
                            <div className="calendar-body">
                            {
                                dayList!=null && dayList.length>0?(
                                dayList.map((ob, i)=>(
                                    <div key={i} className={"days " + (i==0 || i%7==0?'sun ':'') + (i==0 || i%7==6?'sat ':'') + " " + (params.today == ob ? 'today' : '')} id={"day_" + ob}>
                                        <Link to={"/crizel/diary/view?date=" + ob}>{ob}</Link>
                                        <div className="holidayStr"></div>
                                    </div>
                                ))
                                ):''
                            }
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}