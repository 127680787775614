import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';

import {apiServer} from 'component/info/serverInfo';


export default function App(){
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [list, setList] = useState([]);

    const no = searchParams.get("no") || ''
    const pageType = searchParams.get("pageType") || 'view'

    const [params, setParams] = useState({

    });

    const fn_getDataList = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/scheduleList";

        const res = await fetch(url);
        const data = await res.json();
        setList(data.scheduleList);
    }

    useEffect(()=>{
        
        fn_getDataList(params);

    }, []);

    return (
        <>
            <a href="/mediaboard/scheduleInsert" className="btn btn-primary">신규등록</a>
            {
                list!=null && list.length>0 ? (
                <table className="table list">
                    <colgroup>
                        <col style={{width : "5%"}}/>
                        <col style={{width : "auto"}}/>
                        <col style={{width : "15%"}}/>
                        <col style={{width : "15%"}}/>
                    </colgroup>
                    <thead>
                    <th>
                        번호
                    </th>
                    <th>
                        제목
                    </th>
                    <th>
                        변경자
                    </th>
                    <th>
                        변경일
                    </th>
                    </thead>
                    <tbody>
                    {
                        list.map((ob, i)=>(
                        <tr key={i}>
                            <td>
                                {ob.schNo}                      
                            </td>
                            <td className="text-left">
                                <a href={"/mediaboard/scheduleView?schNo=" + ob.schNo}>{ob.schTitle}</a>                      
                            </td>
                            <td>
                                {ob.updateOperName}
                            </td>
                            <td>
                                {ob.updateDate.substring(0, 19)}
                            </td>
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
                ) : (
                <div>
                    데이터가 없습니다.
                </div>
                )
            }
            
        </>        
    );
}

