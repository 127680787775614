import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setMemberId, setMemberName, setMemberRole, getLoginInfo} from '../../features/loginInfo/loginInfoSlice';

export default function App(props){
    const navigate = useNavigate();

    window.document.title = "CRIZEL - ParkGolf";

    const currUrl = window.location.href

    const loginInfo = useSelector(getLoginInfo);
    const dispatch = useDispatch();

    const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') || '');
    const [refreshToken, setRefreshToken] = useState(sessionStorage.getItem('accessToken') || '');
    const [memberName, setMemberName] = useState(sessionStorage.getItem('memberName') || '');
    const memberId = sessionStorage.getItem('memberId') || '';


    const fn_logout = ()=>{
        sessionStorage.setItem('accessToken', '');
        sessionStorage.setItem('refreshToken', '');
        sessionStorage.setItem('memberName', '');
        window.location.href='/';
    }  

    const fn_login = ()=>{
        navigate("/login?returnUrl=" + window.location.href);
    }
    useEffect(()=>{
    }, []);

    return (
        <>
            <header className="header">
                <ul className="menu depth1">
                    <li>
                        <Link to="/pg/" >HOME</Link>
                    </li>
                    <li>
                        <Link to="/pg/corp/list" className={currUrl.indexOf('/corp/') > -1 ? ' active' : ''}>골프장 관리</Link>
                    </li>
                    <li>
                        <Link to="/pg/reserve/reserveList" className={currUrl.indexOf('/reserveList/') > -1 ? ' active' : ''}>예약조회</Link>
                    </li>
                    <li>
                        {
                            loginInfo.accessToken != null && loginInfo.accessToken != '' ? (
                                <button onClick={(e)=>{e.preventDefault(); fn_logout();}}>{loginInfo.memberName} 로그아웃</button>
                            ) : (
                                <button onClick={(e)=>{e.preventDefault(); fn_login();}} >로그인</button>
                            )
                        }
                    </li>
                </ul>
            </header>
        </>        
    );
}