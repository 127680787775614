import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Loading from 'component/Loading'
import { fn_categoryList, fn_rtInfo } from 'api/restaurant';


export default function App(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState({
        no : searchParams.get('no') || ''
    });
    const [info, setInfo] = useState({
        no  : ''
        , category1  : ''
        , category2  : ''
        , rstrTitle  : ''
        , rstrLocation  : ''
        , rstrReview  : ''
        , linkUrl  : ''
        , userName  : sessionStorage.getItem("memberName")
        , registerIp  : ''
        , registerId  : ''
        , registerDate  : ''
    });
    const [category1List, setCategory1List] = useState(null);
    const [category2List, setCategory2List] = useState(null);
    const [fileList, setFileList] = useState(null);

    const fn_getRtInfo = async(params)=>{
        setLoading(true)
        const data = await fn_rtInfo(params);

        if(data.rtInfo!=null){
            setInfo({
                no  : data.rtInfo.no
                , category1  : data.rtInfo.category1
                , category2  : data.rtInfo.category2
                , rstrTitle  : data.rtInfo.rstrTitle
                , rstrLocation  : data.rtInfo.rstrLocation
                , rstrReview  : data.rtInfo.rstrReview
                , linkUrl  : data.rtInfo.linkUrl
                , userName  : data.rtInfo.userName
                , registerIp  : data.rtInfo.registerIp
                , registerId  : data.rtInfo.registerId
                , registerDate  : data.rtInfo.registerDate
            });
        }

        setFileList(data.rtFileList)

        setLoading(false)
    }

    useEffect(()=>{
        fn_getRtInfo(params);
    }, []);

    return (
        <>
           <form name="postForm" onSubmit={(e)=>{e.preventDefault();}}>
                <input type="hidden" name="no" defaultValue={info.no}/>
            <table className="table">
                    <colgroup>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '30%'}}/>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '30%'}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>카테고리1</th>
                            <td>
                                {info.category1}
                            </td>
                            <th>카테고리2</th>
                            <td>
                                {info.category2}
                            </td>
                        </tr>
                        <tr>
                            <th>작성자</th>
                            <td colSpan={3}>
                                {info.userName}
                            </td>
                        </tr>
                        <tr>
                            <th>식당이름</th>
                            <td colSpan={3}>
                                {info.rstrTitle}
                            </td>
                        </tr>
                        <tr>
                            <th>식당주소</th>
                            <td colSpan={3}>
                                {info.rstrLocation}
                            </td>
                        </tr>
                        <tr>
                            <th>외부링크</th>
                            <td colSpan={3}>
                                {info.linkUrl}
                            </td>
                        </tr>
                        <tr>
                            <th>이미지</th>
                            <td colSpan={3}>
                                {
                                    fileList!=null && fileList.length>0 ? (
                                        fileList.map((ob, i)=>{
                                            return (
                                                <div key={i}>
                                                    <img src={"https://crizel.duckdns.org/crizel/upload/restaurant/" + ob.sysFileName} style={{display:'block', maxWidth:'100%'}}/>
                                                </div>
                                            );
                                        })
                                    ) : ''
                                }
                            </td>
                        </tr>
                    </tbody>
            </table>
            <div className="btn-area">
                <Link to={"/rt/insert?no=" + params.no} className="btn btn-success">수정</Link>
                <Link to={"/rt/list"} className="btn btn-list">목록</Link>
            </div>
           </form>
        </>        
    );
}

