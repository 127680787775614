import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';

import {apiServer, fn_reAuth} from 'component/info/serverInfo'

export default function App(){

    const [list, setList] = useState([]);

    const [params, setParmas] = useState({

    });

    const fn_getData = async (params)=>{
        let url = apiServer + "/crizelApi/mediaBoard/contentList"
        
        const res = await fetch(url);
        const data = await res.json();

        setList(data.contentList);

    }


    useEffect(()=>{
        fn_getData(params);
    }, []);


    return (
        <>
            <form name="searchForm" onSubmit="return false;">

            </form>
            <div className="btn-area">
                <Link to="/mediaboard/content/insert" className="btn btn-primary">등록</Link>
            </div>

            <table className="table list">
                <colgroup>
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>카테고리1</th>
                        <th>카테고리2</th>
                        <th>컨텐츠명</th>
                        <th>MD5 해시값</th>
                        <th>파일 사이즈</th>
                        <th>파일 확장자</th>
                        <th>파일 타입</th>
                        <th>사용여부</th>
                        <th>작성자</th>
                        <th>수정일</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list!=null && list.length > 0 ? (
                            list.map((ob, i)=>(
                                <tr key={i}>
                                    <td>
                                        <span className="mob-title">번호</span>
                                        {ob.contentSeq}
                                    </td>
                                    <td>
                                        <span className="mob-title">카테고리1</span>
                                        {ob.category1}
                                    </td>
                                    <td>
                                        <span className="mob-title">카테고리2</span>
                                        {ob.category2}
                                    </td>
                                    <td>
                                        <span className="mob-title">컨텐츠명</span>
                                        <Link to={"/mediaboard/content/insert?contentSeq=" + ob.contentSeq}>{ob.contentName}</Link>
                                    </td>
                                    <td>
                                        <span className="mob-title">MD5해시값</span>
                                        {ob.md5Hash}
                                    </td>
                                    <td>
                                        <span className="mob-title">파일 사이즈</span>
                                        {ob.fileSize}
                                    </td>
                                    <td>
                                        <span className="mob-title">파일 확장자</span>
                                        {ob.fileExt}
                                    </td>
                                    <td>
                                        <span className="mob-title">파일 타입</span>
                                        {ob.fileType == 'I' ? '이미지' : '영상'}
                                    </td>
                                    <td>
                                        <span className="mob-title">사용여부</span>
                                        {ob.activeFlag=='Y'?'사용':'미사용'}
                                    </td>
                                    <td>
                                        <span className="mob-title">작성자</span>
                                        {ob.registerOperName}
                                    </td>
                                    <td>
                                        <span className="mob-title">수정일</span>
                                        {ob.updateDate}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={10} className="text-center">데이터가 없습니다.</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>        
    );

}