import { api_commonFetch } from 'api/commonFetch';
import { apiServer, fn_reAuth } from 'component/info/serverInfo';
import Loading from 'component/Loading'
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import {QRCodeCanvas} from 'qrcode.react';


export default function App(){

    const [loading, setLoading] = useState(true);
    const [params, setParams] = useSearchParams();
    const [vo, setVO] = useState({

    });
    const [couponInvList, setCouponInvList] = useState([]);
    const [qrStr, setQrStr] = useState("");
    const [cpNo, setCpNo] = useState("");

    const fn_getCouponInvList = async (vo)=>{
        setLoading(true);
        const url = process.env.REACT_APP_API_URL + '/crizelApi/coupon/couponInvList'
        const config = {method : 'get', headers : {Authorization : "Bearer " + sessionStorage.getItem('accessToken')}}
        const data = await api_commonFetch(url, config);
        setCouponInvList(data.couponInvList)
        setLoading(false);
    }

    const fn_view = (cpNo, webId)=>{
        setQrStr(JSON.stringify({
            cpNo : cpNo
		    , webId : webId
        }));
        setCpNo(cpNo);
        document.querySelector(".coupon-detail-view-wrap").classList.remove('display-n');
    }

    const fn_close = ()=>{
        document.querySelector(".coupon-detail-view-wrap").classList.add('display-n');
    }

    useEffect(()=>{
        fn_getCouponInvList(vo);
    }, []);


    return (        
        <>
            {loading == true ? (<Loading/>) : ''}
            
            <div className="coupon-inv-list">
                {
                    couponInvList!=null && couponInvList.length>0 ? (
                        couponInvList.map((ob, i)=>{
                            return (
                                <div key={i} className="coupon-inv-item">
                                    <div className="coupon-info-detail">
                                        <span className="discount-percent">{Number(ob.discountPercent).toLocaleString()}</span> 원
                                        <div className="use-dates">
                                            {ob.useStartDate.substring(0, 10)} ~ {ob.useEndDate.substring(0, 10)}
                                        </div>
                                    </div>
                                    <div className="coupon-info-btns">
                                        <button onClick={(e)=>{e.preventDefault(); fn_view(ob.cpNo, ob.webId);}}>조회</button>
                                    </div>
                                </div>
                            );
                        })
                    ) : ''
                }
            </div>
            <div className="coupon-detail-view-wrap display-n">
                <div className="coupon-detail-view">
                    <div className="qr-code">
                        <QRCodeCanvas
                            value={qrStr}
                        />
                    </div>
                    {cpNo}
                </div>
                <button className="" onClick={(e)=>{e.preventDefault(); fn_close();}}>닫기</button>
            </div>
        </>        
    );
}

